import {
    InboxChannelFilter,
    InboxStatusFilter,
    SnoozedStatus,
    SortOrder,
    StarredStatus,
} from '$types/app';
import { CommType } from '$types/patient';
import { Channels } from './timeline';

export interface InboxState {
    conversations: {
        [patientId: string]: Conversation;
    };
    emails: {
        [emailId: string]: Email;
    };
    views: {
        [view: string]: InboxViewMeta;
    };
}

export interface Conversation {
    last_message_at: string;
    snippet: string;
    unread: boolean;
    unread_count: number;
    patient_id: string;
    first_name: string;
    last_name: string;
    channel: CommType;
    last_message_inbound: boolean;
    starred: boolean;
    snoozed: boolean;
    status?: string;
}

export type EmailState = 'loading' | 'loaded' | 'error';

export interface Email {
    state: EmailState;
    date: string;
    email: string;
    subject: string;
    body?: string;
}

export enum ConversationsStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    LOADED = 'loaded',
    ERRORED = 'errored',
    LOADING_MORE = 'loading_more',
    LOADING_MORE_FAILED = 'loading_more_failed',
}

export interface InboxViewMeta {
    state: ConversationsStatus;
    path: string;
    count?: number;
    countWithFilters?: number;
    countStarred?: number;
    countSnoozed?: number;
    channel: Channels | 'all';
    conversations: {
        [patientId: string]: Conversation;
    };
    parameters: {
        unread: InboxStatusFilter;
        channel: InboxChannelFilter;
        types: string[];
        startAt: string;
        endAt: string;
        sortDirection: SortOrder;
        starred: StarredStatus;
        snoozed: SnoozedStatus;
    };
    paging: {
        next: string | null;
        prev: string | null;
    };
}
