import React from 'react';
import { Sidebar } from '../Sidebar';
import { useHistory, useLocation } from 'react-router';
import auth from '$auth';
import { useAppSelector } from '$state';
import { anyIntegrationUnhealthy } from '$state/concerns/integrations';

export const RoutingSidebar = () => {
    const history = useHistory();
    const location = useLocation();
    const settingsAlert = useAppSelector(anyIntegrationUnhealthy);

    const navigate = (dest: string) => history.push(dest);

    const signOut = () => auth.logout();

    return (
        <Sidebar
            navigate={navigate}
            signOut={signOut}
            currentRoute={location.pathname}
            alert={settingsAlert ? 'settings' : ''}
        />
    );
};
