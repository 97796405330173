import { AsyncStatus } from '$types';
import {
    createAction,
    createAsyncThunk,
    createReducer,
    createSelector
} from '@reduxjs/toolkit';
import * as api from '$api/integrations';
import { RootState } from '$state/store';
import { Integration, Sync } from '$types/integrations';
import {
    integrationStateChanged,
    integrationSyncStateChanged
} from '$state/events';
import { maybe } from '$utils';
import { featureEnabled } from '$state/queries/features';
import { withState } from '$state/utils';

interface IntegrationSet {
    [integration: string]: Integration;
}

interface LatestSyncSet {
    [integration: string]: Sync;
}

export interface IntegrationState {
    status: AsyncStatus;
    integrations: IntegrationSet;
    latestSyncs: LatestSyncSet;
}

export const load = createAsyncThunk('integrations/load', () => api.load());

interface SyncPayload {
    integration: string;
    id: string;
    date: string;
}

export const sync = createAsyncThunk<void, SyncPayload>(
    'integrations/sync',
    async ({ integration }) => {
        await api.sync(integration);
    }
);

interface ConnectionPayload {
    integration: string;
}

export const disconnect = createAsyncThunk<void, ConnectionPayload>(
    'integrations/disconnect',
    async ({ integration }) => api.disconnect(integration)
);

export const heal = createAction<ConnectionPayload>('integrations/connect');

const initialState: IntegrationState = {
    status: 'init',
    integrations: {},
    latestSyncs: {}
};

export default createReducer(initialState, (builder) => {
    builder.addCase(load.pending, (state) => {
        state.status = 'loading';
    });

    builder.addCase(load.fulfilled, (state, action) => {
        state.status = 'loaded';

        state.integrations = Object.fromEntries(
            action.payload.map((integration) => [
                integration.integration,
                {
                    integration: integration.integration,
                    displayName: integration.display_name,
                    description: integration.description,
                    logo: integration.logo,
                    state: integration.state
                }
            ])
        );

        state.latestSyncs = Object.fromEntries(
            action.payload
                .filter((integration) => integration.latest_sync !== null)
                .map(({ integration, latest_sync: sync }) => {
                    return [
                        integration,
                        maybe(sync, (s) => ({
                            id: s.id,
                            state: s.state,
                            queuedAt: s.queued_at,
                            syncingAt: s.syncing_at,
                            completedAt: s.completed_at,
                            failedAt: s.failed_at,
                            error: s.error
                        }))
                    ];
                })
        );
    });

    builder.addCase(disconnect.pending, (state, action) => {
        const { integration } = action.meta.arg;
        state.integrations[integration].state = 'disconnected';
        delete state.latestSyncs[integration];
    });

    builder.addCase(heal, (state, action) => {
        const { integration } = action.payload;

        if (!state.integrations[integration]) {
            return;
        }

        state.integrations[integration].state = 'healthy';
    });

    builder.addCase(sync.pending, (state, action) => {
        const { id, date, integration } = action.meta.arg;

        state.latestSyncs[integration] = {
            id,
            state: 'queued',
            queuedAt: date,
            syncingAt: null,
            completedAt: null,
            failedAt: null,
            error: null
        };
    });

    builder.addCase(integrationStateChanged, (state, action) => {
        const { integration, state: integrationState } = action.payload;

        if (!state.integrations[integration]) {
            return;
        }

        state.integrations[integration].state = integrationState;
    });

    builder.addCase(integrationSyncStateChanged, (state, action) => {
        const {
            id,
            integration,
            state: syncState,
            queued_at: queuedAt,
            syncing_at: syncingAt,
            completed_at: completedAt,
            failed_at: failedAt,
            error
        } = action.payload;

        state.latestSyncs[integration] = {
            id,
            state: syncState,
            queuedAt,
            syncingAt,
            completedAt,
            failedAt,
            error
        };
    });
});

const selectState = ({ integrations }: RootState): IntegrationState =>
    integrations;

export const selectStatus = createSelector(
    [selectState],
    ({ status }: IntegrationState) => status
);

export const selectIntegrations = createSelector(
    [selectState, withState(featureEnabled, 'whatsapp')],
    ({ integrations }: IntegrationState, whatsAppEnabled) => {
        return Object.values(integrations).filter(
            ({ integration }) => whatsAppEnabled || integration !== 'whatsapp'
        );
    }
);

export const selectIntegration = (integration: string) => (state: RootState) =>
    selectState(state).integrations[integration];

export const selectSyncState =
    (integration: string) =>
    ({ integrations }: RootState) =>
        integrations.latestSyncs[integration]?.state ?? 'complete';

export const integrationState = (state: RootState): IntegrationState =>
    state.integrations;

export const integrationHealthy = createSelector(
    [(_, integration: string) => integration, integrationState],
    (integration, integrations: IntegrationState) => {
        return integrations.integrations[integration]?.state === 'healthy';
    }
);

export const anyIntegrationUnhealthy = createSelector(
    [integrationState],
    ({ integrations }: IntegrationState) => {
        return Object.values(integrations).some(
            ({ state }) => state === 'unhealthy'
        );
    }
);
