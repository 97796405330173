import { mix } from '$ui/Flo/util';
import { AuthArgs } from '@/state/types';
import { Form, Quote } from '$ui/Login';
import React from 'react';
import styled from 'styled-components';
import LogoOSvg from '$resources/logo-o.svg';
import auth from '$auth';
import { usePageTitle } from '@/utils/hooks';

export const Login = () => {
    const [error, setError] = React.useState<string | null>(null);

    usePageTitle('Login');

    const onLogin = async (details: AuthArgs) => {
        await auth.login(details);

        if (!auth.errored()) {
            return;
        }

        const errorCode = auth.error();

        switch (errorCode) {
            case 'Network error':
                setError(`
                    Could not connect to Leadflo. Are you connected to
                    the internet?
                `);
                return;

            case 'client inactive':
                setError(`
                    Sorry, it looks like your account is disabled. Contact
                    support so we can get you back online :)
                `);
                return;

            case 'invalid_credentials':
                setError('Your email or password is incorrect');
                return;

            case 'too_many_attempts':
                setError(
                    'You have entered an incorrect email/password too' +
                        ' many times. Please wait up to 30 minutes and try' +
                        ' again',
                );
                return;

            default:
                setError(`
                    There has been a serious problem logging you in. Contact
                    support and we will fix it straight away.
                `);
        }
    };

    return (
        <Container>
            <Left>
                <FormContainer>
                    <OShape />
                    <Heading>
                        Login to <span>Leadflo</span>
                    </Heading>
                    <Form onSubmit={onLogin} error={error} />
                </FormContainer>
            </Left>
            <Right>
                <QuoteContainer>
                    <Quote />
                </QuoteContainer>
            </Right>
        </Container>
    );
};

const OShape = styled(LogoOSvg)`
    ${mix.width({ size: 6 })};
    ${mix.fill({ hue: 'primary', shade: '6' })};
    height: auto;
`;

const Container = styled.div`
    display: flex;
    height: 100vh;
`;

const Left = styled.div`
    ${mix.bg({ hue: 'grey', shade: '8' })};
    flex: 0 0 38%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const FormContainer = styled.div`
    ${mix.bg({ hue: 'white' })};
    ${mix.padding({ padding: 4 })};
`;

const Heading = styled.div`
    ${mix.type({ level: 'h2' })};
    ${mix.color({ profile: 'body' })};
    ${mix.margin({ margin: [4, 0] })};
    font-weight: bold;
    line-height: 1;

    span {
        color: ${mix.palette({ hue: 'primary', shade: '6' })};
    }
`;

const Right = styled.div`
    background-image: url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='29' height='50.115' patternTransform='scale(1) rotate(20)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(225, 48%, 13%, 1)'/><path d='M14.498 16.858L0 8.488.002-8.257l14.5-8.374L29-8.26l-.002 16.745zm0 50.06L0 58.548l.002-16.745 14.5-8.373L29 41.8l-.002 16.744zM28.996 41.8l-14.498-8.37.002-16.744L29 8.312l14.498 8.37-.002 16.745zm-29 0l-14.498-8.37.002-16.744L0 8.312l14.498 8.37-.002 16.745z'  stroke-width='1' stroke='hsla(225, 36%, 22%, 1)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>");
    background-color: #111931;
    flex: 1 0 auto;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const QuoteContainer = styled.div`
    position: absolute;
    right: ${mix.unit({ size: 6 })};
    bottom: ${mix.unit({ size: 6 })};
`;
