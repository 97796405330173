import angular from 'angular';
import SectionComponent from './section.component';
import SectionHeaderComponent from './section-header.component';
import SectionBodyComponent from './section-body.component';

export default angular
    .module('app.v2.shared.section', [])
    .component('v2Section', SectionComponent)
    .component('v2SectionHeader', SectionHeaderComponent)
    .component('v2SectionBody', SectionBodyComponent).name;
