import angular from 'angular';
import action from './action';
import confirmation from './confirmation';
import datepicker from './datepicker';
import icon from './icon';
import nextActions from './next-actions';
import searchBar from './search-bar';
import steps from './steps';
import reducers from './reducers';
import success from './success';

export default angular.module('app.v2.dashboard', [
    action,
    confirmation,
    datepicker,
    icon,
    nextActions,
    searchBar,
    steps,
    reducers,
    success
]).name;
