import template from './datepicker.html';
import { format, parse, parseISO } from 'date-fns';

function DatepickerController($scope) {
    var ctrl = this;

    ctrl.$onInit = function () {
        if (!ctrl.default) {
            ctrl.date = format(new Date(), 'dd MMMM yyyy');
            ctrl.default = new Date();
        } else {
            const date =
                typeof ctrl.default === 'string'
                    ? parseISO(ctrl.default)
                    : ctrl.default;
            ctrl.date = format(date, 'dd MMMM yyyy');
        }

        $scope.$watch('$ctrl.date', function (date, oldDate) {
            if (oldDate !== date) {
                ctrl.onChange({
                    date: parse(date, 'dd MMMM yyyy', new Date())
                });
            }
        });
    };
}

export default {
    template,
    controller: ['$scope', DatepickerController],
    bindings: {
        date: '<',
        default: '<',
        min: '<',
        onChange: '&'
    }
};
