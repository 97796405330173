import { add } from 'date-fns';
import { isoDate } from '@/utils/date';

const template = `
    <div class="step follow-up-date-step">
      <v2-back-button on-click="$ctrl.onBack()"></v2-back-button>
      <div class="action-form">
        <div>
          <label for="consultation-date-field">When should we contact {{ $ctrl.firstName() }} next? <small>(We suggest {{ $ctrl.suggestionText }})</small></label>
          <v2-datepicker date="$ctrl.date"
                         default="$ctrl.date"
                         min="$ctrl.minDate"
                         on-change="$ctrl.setFollowupDate(date)"></v2-datepicker>
        </div>
        <div>
          <label for="consultation-note-field">Is there anything to note about {{ $ctrl.firstName() }}?</label>
          <textarea class="form-text-field"
                    cols="30"
                    rows="3"
                    ng-model="$ctrl.note"
                    ng-change="$ctrl.changeNote()"
                    data-cy="action-booking-note"></textarea>
        </div>
      </div>
      <div class="buttons">
        <a class="primary-button button"
           ng-click="$ctrl.onNext()"
           data-cy="action-next-step">Next</a>
      </div>
    </div>
`;

export function FollowUpController() {
    var ctrl = this;

    ctrl.$onInit = function () {
        ctrl.minDate = add(new Date(), { days: -1 }).toISOString();

        var amount = ctrl.suggestion[0];
        var unit = ctrl.suggestion[1];

        ctrl.date = isoDate(add(new Date(), { [unit]: amount }));

        if (amount === 0 && unit) {
            ctrl.suggestionText = 'today!';
        } else {
            ctrl.suggestionText = ctrl.suggestion.join(' ');
        }

        ctrl.onDateChange({ date: ctrl.date });
    };

    ctrl.setFollowupDate = function (date) {
        ctrl.onDateChange({ date: date });
    };

    ctrl.changeNote = function () {
        ctrl.onNoteChange({ note: ctrl.note });
    };

    ctrl.firstName = function () {
        return ctrl.action.firstName ? ctrl.action.firstName : 'this patient';
    };
}

export default {
    template,
    controller: FollowUpController,
    bindings: {
        action: '<',
        suggestion: '<',
        onNext: '&',
        onBack: '&',
        onDateChange: '&',
        onNoteChange: '&'
    }
};
