import angular from 'angular';
import buttons from './buttons';
import field from './field';
import focusField from './focus-field';
import options from './options';
import optionsField from './options-field';
import checkbox from './checkboxes';
import optionsWithOther from './options-with-other';
import toggleField from './toggle-field';
import colorField from './color-field';
import fileArea from './file-area';
import accepts from './accepts';
import heading from './heading';
import section from './section';
import modal from './modal';

export default angular.module('app.v2.shared', [
    buttons,
    field,
    focusField,
    options,
    optionsField,
    optionsWithOther,
    checkbox,
    toggleField,
    colorField,
    fileArea,
    accepts,
    heading,
    section,
    modal,
]).name;
