import { RootState } from '@/state';
import { configState, isConfigured } from '@/state/queries/config';
import { from, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ConfigLoadedState } from '@/state/types';
import * as ws from '@/lib/websockets';
import Pusher from 'pusher-js';
import { clientState, isClientLoaded } from '$state/queries/client';
import { ClientLoadedState } from '$state/concerns/client';
import auth from '$auth';
import { lang } from '$utils';

let _client: Pusher | undefined;

interface Message {
    identity: string;
    payload: unknown;
}

interface MessageMap {
    [event: string]: (msg: Message) => void;
}

export const start = (store: Observable<RootState>, mapping: MessageMap) => {
    const state$ = from(store);

    let isSubscribedToWebsocketChannel = false;

    state$
        .pipe(filter((state) => isConfigured(state)))
        .pipe(filter((state) => isClientLoaded(clientState(state))))
        .pipe(
            map((state) => ({
                config: configState(state) as ConfigLoadedState,
                client: clientState(state) as ClientLoadedState
            }))
        )
        .pipe(distinctUntilChanged(lang.isEqual))
        .subscribe((state) => {
            if (isSubscribedToWebsocketChannel) {
                return;
            }

            isSubscribedToWebsocketChannel = true;

            if (!auth.authenticated()) {
                return;
            }

            // If adding to here, make sure you add it
            // to the state above
            const { config } = state.config;
            const { client } = state.client;

            _client = ws.client({
                enabled: config.pusher_enabled,
                key: config.pusher_key,
                cluster: config.pusher_cluster,
                hostname: config.pusher_host,
                port: config.ws_port,
                secure: config.ws_secure,
                authEndpoint: `${config.api_base_url}/broadcasting/auth`,
                token: auth.token()
            });

            const channel = _client?.subscribe(`private-events.${client.id}`);

            Object.entries(mapping).forEach((entry) => channel?.bind(...entry));
        });
};

export default { start };
