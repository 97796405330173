import template from './heading.html';
import { num } from '$utils';

class HeadingController {
    $onInit() {
        this.size = this.size ? parseInt(this.size) : 1;
        this.size = num.clamp(parseInt(this.size), 1, 6);
    }

    isSize(size) {
        return parseInt(this.size) === size;
    }
}

export default {
    template,
    controller: HeadingController,
    bindings: {
        size: '@'
    },
    transclude: true
};
