import React from 'react';
import { useAppSelector } from '$state/hooks';
import { Section } from '$ui/Settings/Section';
import { Subsection } from '$ui/Settings/Section/Subsection';
import { ConnectedAccordion } from '$ui/Templates';
import {
    selectChannel,
    selectTemplatableChannels
} from '$state/concerns/channels';
import { emailEnabled, smsEnabled } from '$state/queries/client';
import { Heading } from '$ui/Flo/Heading';
import { Icon } from '$ui/Flo/Icon';
import { Text } from '$ui/Flo/Text';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { ConnectedTemplateEditor } from '$ui/Templates/Editor';
import * as templates from '$state/concerns/templates';
import { Channels } from '$state/types';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { useTemplateParams } from '$ui/Templates/hooks';
import { withState } from '$state';
import { usePageTitle } from '@/utils/hooks';
import { whatsAppEnabled } from '$state/queries/integrations';
import { featureEnabled } from '$state/queries/features';

export const Templates = () => {
    usePageTitle('Templates - Settings');

    return (
        <Switch>
            <Route
                exact
                path="/settings/templates"
                render={() => (
                    <Frame>
                        <EmptyEditor />
                    </Frame>
                )}
            />

            <Route
                exact
                path="/settings/templates/:channelName/:templateId?"
                render={() => (
                    <Frame>
                        <RoutedSubsection />
                    </Frame>
                )}
            />
        </Switch>
    );
};

const Frame = (props: { children: React.ReactNode }) => {
    const whatsAppFeatureEnabled = useAppSelector(withState(featureEnabled, 'whatsapp'));

    const channels = useAppSelector(
        withState(selectTemplatableChannels, {
            SMS: useAppSelector(smsEnabled),
            Email: useAppSelector(emailEnabled),
            WhatsApp: useAppSelector(whatsAppEnabled) && whatsAppFeatureEnabled
        })
    );

    const accordions = channels.map((channel) => {
        return (
            <ConnectedAccordion
                channel={channel.channel}
                key={channel.channel}
            />
        );
    });

    return <Section sectionMenu={accordions}>{props.children}</Section>;
};

const RoutedSubsection = () => {
    const { channelName, templateId } = useTemplateParams();

    const template = useAppSelector(templates.selectTemplate(templateId));

    const channel = useAppSelector(
        selectChannel(template?.channel ?? channelName ?? Channels.Email)
    );

    if (templateId === undefined) {
        return (
            <Subsection
                id="details"
                title={`Add a New ${channel.label} Template`}
            >
                <ConnectedTemplateEditor />
            </Subsection>
        );
    }

    return (
        <Subsection
            id="details"
            title={`Edit ${channel.label} Template: '${template?.name}'`}
        >
            <ConnectedTemplateEditor />
        </Subsection>
    );
};

const EmptyEditor = () => {
    return (
        <Empty>
            <Icon icon="ArrowLeftCircle" size={16} hue="grey" />
            <Heading level="h3">Welcome to the Template Manager</Heading>
            <Text level="body1" profile="secondary">
                On the left, you'll find all your templates.
            </Text>
            <Text level="body2" profile="secondary">
                Click on any template to edit it. To create a new template,
                click the + icon next to the channel you want to add a template
                to.
            </Text>
        </Empty>
    );
};
const Empty = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: ${mix.unit({ size: 2 })};
`;
