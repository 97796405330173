import conf from '@/lib/config';

const apiConfig = ($httpProvider) => {
    const apiInterceptor = ($q) => {
        if (window.DISABLE_API_INTERCEPTOR) {
            return {};
        }

        return {
            request: (config) => {
                if (/\.html$/.test(config.url)) {
                    return config;
                }

                return $q.when(conf.get()).then((appConfig) => {
                    try {
                        return {
                            ...config,
                            url: new URL(config.url, appConfig.api_base_url)
                        };
                    } catch {
                        return config;
                    }
                });
            }
        };
    };

    const retryInterceptor = ($q, $injector) => {
        let incrementalTimeout = 1000;

        function retryRequest (httpConfig) {
            let $timeout = $injector.get('$timeout');
            let thisTimeout = incrementalTimeout;
            incrementalTimeout *= 2;
            return $timeout(function() {
                let $http = $injector.get('$http');
                return $http({ ...httpConfig, url: httpConfig.url.href });
            }, thisTimeout);
        }

        var interceptor = {};
        interceptor.responseError = function(response) {
            if (response.status === 500 && !!response.headers('Retry-After')) {
                if (incrementalTimeout < 3000) {
                    return retryRequest(response.config);
                }
                incrementalTimeout = 1000;
                return $q.reject(response);
            } else {
                incrementalTimeout = 1000;
            }
            return $q.reject(response);
        }
        return interceptor;
    }

    $httpProvider.interceptors.push(['$q', '$injector', retryInterceptor]);
    $httpProvider.interceptors.push(['$q', apiInterceptor]);
};

export default apiConfig;
