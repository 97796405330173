import React from 'react';
import { mix } from '$ui/Flo/util';
import styled, { css } from 'styled-components';
import { Attachment } from '$types';
import { isFile, isImage } from '@/utils/files';
import { Images, Files } from '$ui/Timeline/Parts/Attachments';
import { ColorToken } from '$ui/Flo/types';

interface AttachmentsProps {
    attachments: Attachment[];
    withoutMessage?: boolean;
    color?: ColorToken;
}

export const Attachments = ({
    attachments,
    withoutMessage,
    color,
}: AttachmentsProps) => {
    const images = attachments.filter((attachment) => isImage(attachment.type));
    const files = attachments.filter((attachment) => isFile(attachment.type));

    return (
        <Container
            withoutMessage={withoutMessage}
            color={color}
            title={'attachments-container'}
        >
            {files && files.length > 0 && (
                <Files files={files} alternative={withoutMessage} />
            )}
            {images && images.length > 0 && <Images images={images} />}
        </Container>
    );
};

interface ContainerProps {
    withoutMessage?: boolean;
    color?: ColorToken;
}

const Container = styled.div<ContainerProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: ${mix.unit({ size: 1 })};
    margin-top: ${mix.unit({ size: 1.5 })};

    ${({ withoutMessage, color }) =>
        withoutMessage &&
        css`
            width: 416px;
            margin-top: 0;
            padding: ${mix.unit({ size: 1 })};
            border-radius: ${mix.unit({ size: 0.5 })};
            background-color: var(--${color});
        `};
`;
