import * as React from 'react';
import styled, { css } from 'styled-components';
import { openEmailArgumentsV2 } from '$types';
import { CommType, Email as EmailV2 } from '$types/timeline-v2';
import { retryComms } from '$ui/Timeline/TimelineV2';
import * as Parts from '../Parts';

interface Props {
    patientName: string;
    representative: {
        photo: string | null;
        name: string | null;
    };
    practiceName: string;
    email: EmailV2;
    openModal: (args: openEmailArgumentsV2) => void;
    retry?: (comm: retryComms) => void;
    attachmentsEnabled?: boolean;
}

export const EmailEvent = (props: Props) => {
    const { email } = props;

    const snippet = makeSnippet(email.text_content);

    return (
        <Parts.Container
            data-testid={email.id}
            title={email.subject}
            role="listitem"
            data-cy="comm"
            inbound={email.inbound}
        >
            {buildEvent(props, snippet)}
        </Parts.Container>
    );
};

/**
 * Build event
 * @param props
 * @param snippet
 */
const buildEvent = (props: Props, snippet: string) => {
    const { email, retry, openModal, attachmentsEnabled } = props;
    const color = email.inbound ? 'gray-100' : 'primary-500';
    const status: string = email.status.toLowerCase();
    const openable = Boolean(email.id) && status !== 'failed';

    const handleOpen = () => {
        if (!openable) {
            return;
        }

        if (email.id) {
            openModal({
                fromEmail: email.from_email,
                toEmail: email.to_email,
                subject: email.subject,
                status: email.status,
                commName: email.id,
                date: email.datetime,
            });
        }
    };

    const failed = status === 'failed';

    return (
        <>
            <Parts.Avatar
                isAutomation={email.comm_type === CommType.MarketingEmail}
                type="Email"
                color={color}
                inbound={email.inbound}
                {...props}
            />
            <Parts.Event inbound={email.inbound}>
                <Clickable
                    data-testid={`clickable-${email.id}`}
                    onClick={handleOpen}
                    clickable={openable}
                >
                    <Parts.Content
                        type={email.type}
                        title={email.subject}
                        color={color}
                        inbound={email.inbound}
                    >
                        {snippet}
                    </Parts.Content>
                </Clickable>
                <Parts.Metadata
                    failed={failed}
                    retrying={email.retry}
                    retry={() => retry && retry(email)}
                    inbound={email.inbound}
                >
                    <Parts.Status
                        status={status}
                        date={email.datetime}
                        inbound={email.inbound}
                    />
                </Parts.Metadata>
                {attachmentsEnabled && email.attachments && (
                    <Parts.Attachments attachments={email.attachments} />
                )}
            </Parts.Event>
            {(failed || email.retry?.active) && <Parts.FailedIcon />}
        </>
    );
};

const makeSnippet = (text: string): string => {
    let snippet = text.substring(0, 150);
    if (snippet.length !== text.length) {
        snippet = snippet + '...';
    }
    return snippet;
};

interface ContainerProps {
    clickable?: boolean;
}

export const Clickable = styled.div<ContainerProps>`
    ${({ clickable }) => {
        return (
            clickable &&
            css`
                &:hover {
                    cursor: pointer;
                }
            `
        );
    }}
`;
