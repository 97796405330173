import template from './toggle-field.html';

function ToggleFieldController() {
    var ctrl = this;

    ctrl.$onInit = function () {
        ctrl.texts = {
            true: ctrl.onText,
            false: ctrl.offText
        };
    };

    ctrl.toggle = function () {
        ctrl.value = !ctrl.value;
        ctrl.onChanged({ value: ctrl.value });
    };

    ctrl.text = function () {
        return ctrl.texts[Boolean(ctrl.value)];
    };

    ctrl.controlText = function () {
        return ctrl.value ? 'Yes' : 'No';
    };
}

export default {
    template,
    controller: ToggleFieldController,
    bindings: {
        label: '@',
        value: '<',
        onText: '@',
        offText: '@',
        onChanged: '&'
    }
};
