function FocusFieldController() {
    var ctrl = this;

    ctrl.focusers = [];

    ctrl.addFocuser = function(focuser) {
        ctrl.focusers.push(focuser);
        focuser.on('click', function() {
            ctrl.focusable.get(0).focus();
            console.log('focuser clicked');
        });
    };

    ctrl.setFocusable = function(focusable) {
        ctrl.focusable = focusable;
    };
}

export default function focusField() {
    return {
        restrict: 'A',
        scope: {},
        controller: FocusFieldController,
        controllerAs: 'ctrl'
    };
}
