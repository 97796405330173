import template from './back-button.html';
import { lang } from '$utils';

function BackButtonController() {
    var ctrl = this;

    ctrl.$onInit = function () {
        ctrl.back = ctrl.onClick;

        if (lang.isEmpty(ctrl.text)) {
            ctrl.text = 'Go back';
        }
    };
}

export default {
    template,
    controller: BackButtonController,
    bindings: {
        text: '@',
        onClick: '&'
    }
};
