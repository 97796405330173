import template from './section.html';

class SectionController {}

export default {
    template,
    controller: SectionController,
    transclude: true,
    bindings: {
        header: '@',
        size: '@',
        open: '<',
        collapsible: '<'
    }
};
