import validator from 'validator';
import * as patients from '$bridge/edit-patient';
import { store } from '$state';
import { txTypes } from '$state/queries/auth';
import { pipeline } from '$state/queries/client';
import { listSources, selectSourceNames } from '$state/concerns/sources';
import { array, fn, lang } from '$utils';
import { parseNumber } from 'libphonenumber-js';
import { selectClientCountry } from '$state/concerns/client';

const template = `
<div class='container baseline-top'>
  <patient></patient>
</div>
<div class='container container-thin baseline-bottom-3'>
  <div class='baseline-top'>
    <v2-back-button on-click='$ctrl.close()'></v2-back-button>
  </div>

  <div class='section is-open'>
    <v2-success
      ng-if='$ctrl.isState($ctrl.states.SUCCESS)'
      action='$ctrl.action'
      is-sticky='true'
      next-action='$ctrl.nextAction'
      on-close='$ctrl.close()'
    ></v2-success>

    <div
      class='success-button-group'
      ng-if='$ctrl.isState($ctrl.states.SUCCESS)'
    >
      <div
        class='success-button success-button-secondary'
        ng-click='$ctrl.addNewPatient()'
      >
        <i class='zmdi zmdi-plus-circle-o success-button-icon'></i>
        Add Another Patient
      </div>
      <div class='success-button success-button-primary' ng-click='$ctrl.goToDashboard()' data-cy='go-to-dashboard'>
        <i class='zmdi zmdi-view-dashboard success-button-icon'></i>
        Go to Dashboard
      </div>
    </div>

    <div class='section-header section-header--with-icon'>
      <i class='fa fa-user-plus section-header-icon'></i>
      Add a <strong>New Patient</strong>
    </div>

    <div class='section-body form'>
      <v2-field
        id='email'
        validator='$ctrl.validator.isEmail'
        validation-error='Please enter a correct email address'
        icon='envelope'
        label="What is the patient's email address?"
        type='email'
        placeholder='e.g john@leadflo.com'
        value='$ctrl.email'
        show-error='$ctrl.error.email'
        on-changed='$ctrl.onEmailChanged(value, valid)'
        data-cy='new-patient-email'
      ></v2-field>

      <div class='email-exists-message-container'>
        <div
          class='email-exists-message-bg'
          ng-if='$ctrl.shouldDisableFields()'
        >
          <div
            class='email-exists-message email-exists-message-content'
            ng-if='$ctrl.isState($ctrl.states.EMAIL_EXISTS)'
          >
            <div class='email-exists-message-text'>
              A patient with this email already exists!
            </div>
            <div
              class='email-exists-message-button'
              ng-click='$ctrl.editExistingPatient()'
            >
              Edit this patient
            </div>
          </div>

          <div
            class='alert email-exists-message-content'
            ng-if='$ctrl.isState($ctrl.states.CHECKING_EMAIL)'
          >
            <div class='alert-icon'>
              <i class='fa fa-cog alert-icon-spin'></i>
            </div>
            <div class='alert-message'>
              <div class='alert-message-header'>Give us two shakes!</div>
              <div class='alert-message-text'>
                We're just checking if this patient already exists.
              </div>
            </div>
          </div>

          <div
            class='alert email-exists-message-content'
            ng-if='$ctrl.isState($ctrl.states.START)'
          >
            <div class='alert-icon'>
              <i class='fa fa-caret-up alert-icon-bounce'></i>
            </div>
            <div class='alert-message'>
              <div class='alert-message-header'>First things first</div>
              <div class='alert-message-text'>
                We need to check if a patient with this email already exists.
              </div>
            </div>
          </div>
        </div>
        <div
          class='email-exists-message-disabled-content'
          ng-class="{ 'is-disabled': $ctrl.shouldDisableFields() }"
        >
          <v2-field
            id='full-name'
            validator='$ctrl.isNotEmpty'
            validation-error="Please enter the patient's full name"
            icon='user'
            label="What is the patient's full name?"
            placeholder='e.g John Smith'
            value='$ctrl.name'
            show-error='$ctrl.error.name'
            on-changed='$ctrl.onNameChanged(value, valid)'
            data-cy='new-patient-name'
          ></v2-field>

          <v2-field
            id='phone'
            icon='phone'
            validator='$ctrl.isValidPhone'
            validation-error='Please enter a correct phone number'
            label="What is the patient's phone number?"
            placeholder='e.g 0161 111 2222'
            value='$ctrl.phone'
            show-error='$ctrl.error.phone'
            on-changed='$ctrl.onPhoneChanged(value, valid)'
            data-cy='new-patient-phone'
          ></v2-field>

          <v2-options-field
            id='tx-types'
            label='What type of treatment is the patient interested in?'
            options='$ctrl.txTypes'
            value='$ctrl.txType'
            show-error='$ctrl.error.txType'
            on-changed='$ctrl.onTxTypeChanged(value)'
          ></v2-options-field>

          <label class='form-label' for=''>Where did the patient hear about us?</label>
          <div
            class='dropdown-field form-text-field-container has-icon'
            v2-focus-field>
            <select class='form-text-field single-select'
              id='source'
              v2-focusable-field
              ng-model='$ctrl.source'
              style='background-color: white;'
              data-cy='lead-source'>
                <option value='{{ item.id }}' ng-repeat='item in $ctrl.sources'>{{ item.name }}</option>
            </select>
          </div>

          <v2-toggle-field
            label='Has this patient verbally consented to marketing communications?'
            value='$ctrl.consent'
            on-changed='$ctrl.onConsentToggled(value)'
            on-text='We can send this patient marketing emails'
            off-text='We cannot send this patient marketing emails'
            data-cy='new-patient-gdpr'
          ></v2-toggle-field>

          <div
            class='buttons baseline-top zero-margin-bottom'
            ng-if='! $ctrl.showingNextActions'
          >
            <v2-next-button on-click='$ctrl.showNextActions()'></v2-next-button>
            <v2-popover
              align='left'
              ng-if='! $ctrl.isValid'
              on-done='$ctrl.onHideError()'
              type='error'
            >
              Please complete all fields correctly before moving forward!
            </v2-popover>
          </div>
        </div>
      </div>
    </div>

    <v2-next-action-flo
      enabled='$ctrl.showingNextActions'
      is-saving='$ctrl.isState($ctrl.states.SAVING)'
      action='$ctrl.action'
      on-complete='$ctrl.onComplete(nextAction, date, note, provider)'
      pipeline='$ctrl.pipeline'
    ></v2-next-action-flo>
  </div>
</div>
`;

const States = {
    START: 'start',
    CHECKING_EMAIL: 'checkingEmail',
    EMAIL_EXISTS: 'emailExists',
    EMAIL_OK: 'emailOk',
    SAVING: 'saving',
    SUCCESS: 'success',
    FAILED: 'failed',
};

function controller($state, $rootScope, $ngRedux, $anchorScroll, Actions) {
    var ctrl = this;

    const fetchData = () => {
        const state = store.getState();

        ctrl.txTypes = Object.fromEntries(
            txTypes(state).map((value) => [value, value]),
        );

        ctrl.sources = selectSourceNames(state).map((value) => ({
            id: value,
            name: value,
        }));

        ctrl.pipeline = pipeline(state);

        ctrl.country = selectClientCountry(state);
    };

    store.subscribe(fetchData);

    ctrl.$onInit = function () {
        store.dispatch(listSources());

        fetchData();

        ctrl.states = States;
        ctrl.state = States.START;

        ctrl.providers = [
            { id: 1, name: 'Dr John Paul' },
            { id: 2, name: 'Dr David Beckham' },
        ];

        ctrl.consent = false;

        ctrl.validator = validator;

        ctrl.isTxTypeValid = true;
        ctrl.isValid = true;
        ctrl.valid = {
            email: false,
            name: false,
            phone: false,
            txType: false,
        };

        ctrl.isValidPhone = (number) => {
            const parsedNumber = parseNumber(number, {
                defaultCountry: ctrl.country,
                extended: true,
            });
            return Boolean(parsedNumber.possible);
        };

        ctrl.nextAction = 'booking';

        ctrl.error = {};

        ctrl.action = { stage: 'newPatient' };

        ctrl.email = void 0;
        ctrl.phone = void 0;
        ctrl.txType = void 0;
        ctrl.name = void 0;
        ctrl.consent = void 0;
        ctrl.source = void 0;
    };

    ctrl.isState = function (state) {
        return ctrl.state === state;
    };

    ctrl.isNotEmpty = function (value) {
        return !validator.isEmpty(value, { ignore_whitespace: true });
    };

    ctrl.onEmailChanged = function (value, valid) {
        ctrl.email = value;
        ctrl.valid.email = valid;
        ctrl.action.email = value;

        if (lang.isEmpty(value)) {
            ctrl.state = States.START;
        }

        if (!valid) {
            return;
        }

        ctrl.state = States.CHECKING_EMAIL;
        Actions.exists(value).then(function (existing) {
            if (existing.status) {
                ctrl.state = States.EMAIL_EXISTS;
                ctrl.existingPatientId = existing.patient_id;
            } else {
                ctrl.state = States.EMAIL_OK;
            }
        });
    };

    ctrl.shouldDisableFields = function () {
        return (
            ctrl.isState(States.START) ||
            ctrl.isState(States.EMAIL_EXISTS) ||
            ctrl.isState(States.CHECKING_EMAIL)
        );
    };

    ctrl.editExistingPatient = function () {
        patients.open(ctrl.existingPatientId);
    };

    ctrl.onNameChanged = function (value, valid) {
        ctrl.name = value;
        ctrl.valid.name = valid;
        ctrl.consent = false;

        var names = value.split(' ');
        if (names.length === 1) {
            ctrl.action.firstName = value;
        } else {
            ctrl.action.firstName = array.dropRight(names).join(' ');
            ctrl.action.lastName = array.last(names);
        }
    };

    ctrl.onPhoneChanged = function (value, valid) {
        ctrl.phone = value;
        ctrl.valid.phone = valid;
        ctrl.action.phone = value;
    };

    ctrl.onTxTypeChanged = function (value) {
        ctrl.txType = value;
        ctrl.valid.txType = true;
        ctrl.error.txType = false;
        ctrl.action.txType = value;
    };

    ctrl.onConsentToggled = function (value) {
        ctrl.consent = value;
        ctrl.action.gdpr = value;
    };

    ctrl.onHideError = function () {
        ctrl.isValid = true;
        ctrl.highlight = Object.keys(ctrl.valid).find((x) => !ctrl.valid[x]);
    };

    ctrl.isHighlighted = function (field) {
        return ctrl.highlight === field;
    };

    ctrl.showNextActions = function () {
        ctrl.isValid = Object.values(ctrl.valid).every(fn.identity);
        ctrl.error = Object.values(ctrl.valid).map((x) => !x);
        if (!ctrl.source) {
            ctrl.isValid = false;
        }

        if (ctrl.isValid) {
            ctrl.showingNextActions = true;
        }
    };

    ctrl.onComplete = function (nextAction, date, note, provider) {
        ctrl.state = States.SAVING;

        Actions.persist({
            ...ctrl.action,
            ...{
                nextAction: nextAction,
                date: date,
                note: note,
                provider: provider,
                gdpr: ctrl.consent,
                source: ctrl.source,
            },
        })
            .then(function (action) {
                ctrl.state = States.SUCCESS;
            })
            .catch(() => {
                ctrl.state = States.FAILED;
            });
    };

    ctrl.close = function () {
        $state.go('dashboard');
    };

    ctrl.addNewPatient = function () {
        $anchorScroll();
        $state.go(
            'add-patient',
            {},
            {
                reload: true,
                notify: true,
            },
        );
        ctrl.$onInit();
    };

    ctrl.goToDashboard = function () {
        $state.go('dashboard');
    };
}

export const addPatient = {
    template,
    controller: [
        '$state',
        '$rootScope',
        '$ngRedux',
        '$anchorScroll',
        'Actions',
        controller,
    ],
};
