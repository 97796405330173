import template from './booking.html';
import { lang, obj } from '$utils';

function BookingController($ngRedux, NextActions) {
    var ctrl = this;

    $ngRedux.connect((o) => obj.pick(o, ['providers']))(this);

    ctrl.$onInit = function () {
        ctrl.apptText = NextActions.getApptType(
            ctrl.action.stage,
            ctrl.nextAction
        );

        if (ctrl.date === undefined) {
            ctrl.setBookingDate(new Date());
        }
    };

    ctrl.firstName = function () {
        return ctrl.action.firstName ? ctrl.action.firstName : 'this patient';
    };

    ctrl.setBookingDate = function (date) {
        ctrl.onBookingDateChanged({ date: date });
    };

    ctrl.changeNote = function () {
        ctrl.onNoteChanged({ note: ctrl.note });
    };

    ctrl.hasProvider = function () {
        return !lang.isEmpty(ctrl.action.provider);
    };

    ctrl.showProviderSelect = function () {
        return !lang.isEmpty(ctrl.providers) && !ctrl.hasProvider();
    };
}

export default {
    template,
    controller: ['$ngRedux', 'NextActions', BookingController],
    bindings: {
        action: '<',
        provider: '<',
        nextAction: '<',
        note: '<',
        date: '<',
        onNext: '&',
        backText: '@',
        onBack: '&',
        onBookingDateChanged: '&',
        onNoteChanged: '&',
        onProviderChanged: '&'
    }
};
