import template from './options.html';

function OptionsController() {
    var ctrl = this;

    ctrl.select = function (option, index) {
        ctrl.selected = index;
        ctrl.onChange({ option: option, index: index });
    };

    ctrl.isActive = function (index) {
        return ctrl.selected === index;
    };
}

export default {
    controller: OptionsController,
    template,
    bindings: {
        selected: '<',
        onChange: '&',
        options: '<'
    }
};
