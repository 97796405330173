const template = `
    <div class="container container-thin">
        <div class="section is-open baseline-top">
            <div class="section-header">
                <i class="zmdi zmdi-alert-triangle section-header-icon"></i>
                Unauthorized!
            </div>
            <div class="section-body">
                <p class="text-center">I'm afraid you must have got here by accident, you don't have permissions to view this page.</p>
            </div>
        </div>
    </div>
`;

export const unauthorized = {
    template,
    controller: () => {}
};
