import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

export const api = axios.create({
    headers: {
        'Content-Type': 'application/json'
    }
});

export const storage = axios.create({
    headers: {
        'Content-Type': 'application/json'
    }
});

interface ConfigSource {
    (): {
        authToken?: string;
        URL?: string;
        proxyURL?: string;
        onAuthError: () => void;
        setApiVersion: (version: string) => void;
    };
}

const intercept = (
    instance: AxiosInstance,
    urlKey: string,
    configSource: ConfigSource
) => {
    instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
        const { authToken, ...conf } = configSource();

        if (authToken) {
            config.headers.Authorization = 'Bearer ' + authToken;
        }

        if (conf[urlKey]) {
            config.baseURL = conf[urlKey];
        }

        return config as InternalAxiosRequestConfig;
    });

    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            const { onAuthError } = configSource();

            if (!error.response || !error.response.status) {
                return Promise.reject(error);
            }

            if (error.response.status === 401) {
                onAuthError();
                return Promise.reject(error);
            }

            return Promise.reject(error);
        }
    );
};

const start = (configSource: ConfigSource) => {
    intercept(api, 'URL', configSource);
    intercept(storage, 'proxyURL', configSource);

    api.interceptors.response.use((response) => {
        const version = response.headers['x-api-version'] || null;
        if (!version) {
            return response;
        }

        const { setApiVersion } = configSource();
        setApiVersion(version);
        return response;
    });
};

export default { start };
