import template from './next-actions.html';

function NextActionsController(NextActions) {
    var ctrl = this;

    ctrl.$onInit = function () {
        if (ctrl.reschedule) {
            ctrl.nextActions = NextActions.forReschedule();
        } else {
            ctrl.nextActions = NextActions.forStage(ctrl.action);
        }
        ctrl.showError = false;
        ctrl.nextAction = undefined;
    };

    ctrl.setNextAction = function (_, index) {
        ctrl.nextAction = index;
    };

    ctrl.chooseNextAction = function () {
        ctrl.showError = ctrl.nextAction === undefined;
        if (ctrl.showError) {
            return;
        }
        ctrl.onChooseNextAction({ nextAction: ctrl.nextAction });
    };

    ctrl.hideError = function () {
        ctrl.showError = false;
    };
}

export default {
    template,
    controller: ['NextActions', NextActionsController],
    bindings: {
        action: '<',
        nextAction: '<',
        hideBack: '<',
        onNext: '&',
        onBack: '&',
        onChooseNextAction: '&',
        reschedule: '<'
    }
};
