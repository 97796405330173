import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import { convertToBase64 } from '@/utils/files';
import { Attachment } from '$types';
import { fetchAttachment } from '$api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
    LoadAttachment,
    LoadingAttachment,
    NotFoundAttachment,
} from '$ui/Timeline/Parts';
import { useInView } from 'react-intersection-observer';

interface ImageProps {
    data: ArrayBuffer;
    alt: string;
    type: string;
    onClick?: () => void;
}

const Image = ({ data, type, alt, onClick }: ImageProps) => {
    const base64 = data && convertToBase64(data);
    return (
        <Img
            src={`data:${type};base64,${base64}`}
            alt={alt}
            onClick={onClick}
            width={200}
            height={200}
            data-testid={`clickable-${alt}`}
        />
    );
};

interface DisplayImageProps {
    image: Attachment;
    toggleLightbox: () => void;
}

export const DisplayImage = ({ image, toggleLightbox }: DisplayImageProps) => {
    const [paused, setPaused] = React.useState<boolean>(false);
    const queryKey = ['images', image.url];
    const queryClient = useQueryClient();
    const { ref, inView } = useInView();
    const { isFetched, isError, isLoading, data, refetch, isFetching } =
        useQuery({
            queryKey: queryKey,
            queryFn: ({ signal }) => fetchAttachment(image.url, signal),
            retry: false,
            retryOnMount: false,
            refetchOnWindowFocus: false,
            enabled: inView && !paused,
        });

    const onRefetch = async () => {
        setPaused(false);
        await refetch();
    };

    const onCancelFetch = () => {
        setPaused(true);
        queryClient.cancelQueries({ queryKey: queryKey });
    };

    if (isFetched && data) {
        return (
            <ImageWrap>
                <Image
                    data={data}
                    alt={image.name}
                    type={image.type}
                    onClick={toggleLightbox}
                />
            </ImageWrap>
        );
    }

    if (isError && !paused) {
        return <NotFoundAttachment onClick={onRefetch} />;
    }

    if (isLoading && !paused && isFetching) {
        return <LoadingAttachment onClick={onCancelFetch} />;
    }

    return <LoadAttachment ref={ref} onLoad={onRefetch} />;
};

const Img = styled.img`
    border-radius: ${mix.unit({ size: 0.5 })};
    width: auto;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    max-width: 80vw;
    max-height: 80vh;
`;

const ImageWrap = styled.div`
    display: flex;
    width: 100%;
    height: 100%;

    > img {
        flex: 1 1 auto;
        width: 100%;
        height: 100%;
        object-fit: cover;
        min-width: 200px;
    }
`;
