const template = `
<div class="container container-thin">
    <v2-heading> Change stage </v2-heading>

    <v2-search-bar
    on-add-patient-clicked="$ctrl.onAddPatientClicked()"
    on-patient-clicked="$ctrl.onPatientClicked(id, patientId)"
    dont-open-editor="true"
    ></v2-search-bar>

    <div
    class="alert email-exists-message-content"
    ng-if="$ctrl.patientLoading()"
    >
    <div class="alert-icon">
        <i class="fa fa-cog alert-icon-spin"></i>
    </div>
    <div class="alert-message">
        <div class="alert-message-header">Give us two shakes!</div>
        <div class="alert-message-text">
        We're just pulling up the patient's details.
        </div>
    </div>
    </div>

    <v2-section
    ng-if="$ctrl.patientLoaded()"
    size="medium"
    open="true"
    collapsible="false"
    >
    <v2-section-header>
        Changing the stage for <strong>{{ $ctrl.patientName() }}</strong>
    </v2-section-header>
    <v2-section-body>
        <div class="form">
        <div class="message-success" ng-if="$ctrl.saved()">
            <strong>Success!</strong> {{ $ctrl.patientName() }} is now in stage
            '{{ $ctrl.stage.display_name }}'
        </div>
        <p class="body">
            <strong>Current stage</strong>
        </p>
        <v2-heading size="5"> {{ $ctrl.renderStage() }} </v2-heading>
        <p class="body"><strong> New stage </strong></p>
        <div
            class="providers-field dropdown-field form-text-field-container has-icon"
            v2-focus-field
        >
            <div class="form-text-field-icon" v2-field-focuser>
            <i class="fa fa-plus"></i>
            </div>
            <ui-select
                class="form-text-field single-select"
                theme="bootstrap"
                id="stage-select"
                class="has-icon"
                v2-focusable-field
                ng-model="$ctrl.stage"
                on-select="$ctrl.onStageSelected($item, $model)"
            >
            <ui-select-match placeholder="Select a stage...">
                {{ $select.selected.display_name }}
            </ui-select-match>
            <ui-select-choices
                repeat="stage in ($ctrl.stages | filter: { name: $select.search }) track by stage.name"
            >
                <div ng-bind-html="stage.display_name | highlight: $select.search"></div>
            </ui-select-choices>
            </ui-select>
        </div>
        <div class="buttons">
            <v2-next-button
            on-click="$ctrl.onSave()"
            ng-if="$ctrl.displaySave()"
            lf-disabled="$ctrl.saving()"
            >
            Save
            <i
                class="fa fa-cog alert-icon-spin primary-button__icon"
                ng-if="$ctrl.saving()"
            ></i>
            </v2-next-button>
        </div>
        </div>
    </v2-section-body>
    </v2-section>
</div>
`;

const STATES = {
    INITIAL: 'initial',
    PATIENT_LOADING: 'patient_loading',
    PATIENT_LOADED: 'patient_loaded',
    STAGE_SELECTED: 'stage_selected',
    PATIENT_SAVING: 'patient_saving',
    PATIENT_SAVED: 'patient_saved'
};

class controller {
    constructor($state, SyncService) {
        // ui-router
        this.$state = $state;
        this.svc = SyncService;

        SyncService.listStages().then((stages) => {
            this.stages = stages;
        });

        this.state = STATES.INITIAL;
    }

    patientLoading() {
        return this.state === STATES.PATIENT_LOADING;
    }

    onPatientClicked(id, patientId) {
        this.state = STATES.PATIENT_LOADING;
        this.svc.findPatient(patientId).then((patient) => {
            this.action = patient;
            this.state = STATES.PATIENT_LOADED;
        });
    }

    patientName() {
        if (!this.action) {
            return '';
        }

        return [this.action.first_name, this.action.last_name].join(' ');
    }

    renderStage() {
        if (!this.action) {
            return '';
        }

        if (!this.stages) {
            return '';
        }

        const stage = this.stages.find(
            (stage) => stage.name === this.action.stage
        );
        return stage?.display_name;
    }

    onStageSelected() {
        this.state = STATES.STAGE_SELECTED;
    }

    patientLoaded() {
        return (
            this.state !== STATES.INITIAL &&
            this.state !== STATES.PATIENT_LOADING
        );
    }

    onAddPatientClicked() {
        this.$state.go('add-patient');
    }

    displaySave() {
        return (
            this.state === STATES.STAGE_SELECTED ||
            this.state === STATES.PATIENT_SAVING
        );
    }

    saving() {
        return this.state === STATES.PATIENT_SAVING;
    }

    saved() {
        return this.state === STATES.PATIENT_SAVED;
    }

    onSave() {
        this.state = STATES.PATIENT_SAVING;
        this.svc
            .savePatient(this.action.id, this.stage.name)
            .then((success) => {
                this.state = STATES.PATIENT_SAVED;
                this.action.stage = this.stage;
            });
    }
}

export const changeStage = {
    template,
    controller: ['$state', 'SyncService', controller]
};
