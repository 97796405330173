export type Ok<T> = { ok: true; value: T };

export type Err<E = Error> = { ok: false; err: E };

export type Result<T, E = Error> = Ok<T> | Err<E>;

export function ok<T>(value: T): Result<T> {
    return { ok: true, value };
}

export function err<T, E extends Error>(err: E): Result<T, E> {
    return { ok: false, err };
}
