import * as React from 'react';
import { TopNavItem, SubNavItem, NavCountingStrategy } from '$types';
import styled from 'styled-components';
import { Item } from './Item';
import { SubmenuItem } from './SubmenuItem';
import { applyBg } from '$ui/Flo/util';

interface Props {
    data: TopNavItem[];
    navigate: (dest: string) => void;
    role: string;
    currentRoute: string;
}

export const Navigation = (props: Props) => {
    const { data, navigate: onNavigate, role, currentRoute } = props;
    const [previewing, setPreviewing] = React.useState<string | null>(null);

    const navigate = (to: string) => {
        setPreviewing(null);
        onNavigate(to);
    };

    const items = data.map((item) => {
        if (item.roles && !item.roles.includes(role)) {
            return;
        }

        const uri = item.destination?.split('/')[1];
        const isInUri =
            (currentRoute && uri && currentRoute.includes(uri)) || false;

        if (!item.submenu) {
            return (
                <Item
                    title={item.name}
                    icon={item.icon}
                    selected={currentRoute == item.destination || isInUri}
                    key={item.name}
                    onClick={() => navigate(item.destination!)}
                />
            );
        }

        return (
            <Item
                title={item.name}
                icon={item.icon}
                counter={submenuCounts(item)}
                key={item.name}
                selected={
                    submenuIsSelected(item.submenu!, currentRoute) ||
                    item.name == previewing
                }
                onClick={() => setPreviewing(item.name)}
            >
                {submenu(item.submenu!, currentRoute, navigate)}
            </Item>
        );
    });

    return <Container>{items}</Container>;
};

/** Background color container */
const Container = styled.div`
    ${({ theme }) => applyBg({ theme, hue: 'primary', shade: '2' })}
`;

/** Maps a list of subnavitems into a submenu */
const submenu = (
    items: SubNavItem[],
    visiting: string,
    navigate: (dest: string) => void
): React.ReactElement[] => {
    return items.map((item) => (
        <SubmenuItem
            item={item.name}
            counter={item.count}
            selected={visiting == item.destination}
            key={item.destination}
            onClick={() => navigate(item.destination)}
        />
    ));
};

const submenuCounts = (item: TopNavItem): number => {
    if (!item.submenu) {
        return 0;
    }

    if (item.countingStrategy === NavCountingStrategy.FIRST) {
        return item.submenu[0].count ?? 0;
    }

    return item.submenu.reduce(
        (count: number, item) => (count += item.count),
        0
    );
};

/** Returns whether an item an a submenu is currently being visited */
const submenuIsSelected = (items: SubNavItem[], visiting: string): boolean => {
    return items.findIndex((item) => item.destination === visiting) !== -1;
};
