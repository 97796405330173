import React from 'react';
import { openEmailArguments, openEmailArgumentsV2 } from '$types/patient';
import { ConnectedTimeline } from '$ui/Timeline/ConnectedTimeline';
import { EmailModal } from '$ui/EmailModal';
import styled from 'styled-components';
import { ModalBackground, ModalOverlay } from '$ui/Flo/Modal';
import { useAppDispatch, useAppSelector } from '$state/hooks';
import { fetchEmail } from '$state/concerns/inbox';
import { emailMessage } from '$state/queries/inbox';
import { Channels } from '$state/types';

interface Props {
    patientId: string;
    selectChannelAfterLoad?: Channels;
    showRefreshButton?: boolean;
}

// temporary until we come to integrate new modal with
// activities component system wide, w/o increasing complexity
// of the Inbox view
/**
 * Temporary until we come to integrate new modal with activities component
 * system wide, w/o increasing complexity of the Inbox view.
 *
 * Memoized because this is quite a large component tree and is used in
 * several places where re-renders are common.
 */
export const TimelineContainer = React.memo(
    ({
        patientId,
        selectChannelAfterLoad,
        showRefreshButton = true,
    }: Props) => {
        const dispatch = useAppDispatch();
        const [commId, setCommId] = React.useState<string | null>(null);
        const email = useAppSelector(emailMessage(commId));

        React.useEffect(() => {
            setCommId(null);
        }, [patientId]);

        const open = (args: openEmailArguments | openEmailArgumentsV2) => {
            dispatch(
                fetchEmail({
                    patientId,
                    ...args,
                }),
            );
            setCommId(args.commName);
        };

        const close = () => {
            setCommId(null);
        };

        return (
            <Container>
                {commId && email && (
                    <ModalOverlay>
                        <ModalBackground onClick={close} />
                        <EmailModal
                            state={email.state}
                            subject={email.subject}
                            onClose={close}
                            body={email.body}
                        />
                    </ModalOverlay>
                )}

                <ConnectedTimeline
                    patientId={patientId}
                    openEmailModal={open}
                    selectChannelAfterLoad={selectChannelAfterLoad}
                    showRefreshButton={showRefreshButton}
                />
            </Container>
        );
    },
);

TimelineContainer.displayName = 'TimelineContainer';

const Container = styled.div`
    flex: 2;
    position: relative;
    overflow: hidden;
    height: 100%;
`;
