const template = `
    <div class='overflow-protector'>
        <v2-action-category
            ng-repeat='category in $ctrl.categories'
            category='category'
            actions='$ctrl.actions'
            providers='$ctrl.providers'
            sort='asc'
        ></v2-action-category>
    </div>
`;

function controller($timeout, $rootScope, $stateParams, $state, Actions) {
    var ctrl = this;

    ctrl.$onInit = function () {
        ctrl.refreshing = ctrl.refreshing ?? false;

        ctrl.title = ctrl.title ?? 'Actions Due';

        ctrl.categories = ctrl.categories ?? [
            { name: 'New Leads', capture: ['newLead'] },
            { name: 'Thinking of Going Ahead', capture: ['thinking'] },
            {
                name: 'Follow Ups',
                capture: ['callback1', 'callback2', 'callback3', 'working']
            },
            {
                name: 'Appointment Outcomes',
                capture: ['txPlanConsult', 'consultation', 'inTx', 'won']
            }
        ];

        ctrl.onRefresh = () => {
            if (ctrl.preventDefault && ctrl.refreshActions) {
                ctrl.refreshActions();
                return;
            }

            ctrl.refreshing = true;
        };
    };
    if ($stateParams.id) {
        $timeout(function () {
            $rootScope.$broadcast('patientOpened', $stateParams.id);
        });
    }

    ctrl.onAddPatient = () => {
        $state.go('add-patient');
    };

    ctrl.onOpenPatient = (id) => {
        $rootScope.$broadcast('patientOpened', id);
    };
}

export const dashboard = {
    template,
    bindings: {
        preventDefault: '<',
        refreshActions: '<',
        title: '<',
        actions: '<',
        categories: '<',
        refreshing: '<'
    },
    controller: [
        '$timeout',
        '$rootScope',
        '$stateParams',
        '$state',
        'Actions',
        controller
    ]
};
