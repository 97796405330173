import $ from 'jquery';

const templateUrl = require('./back-to-top.html');

function backToTop($timeout) {
    return {
        restrict: 'E',
        templateUrl,
        scope: {},
        link: function(scope, element) {
            element.on('click', function() {
                $('html, body').animate({ scrollTop: 0 }, 1000);
            });

            scope.show = false;
            $(window).scroll(function() {
                var scrollTop = $(this).scrollTop();
                $timeout(function() {
                    scope.show = scrollTop > 100;
                });
            });
        }
    };
}

export default backToTop;
