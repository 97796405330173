function select2($timeout) {
    return {
        restrict: 'AC',
        require: 'ngModel',
        link: function(scope, element, attrs) {
            $timeout(function() {
                element.select2({ width: '100%' });
                element.select2Init = true;
            });

            scope.$watch(attrs.ngModel, function() {
                if (!element.select2Init) {
                    return;
                }

                $timeout(function() {
                    element.trigger('change');
                });
            });
        }
    };
}

export default select2;
