function httpConfig($httpProvider, $sceDelegateProvider) {
    $httpProvider.useApplyAsync(true);

    $sceDelegateProvider.resourceUrlWhitelist([
        'self',
        'http://www.leadflo.com/**',
        'https://www.leadflo.com/**'
    ]);
}

export default httpConfig;
