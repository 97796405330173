const WHITELIST = [
    '/v2/features',
    '/v2/patients',
    '/v3/patients',
    '/actions/info-tags'
];

const isWhitelisted = pathname => {
    for (const route of WHITELIST) {
        if (pathname.includes(route)) {
            return true;
        }
    }
    return false;
}

function debugConfig($logProvider, $compileProvider, $httpProvider) {
    $logProvider.debugEnabled(true);
    $compileProvider.debugInfoEnabled(true);

    $httpProvider.interceptors.push(['$q', '$rootScope', debugInterceptor]);
}

function debugInterceptor($q, $rootScope) {
    var interceptor = {};

    interceptor.responseError = function(response) {
        if (
            response.status === 500
            && !isWhitelisted(response.config.url.pathname)
        ) {
            $rootScope.$broadcast('serverError', {
                response: response
            });
        }

        return $q.reject(response);
    };

    return interceptor;
}

export default debugConfig;
