import { Types } from './treatments.actions';

function reducer() {
    return function (state, action) {
        if (!state) {
            return {};
        }

        switch (action.type) {
            case Types.RECEIVE_TX_TYPES:
                return {
                    ...state,
                    ...action.txTypes
                };
        }

        return state;
    };
}

export default reducer;
