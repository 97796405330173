import React, { useState } from 'react';
import { Header } from '../Header';
import { useHistory } from 'react-router-dom';
import { useAppSelector, withState } from '$state';
import { referralsEnabled } from '$state/queries/client';
import {
    subscriberHash as subscriberHashQuery,
    subscriberId as subscriberIdQuery,
} from '$state/queries/auth';
import { novuAppId } from '$state/queries/config';
import * as patients from '$state/concerns/patient';
import { useAppDispatch } from '$state/hooks';
import { z } from 'zod';
import { IMessage } from '@novu/notification-center';
import { featureEnabled } from '$state/queries/features';
import { Slideout } from '$ui/Shared/Slideout';
import { AddPatientForm } from '$ui/AddPatient/AddPatientForm';

const PatientPayload = z.object({
    patientId: z.string(),
});

export const RoutingHeader = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const addEnabled = !useAppSelector(referralsEnabled);
    const subscriberId = useAppSelector(subscriberIdQuery);
    const subscriberHash = useAppSelector(subscriberHashQuery);
    const appId = useAppSelector(novuAppId);
    const newAddPatientEnabled = useAppSelector(
        withState(featureEnabled, 'add-patient-form'),
    );

    const [addPatientFormOpen, setAddPatientFormOpen] =
        useState<boolean>(false);

    const logoClicked = () => {
        history.push('/');
    };

    const addPatientClicked = () => {
        if (newAddPatientEnabled) {
            setAddPatientFormOpen(true);
            return;
        }

        history.push('/patients/add');
    };

    const onNotificationClick = (message: IMessage): void => {
        const payload = PatientPayload.parse(message.payload);
        dispatch(
            patients.openPatient({
                patientId: payload.patientId,
            }),
        );
    };

    return (
        <>
            <Header
                onLogoClicked={logoClicked}
                onAddPatientClicked={addPatientClicked}
                addEnabled={addEnabled}
                subscriberId={subscriberId}
                subscriberHash={subscriberHash}
                appId={appId}
                onNotificationClick={onNotificationClick}
            />
            <Slideout
                open={addPatientFormOpen}
                onClose={() => setAddPatientFormOpen(false)}
                width={52.5}
            >
                {addPatientFormOpen && (
                    <AddPatientForm
                        onClose={() => setAddPatientFormOpen(false)}
                    />
                )}
            </Slideout>
        </>
    );
};
