function Modal(ModalService) {
    const svc = this;

    svc.show = ({ templateUrl, controller, inputs }) => {
        ModalService.showModal({
            templateUrl,
            controller,
            inputs,
        }).then(modal => {
            document.body.classList.add(['modal-open']);

            modal.close.then(() => {
                document.body.classList.remove(['modal-open']);
            });
        });
    };
};

export default Modal;
