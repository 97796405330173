import { ActionCategory } from '$ui/Actions/ActionCategory';
import { ConnectedToolbar } from '$ui/Actions/ConnectedToolbar';
import { Empty } from '$ui/Actions/Empty';
import { FailIcecream } from '$ui/Flo/Fail';
import { mix } from '$ui/Flo/util';
import { useAppDispatch, useAppSelector, withState } from '@/state';
import {
    actionToggled,
    fetchActions,
    INITIAL_ACTIONS,
    loadMoreActions,
    newActionRemoveAll,
} from '@/state/concerns/actions';
import {
    actionsForView,
    newActionsInView,
    updatedActionsInView,
    viewMeta,
    viewStages,
    wakingActionsInView,
} from '@/state/queries/actions';
import { ActionStatus } from '@/state/types';
import { Shadow } from '@/ui/Actions';
import * as integrations from '$state/concerns/integrations';
import * as React from 'react';
import styled from 'styled-components';
import { usePageTitle } from '@/utils/hooks';
import { lang } from '$utils';
import { Refresh } from '$ui/Actions/Refresh';
import { selectClientCountry } from '$state/concerns/client';

interface Props {
    title: string;
}

export const Actions = (props: Props) => {
    const { title } = props;

    usePageTitle(`${title} - Actions Due`);

    const view = useAppSelector(withState(viewMeta, title));
    const stages = useAppSelector(withState(viewStages, title));
    const categories = useAppSelector(
        withState(actionsForView, title),
        lang.isEqual,
    );
    const integrationStatus = useAppSelector(integrations.selectStatus);
    // Temporarily disabled until actions are in react
    const updatedStale = useAppSelector(withState(updatedActionsInView, title));
    const newStale = useAppSelector(withState(newActionsInView, title));
    const wakingStale = useAppSelector(withState(wakingActionsInView, title));
    const clientCountry = useAppSelector(withState(selectClientCountry));

    const dispatch = useAppDispatch();
    const dispatchFetchActions = (removeNew = true) => {
        dispatch(
            fetchActions({
                stages,
                forView: title,
                query: view.query,
            }),
        );
        removeNew && dispatch(newActionRemoveAll());
    };

    React.useEffect(() => {
        if (view.state === ActionStatus.INITIAL_LOAD) {
            dispatchFetchActions(false);
        }
    }, [title]);

    const loading =
        view.state == ActionStatus.INITIAL_LOAD ||
        integrationStatus === 'loading';

    if (loading) {
        const shadows = Array.from(Array(3), (_, i) => <Shadow key={i} />);
        return (
            <Frame
                title={title}
                state={view.state}
                refresh={dispatchFetchActions}
                staleNew={0}
                staleUpdated={0}
                staleWaking={0}
            >
                {shadows}
            </Frame>
        );
    }

    if (
        view.state == ActionStatus.ERROR_INITIAL_LOADING ||
        view.state == ActionStatus.ERROR_REFRESHING
    ) {
        return (
            <Frame
                title={title}
                state={view.state}
                refresh={dispatchFetchActions}
                staleNew={0}
                staleUpdated={0}
                staleWaking={0}
            >
                <FailIcecream />
            </Frame>
        );
    }

    const numberOfActions = categories
        .map((cat) => cat.actions.length)
        .reduce((acc, x) => acc + x, 0);

    if (numberOfActions === 0) {
        return (
            <Frame
                title={title}
                state={view.state}
                refresh={dispatchFetchActions}
                staleNew={0}
                staleUpdated={0}
                staleWaking={0}
            >
                <Empty title={title} />
            </Frame>
        );
    }

    const content = categories.map((cat) => (
        <ActionCategory
            key={cat.category}
            category={cat}
            includeTitle={categories.length > 1}
            pageSize={INITIAL_ACTIONS}
            loadMore={(load: number) =>
                dispatch(
                    loadMoreActions({
                        view: title,
                        category: cat.category,
                        load,
                    }),
                )
            }
            toggleAction={(patientId, state) =>
                dispatch(actionToggled({ state, patient_id: patientId }))
            }
            country={clientCountry}
        />
    ));

    return (
        <Frame
            title={title}
            state={view.state}
            refresh={dispatchFetchActions}
            staleNew={newStale}
            staleUpdated={updatedStale}
            staleWaking={wakingStale}
        >
            {content}
        </Frame>
    );
};

interface FrameProps {
    state: ActionStatus;
    title: string;
    children: React.ReactNode;
    refresh: () => void;
    staleNew: number;
    staleUpdated: number;
    staleWaking: number;
}

const Frame = ({
    title,
    children,
    state,
    refresh,
    staleNew,
    staleUpdated,
    staleWaking,
}: FrameProps) => {
    return (
        <>
            <Refresh
                status={state}
                onRefresh={refresh}
                countNew={staleNew}
                countUpdated={staleUpdated}
                countWaking={staleWaking}
                visible={true}
            />
            <ConnectedToolbar title={title} />
            <Container>
                <ActionHeading>{title}</ActionHeading>
                {children}
            </Container>
        </>
    );
};

const Container = styled.div`
    ${mix.padding({ padding: 3 })};
    display: flex;
    flex-direction: column;
    flex: 1 0;
    background: white;
    ${mix.gap({ size: 3 })};
`;

const ActionHeading = styled.h1`
    font-size: 36px;
    font-weight: 400;
    color: var(--gray-600);
    line-height: 48px;
    margin: 0;
`;
