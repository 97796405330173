import angular from 'angular';
import auth from './auth';
import global from './global';
import layout from './layout';
import treatmentTypes from './treatment-types';

export default angular.module('app.legacy', [
    auth,
    global,
    layout,
    treatmentTypes
]).name;
