import api from './api.config';
import auth from './auth.config';
import debug from './debug.config';
import http from './http.config';
import redux from './redux.config';
import route from './route.config';

export default [
    ['$logProvider', '$compileProvider', '$httpProvider', debug],
    ['$httpProvider', api],
    ['$httpProvider', auth],
    ['$httpProvider', '$sceDelegateProvider', http],
    ['$ngReduxProvider', redux],
    ['$urlRouterProvider', '$locationProvider', route]
];
