// Define the context
import { AnyEventObject, assign, createMachine } from 'xstate';
import { api } from '$api';

type DetailsContext = {
    name: string;
    email: string;
    phone: string;
};

type DuplicatePatient = {
    patient: {
        id: string;
        name: string;
        email: string;
        phone: string;
    };
    email: boolean;
    phone: boolean;
};

type UniquePatient = {
    email: false;
    phone: false;
};

export type DuplicateReport = DuplicatePatient | UniquePatient;

type PatientContext = {
    nextAction?: string;

    details?: DetailsContext;

    duplicates: DuplicateReport;
};

type AddPatientEvent =
    | { type: 'search'; details: DetailsContext }
    | { type: 'continue' }
    | { type: 'select' }
    | { type: 'back' }
    | { type: 'confirm' }
    | AnyEventObject;

// Define the state machine
export const fsm = createMachine<PatientContext, AddPatientEvent>(
    {
        predictableActionArguments: true,

        id: 'addPatient',
        initial: 'details',

        context: {
            duplicates: {
                email: false,
                phone: false,
            },
        },

        states: {
            details: {
                initial: 'initial',

                states: {
                    initial: {
                        on: {
                            search: {
                                target: 'searching',
                                actions: 'storeContactDetails',
                            },
                        },
                    },
                    searching: {
                        invoke: {
                            src: 'searchForDuplicates',
                            onDone: [
                                {
                                    target: 'duplicates',
                                    cond: 'hasDuplicates',
                                    actions: 'storeDuplicates',
                                },
                                {
                                    target: 'unique',
                                    actions: 'storeDuplicates',
                                },
                            ],
                        },
                    },
                    duplicates: {
                        on: {
                            search: {
                                target: 'searching',
                                actions: 'storeContactDetails',
                            },
                        },
                    },
                    unique: {
                        on: {
                            search: {
                                target: 'searching',
                                actions: 'storeContactDetails',
                            },
                            continue: '#addPatient.nextAction',
                        },
                    },
                },
            },
            nextAction: {
                initial: 'initial',
                states: {
                    initial: {
                        on: {
                            select: 'selected',
                        },
                    },
                    selected: {
                        on: {
                            continue: '#addPatient.confirmation',
                        },
                    },
                },
                on: {
                    back: 'details.unique',
                },
            },
            confirmation: {
                on: {
                    back: 'nextAction.selected',
                    confirm: 'end',
                },
            },
            end: {
                type: 'final',
            },
        },
    },
    {
        actions: {
            storeContactDetails: assign((context, event) => {
                if (event.type === 'search') {
                    return {
                        ...context,
                        details: {
                            ...context.details,
                            ...event.details,
                        },
                    };
                }

                return context;
            }),

            storeDuplicates: assign((context, event) => {
                if (
                    event.type ===
                    'done.invoke.addPatient.details.searching:invocation[0]'
                ) {
                    return {
                        ...context,
                        duplicates: event.data ?? {
                            email: false,
                            phone: false,
                        },
                    };
                }

                return context;
            }),
        },

        guards: {
            hasDuplicates: (_, event) => {
                // This is because the events we define above and invokable
                // events are the same type and is passed here. This is ugly
                // but this narrows things down to the default `AnyEventObject`
                // type.
                if (
                    event.type ===
                    'done.invoke.addPatient.details.searching:invocation[0]'
                ) {
                    return (
                        (event.data?.email ?? false) ||
                        (event.data?.phone ?? false)
                    );
                }

                return false;
            },
        },

        services: {
            searchForDuplicates: (context) => {
                return api
                    .get('/actions/exists', {
                        params: {
                            email: context.details?.email,
                            phone: context.details?.phone,
                        },
                    })
                    .then(({ data }) => data);
            },
        },
    },
);
