import angular from 'angular';
import FieldFocuser from './field-focuser.directive';
import FocusField from './focus-field.directive';
import FocusableField from './focusable-field.directive';

export default angular
    .module('app.v2.shared.focus-field', [])
    .directive('v2FieldFocuser', FieldFocuser)
    .directive('v2FocusField', FocusField)
    .directive('v2FocusableField', FocusableField).name;
