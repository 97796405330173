import Pusher from 'pusher-js';

interface ClientArgs {
    enabled: boolean;
    key: string;
    cluster: string;
    hostname: string;
    port: string;
    secure: boolean;
    authEndpoint: string;
    token: string;
}

export const client = ({
    enabled,
    key,
    cluster,
    hostname,
    port,
    authEndpoint,
    token,
    secure = true,
}: ClientArgs) => {
    if (!enabled) {
        return;
    }

    const numericPort = parseInt(port);

    const options = {
        cluster,
        wsHost: hostname,
        forceTLS: secure,
        authEndpoint,
        auth: {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    };

    if (secure) {
        return new Pusher(key, {
            ...options,
            wssPort: numericPort
        });
    }

    return new Pusher(key, {
        ...options,
        wsPort: numericPort
    });
};
