function Bridge($http) {
    const svc = this;

    svc.timeline = function (id) {
        return $http.get(`/v3/patients/${id}/timeline`).then(function (res) {
            return res.data;
        });
    };

    svc.refresh = (id) => {
        return $http.get(`/v2/patients/${id}/communications/fresh`);
    }
}

export default Bridge;
