import * as api from '$api';
import { RootState } from '$state/store';
import {
    Conversation,
    ConversationsStatus,
    InboxState,
} from '$state/types/inbox';
import {
    InboxChannelFilter,
    InboxStatusFilter,
    SnoozedStatus,
    SortOrder,
    StarredStatus,
} from '$types/app';
import { CommType } from '$types/patient';
import { channelMatch } from '$state/types/timeline';
import {
    createAction,
    createAsyncThunk,
    createReducer,
} from '@reduxjs/toolkit';
import { communicationOccurred } from '$state/events';
import { sendComm } from './timeline';
import { Channels, EmailPayload, SMSPayload } from '$state/types';
import { array } from '$utils';

export const fetchUnreadConversationsCount = createAsyncThunk<
    number,
    {
        view: string;
        channel: InboxChannelFilter;
        startAt: string;
        endAt: string;
        types: string[];
    }
>(
    'conversations/unread/fetchWithFilters',
    ({ view, channel, types, startAt, endAt }) =>
        api.fetchUnreadConversationsCount(
            // support for inbox v2
            view === 'All' || view === 'Unread' ? undefined : channel,
            types,
            startAt,
            endAt,
        ),
);

export const fetchStarredCount = createAsyncThunk<
    number,
    {
        view: string;
        channel: InboxChannelFilter;
        startAt: string;
        endAt: string;
        types: string[];
    }
>(
    'conversations/starred/fetchWithFilters',
    ({ view, channel, types, startAt, endAt }) =>
        api.fetchStarredCount(
            view === 'All' || view === 'Unread' ? undefined : channel,
            types,
            startAt,
            endAt,
        ),
);

export const fetchSnoozedCount = createAsyncThunk<
    number,
    {
        view: string;
        channel: InboxChannelFilter;
        startAt: string;
        endAt: string;
        types: string[];
    }
>(
    'conversations/snoozed/fetchWithFilters',
    ({ view, channel, types, startAt, endAt }) =>
        api.fetchSnoozedCount(
            view === 'All' || view === 'Unread' ? undefined : channel,
            types,
            startAt,
            endAt,
        ),
);

export const fetchUnreadTrueCount = createAsyncThunk<
    number,
    {
        view: string;
        channel: InboxChannelFilter;
    }
>('conversations/unread/fetch', ({ view, channel }) =>
    api.fetchUnreadConversationsCount(
        // support for inbox v2
        view === 'All' || view === 'Unread' ? undefined : channel,
    ),
);

export interface FetchConversationsArgs {
    view: string;
    unread: InboxStatusFilter;
    channel: InboxChannelFilter;
    token: string | null;
    types: string[];
    startAt: string;
    endAt: string;
    sortDirection: SortOrder;
    starred: StarredStatus;
    snoozed: SnoozedStatus;
}

export const fetchConversations = createAsyncThunk<
    api.LastConversationPayload[],
    FetchConversationsArgs
>(
    'conversations/fetch',
    async (
        {
            view,
            unread,
            channel,
            token = null,
            types,
            startAt,
            endAt,
            sortDirection,
            starred,
            snoozed = 'hideSnoozed',
        }: FetchConversationsArgs,
        ThunkAPI,
    ) => {
        const query = {};

        if (unread !== 'all') {
            query['unread'] = 'true';
        }
        if (starred !== 'all') {
            query['starred'] = 'true';
        }
        if (snoozed !== 'hideSnoozed') {
            query['includeSnoozed'] = 'true';
        }

        if (view !== 'All' && view !== 'Unread') {
            query['channel'] = channel;
        }

        if (types.length > 0) {
            query['types'] = types;
        }

        if (startAt) {
            query['startAt'] = startAt;
        }

        if (endAt) {
            query['endAt'] = endAt;
        }

        if (sortDirection) {
            query['sortDirection'] = sortDirection;
        }

        ThunkAPI.dispatch(
            fetchUnreadConversationsCount({
                view: channel,
                channel,
                startAt,
                endAt,
                types,
            }),
        );

        return api.fetchConversations({
            view,
            ...query,
            token,
        });
    },
);

export const hydrateConversations = createAsyncThunk<void>(
    'conversations/hydrate',
    async (_, ThunkAPI) => {
        const { inbox } = ThunkAPI.getState() as RootState;
        const views = Object.entries(inbox.views);
        for (const [name, view] of views) {
            ThunkAPI.dispatch(
                fetchUnreadTrueCount({
                    view: name,
                    channel: view.parameters.channel,
                }),
            );
        }
    },
);

export const markConversationRead = createAsyncThunk<void, string>(
    'conversations/markAsRead',
    async (patientId: string) => {
        await api.markConversationRead(patientId);
    },
);

export const markConversationUnread = createAsyncThunk<void, string>(
    'conversations/markAsUnread',
    async (patientId: string) => {
        await api.markConversationUnread(patientId);
    },
);

export const markConversationStarred = createAsyncThunk<void, string>(
    'conversations/markAsStarred',
    async (patientId: string) => {
        await api.markConversationStarred(patientId);
    },
);

export const markConversationUnstarred = createAsyncThunk<void, string>(
    'conversations/markAsUnstarred',
    async (patientId: string) => {
        await api.markConversationUnstarred(patientId);
    },
);

interface BulkMarkAs {
    patientIds: string[];
}

export const bulkMarkConversationRead = createAsyncThunk<void, BulkMarkAs>(
    'conversations/bulkMarkAsRead',
    async ({ patientIds }) => {
        await api.bulkMarkConversationRead(patientIds);
    },
);

export const bulkMarkConversationUnread = createAsyncThunk<void, BulkMarkAs>(
    'conversations/bulkMarkAsUnread',
    async ({ patientIds }) => {
        await api.bulkMarkConversationUnread(patientIds);
    },
);

export const bulkMarkConversationStarred = createAsyncThunk<void, BulkMarkAs>(
    'conversations/bulkMarkAsStarred',
    async ({ patientIds }) => {
        await api.bulkMarkConversationStarred(patientIds);
    },
);

export const bulkMarkConversationUnstarred = createAsyncThunk<void, BulkMarkAs>(
    'conversations/bulkMarkAsUnstarred',
    async ({ patientIds }) => {
        await api.bulkMarkConversationUnstarred(patientIds);
    },
);

interface FetchEmailArgs {
    patientId: string;
    commName: string;
    date: string;
    fromEmail: string;
    subject: string;
}

interface FetchEmailPayload {
    full_content: string;
}

export const fetchEmail = createAsyncThunk<FetchEmailPayload, FetchEmailArgs>(
    'conversations/fetchMessage',
    ({ patientId, commName }: FetchEmailArgs) => {
        return api.fetchEmail(patientId, commName);
    },
);

/**
 * convoRead is an event that's fired when a conversation has been marked
 * as read, either by the user, or from a websocket event.
 *
 * If notify is false, the read event will not be sent to the API.
 */
export const conversationRead = createAsyncThunk<
    void,
    {
        patientId: string;
        notify: boolean;
    }
>('conversations/read', (args, thunk) => {
    if (args.notify) {
        thunk.dispatch(markConversationRead(args.patientId));
    }
});

/**
 * pusher event for convoUnread
 */

export const conversationUnread = createAsyncThunk<
    void,
    {
        patientId: string;
        notify: boolean;
    }
>('conversations/unread', (args, thunk) => {
    if (args.notify) {
        thunk.dispatch(markConversationUnread(args.patientId));
    }
});

/**
 * pusher event for convoStarred
 */
export const patientStarred = createAsyncThunk<
    void,
    {
        patientId: string;
        notify: boolean;
    }
>('conversations/starred', (args, thunk) => {
    if (args.notify) {
        thunk.dispatch(markConversationStarred(args.patientId));
    }
});

/**
 * pusher event for convoUnstarred
 */
export const patientUnstarred = createAsyncThunk<
    void,
    {
        patientId: string;
        notify: boolean;
    }
>('conversations/unstarred', (args, thunk) => {
    if (args.notify) {
        thunk.dispatch(markConversationUnstarred(args.patientId));
    }
});

export const resetConversation = createAction(
    'conversations/reset',
    (view: string) => ({
        payload: view,
    }),
);

export const resetConversations = createAction('conversations/resetAll');

export const initialState: InboxState = {
    conversations: {},
    emails: {},
    views: {
        All: {
            state: ConversationsStatus.IDLE,
            channel: 'all',
            path: '/inbox',
            conversations: {},
            parameters: {
                unread: 'all',
                channel: 'All',
                types: [],
                startAt: '',
                endAt: '',
                sortDirection: 'desc',
                starred: 'all',
                snoozed: 'hideSnoozed',
            },
            paging: {
                next: null,
                prev: null,
            },
        },

        Email: {
            state: ConversationsStatus.IDLE,
            channel: Channels.Email,
            path: '/inbox/all/email',
            conversations: {},
            parameters: {
                channel: CommType.Email,
                unread: 'all',
                types: [],
                startAt: '',
                endAt: '',
                sortDirection: 'desc',
                starred: 'all',
                snoozed: 'hideSnoozed',
            },
            paging: {
                next: null,
                prev: null,
            },
        },

        SMS: {
            state: ConversationsStatus.IDLE,
            channel: Channels.SMS,
            path: '/inbox/all/sms',
            conversations: {},
            parameters: {
                channel: CommType.SMS,
                unread: 'all',
                types: [],
                startAt: '',
                endAt: '',
                sortDirection: 'desc',
                starred: 'all',
                snoozed: 'hideSnoozed',
            },
            paging: {
                next: null,
                prev: null,
            },
        },

        'Form Submissions': {
            state: ConversationsStatus.IDLE,
            channel: Channels.FormSubmissions,
            path: '/inbox/all/forms',
            conversations: {},
            parameters: {
                channel: 'Form Submissions',
                unread: 'all',
                types: [],
                startAt: '',
                endAt: '',
                sortDirection: 'desc',
                starred: 'all',
                snoozed: 'hideSnoozed',
            },
            paging: {
                next: null,
                prev: null,
            },
        },

        WhatsApp: {
            state: ConversationsStatus.IDLE,
            channel: Channels.WhatsApp,
            path: '/inbox/all/whatsapp',
            conversations: {},
            parameters: {
                channel: CommType.WhatsApp,
                unread: 'all',
                types: [],
                startAt: '',
                endAt: '',
                sortDirection: 'desc',
                starred: 'all',
                snoozed: 'hideSnoozed',
            },
            paging: {
                next: null,
                prev: null,
            },
        },
    },
};

export default createReducer(initialState, (builder) => {
    builder.addCase(resetConversation, (state, action) => {
        const view = state.views[action.payload];

        if (!view) return;

        view.conversations = {};
        view.paging = {
            next: null,
            prev: null,
        };
        view.state = ConversationsStatus.IDLE;
    });

    builder.addCase(resetConversations, (state) => {
        Object.values(state.views).forEach((view) => {
            view.conversations = {};
            view.paging = {
                next: null,
                prev: null,
            };
            view.state = ConversationsStatus.IDLE;
        });
    });

    builder.addCase(fetchConversations.pending, (state, action) => {
        const { view: name } = action.meta.arg;
        const view = state.views[name];

        if (view.state === ConversationsStatus.LOADED) {
            view.state = ConversationsStatus.LOADING_MORE;
            return;
        }

        view.state = ConversationsStatus.LOADING;
    });

    builder.addCase(fetchConversations.fulfilled, (state, action) => {
        const {
            view: name,
            types,
            startAt,
            endAt,
            sortDirection,
            unread,
            starred,
            snoozed,
        } = action.meta.arg;
        const items = action.payload;
        const view = state.views[name];

        view.state = ConversationsStatus.LOADED;
        const cursor = items[items.length - 1]?.cursor;
        view.paging.next = cursor ? btoa(JSON.stringify(cursor)) : null;

        const conversations = array.keyBy<Conversation, string>(
            items,
            (c) => c.patient_id,
        );

        state.conversations = {
            ...state.conversations,
            ...conversations,
        };
        view.conversations = {
            ...view.conversations,
            ...conversations,
        };

        view.parameters = {
            ...view.parameters,
            unread,
            types,
            startAt,
            endAt,
            sortDirection,
            starred,
            snoozed,
        };
    });

    builder.addCase(fetchConversations.rejected, (state, action) => {
        const { view: name } = action.meta.arg;
        const view = state.views[name];

        if (view.state === ConversationsStatus.LOADING_MORE) {
            view.state = ConversationsStatus.LOADING_MORE_FAILED;
            return;
        }

        view.state = ConversationsStatus.ERRORED;
    });

    builder.addCase(fetchUnreadTrueCount.fulfilled, (state, action) => {
        const { view } = action.meta.arg;
        state.views[view].count = action.payload;
    });

    builder.addCase(
        fetchUnreadConversationsCount.fulfilled,
        (state, action) => {
            const { view } = action.meta.arg;
            state.views[view].countWithFilters = action.payload;
        },
    );

    builder.addCase(fetchStarredCount.fulfilled, (state, action) => {
        const { view } = action.meta.arg;
        state.views[view].countStarred = action.payload;
    });

    builder.addCase(fetchSnoozedCount.fulfilled, (state, action) => {
        const { view } = action.meta.arg;
        state.views[view].countSnoozed = action.payload;
    });

    builder.addCase(conversationRead.pending, (state, action) => {
        const { patientId } = action.meta.arg;

        updateUnreadState({
            state,
            patientId,
            unread: false,
        });
    });

    // Refactor all these away to a single action that supports starring
    // multiple conversations at once
    builder.addCase(patientStarred.pending, (state, action) => {
        const { patientId } = action.meta.arg;

        updateStarState({
            state,
            patientId,
            starred: true,
        });
    });

    builder.addCase(markConversationStarred.pending, (state, action) => {
        const patientId = action.meta.arg;

        updateStarState({
            state,
            patientId,
            starred: true,
        });
    });

    builder.addCase(bulkMarkConversationStarred.pending, (state, action) => {
        const { patientIds } = action.meta.arg;

        patientIds.forEach((patientId) => {
            updateStarState({
                state,
                patientId,
                starred: true,
            });
        });
    });

    builder.addCase(bulkMarkConversationUnstarred.pending, (state, action) => {
        const { patientIds } = action.meta.arg;

        patientIds.forEach((patientId) => {
            updateStarState({
                state,
                patientId,
                starred: false,
            });
        });
    });

    builder.addCase(patientUnstarred.pending, (state, action) => {
        const { patientId } = action.meta.arg;

        updateStarState({
            state,
            patientId,
            starred: false,
        });
    });

    builder.addCase(markConversationUnstarred.pending, (state, action) => {
        const patientId = action.meta.arg;

        updateStarState({
            state,
            patientId,
            starred: false,
        });
    });

    builder.addCase(conversationUnread.pending, (state, action) => {
        const { patientId } = action.meta.arg;

        updateUnreadState({
            state,
            patientId,
            unread: true,
        });
    });

    builder.addCase(fetchEmail.pending, (state, action) => {
        const { commName, date, fromEmail, subject } = action.meta.arg;
        state.emails[commName] = {
            state: 'loading',
            email: fromEmail,
            date,
            subject,
        };
    });

    builder.addCase(fetchEmail.fulfilled, (state, action) => {
        const { commName } = action.meta.arg;
        const message = state.emails[commName];
        message.state = 'loaded';
        message.body = action.payload.full_content;
    });

    builder.addCase(fetchEmail.rejected, (state, action) => {
        const { commName } = action.meta.arg;
        const message = state.emails[commName];
        message.state = 'error';
    });

    builder.addCase(communicationOccurred.pending, (state, action) => {
        const { patientId, inbound, communication: comm } = action.meta.arg;

        const view = state.views[comm.comm_type];
        if (!view) {
            return;
        }
        const convo = view.conversations[patientId];
        if (!convo) {
            return;
        }

        // Don't move it to the top of the list if the convo is inbound
        // so that we maintain the user's position in the list
        const date = comm.inbound ? comm.datetime : convo.last_message_at;

        // We already have a conversation, so let's optimistically
        // update it while we wait for the API to return
        view.conversations[patientId] = {
            last_message_at: date,
            snippet: comm.text_content,
            unread: false, // Needs to default to read for optimistic render,
            // as it's possible it may
            // have been read so we don't want to cause a flash of unread
            // message. Regardless, the API response will give us the
            // definitive answer
            patient_id: patientId,
            first_name: convo.first_name,
            last_name: convo.last_name,
            channel: channelMatch(comm.comm_type),
            last_message_inbound: inbound,
            unread_count: convo.unread_count,
            starred: convo.starred,
            snoozed: convo.snoozed,
            status: convo.status ?? 'received',
        };
    });

    builder.addCase(communicationOccurred.fulfilled, (state, action) => {
        const { patientId } = action.meta.arg;
        const convo = action.payload;

        // Don't move it to the top of the list if the convo is inbound
        // so that we maintain the user's position in the list
        const date = convo.inbound
            ? convo.datetime
            : state.conversations[patientId]?.last_message_at;

        state.conversations[patientId] = {
            patient_id: convo.patient_id,
            first_name: convo.first_name,
            last_name: convo.last_name,
            last_message_at: date ?? convo.datetime,
            last_message_inbound: convo.inbound,
            snippet: convo.snippet,
            unread: Boolean(convo.read_at),
            channel: convo.channel,
            unread_count: convo.unread_count,
            starred: Boolean(convo.starred),
            snoozed: Boolean(convo.snoozed),
            status: convo.status,
        };
    });

    builder.addCase(sendComm.pending, (state, action) => {
        const { patientId, payload, channel } = action.meta.arg;

        let snippet = '';
        switch (channel) {
            case CommType.Email:
                snippet = (payload as EmailPayload).body;
                break;
            case CommType.SMS:
                snippet = (payload as SMSPayload).message;
                break;
        }

        const { conversations } = state.views[channel];
        conversations[patientId] = {
            ...conversations[patientId],
            snippet,
            unread: false,
        };
    });

    // Refactor these to a single action that supports marking multiple patients
    // as read/unread

    builder.addCase(bulkMarkConversationRead.pending, (state, action) => {
        const { patientIds } = action.meta.arg;

        patientIds.forEach((patientId: string) => {
            updateUnreadState({
                state,
                patientId,
                unread: false,
            });
        });
    });

    builder.addCase(bulkMarkConversationUnread.pending, (state, action) => {
        const { patientIds } = action.meta.arg;

        patientIds.forEach((patientId: string) => {
            updateUnreadState({
                state,
                patientId,
                unread: true,
            });
        });
    });

    builder.addCase(markConversationRead.pending, (state, action) => {
        const patientId = action.meta.arg;

        updateUnreadState({
            state,
            patientId,
            unread: false,
        });
    });

    builder.addCase(markConversationUnread.pending, (state, action) => {
        const patientId = action.meta.arg;

        updateUnreadState({
            state,
            patientId,
            unread: true,
        });
    });
});

interface UpdateStarStateArgs {
    state: InboxState;
    patientId: string;
    starred: boolean;
}

const updateStarState = ({
    state,
    patientId,
    starred,
}: UpdateStarStateArgs) => {
    const convo = state.conversations[patientId];

    if (convo) {
        convo.starred = starred;
    }

    // mark as starred/unstarred in all views
    Object.values(state.views).forEach((view) => {
        const convo = view.conversations[patientId];

        if (!convo) {
            return;
        }

        convo.starred = starred;

        if (view.countStarred === undefined) {
            return;
        }

        if (!starred && view.countStarred === 0) {
            return;
        }

        view.countStarred += starred ? 1 : -1;
    });
};

interface UpdateUnreadStateArgs {
    state: InboxState;
    patientId: string;
    unread: boolean;
}

const updateUnreadState = ({
    state,
    patientId,
    unread,
}: UpdateUnreadStateArgs) => {
    const convo = state.conversations[patientId];

    if (convo) {
        if (unread && !convo.unread) {
            convo.unread = true;
            convo.unread_count = 1;
        }

        if (!unread && convo.unread) {
            convo.unread = false;
            convo.unread_count = 0;
        }
    }

    // mark as read/unread in all views
    Object.values(state.views).forEach((view) => {
        const convo = view.conversations[patientId];

        if (!convo) {
            return;
        }

        if (unread && convo.unread) {
            return;
        }

        if (!unread && !convo.unread) {
            return;
        }

        convo.unread = unread;
        convo.unread_count = unread ? 1 : 0;

        if (!view.count) {
            return;
        }

        view.count += unread ? 1 : -1;

        if (!view.countWithFilters) {
            return;
        }

        view.countWithFilters += unread ? 1 : -1;
    });
};
