import template from './next-button.html';

function NextButtonController() {
    var ctrl = this;

    ctrl.$onInit = function () {
        ctrl.next = ctrl.onClick;
    };

    ctrl.disabled = function () {
        return Boolean(ctrl.lfDisabled);
    };
}

export default {
    template,
    controller: NextButtonController,
    transclude: true,
    bindings: {
        onClick: '&',
        lfDisabled: '<'
    }
};
