let _promise = null;

const get = () => {
    if (_promise) {
        return _promise;
    }

    _promise = fetch('/config.json').then((res) => res.json());

    return _promise;
};

export default { get };
