import * as api from '$api/templates';
import { LoadTemplatePayload } from '$api/templates';
import {
    SelectedStatus,
    Template,
    TemplatesState,
} from '$state/types/templates';
import {
    createAction,
    createAsyncThunk,
    createReducer,
} from '@reduxjs/toolkit';
import { RootState } from '$state';
import { array } from '$utils';

export const loadTemplates = createAsyncThunk<Array<LoadTemplatePayload>>(
    'templates/load',
    () => api.loadTemplates(),
);

export const saveTemplate = createAsyncThunk<void, Template>(
    'templates/save',
    async (template) => {
        await api.saveTemplate(template);
    },
);

export const deleteTemplate = createAsyncThunk<void, Template>(
    'templates/delete',
    async (template) => {
        await api.deleteTemplate(template);
    },
);

export const complete = createAction('templates/save/complete');

const initialState: TemplatesState = {
    status: 'init',
    channels: {},
    templates: {},
    selected: 'init',
};

export default createReducer(initialState, (builder) => {
    builder.addCase(loadTemplates.pending, (state) => {
        state.status = 'loading';
    });

    builder.addCase(loadTemplates.fulfilled, (state, action) => {
        state.status = 'loaded';

        state.channels = Object.fromEntries(
            action.payload.map((channel) => [
                channel.name,
                {
                    name: channel.name,
                    templates: channel.templates.map(({ id }) => id),
                    approval: channel.approval,
                },
            ]),
        );

        state.templates = Object.fromEntries(
            action.payload.flatMap((channel) =>
                channel.templates.map(
                    (template) => [template.id, template as Template] as const,
                ),
            ),
        );
    });

    builder.addCase(loadTemplates.rejected, (state) => {
        state.status = 'errored';
    });

    builder.addCase(saveTemplate.pending, (state, action) => {
        const template = action.meta.arg;
        template.status = template.approval ? 'PENDING' : 'NOT_SUBMITTED';
        state.templates[template.id] = template;

        const channel = state.channels[template.channel];
        channel.templates = array.unique([...channel.templates, template.id]);

        state.selected = 'saving';
    });

    builder.addCase(saveTemplate.fulfilled, (state) => {
        state.selected = 'saved';
    });

    builder.addCase(saveTemplate.rejected, (state, action) => {
        const template = action.meta.arg;
        delete state.templates[template.id];

        const channel = state.channels[template.channel];
        channel.templates = channel.templates.filter(
            (id) => id !== template.id,
        );

        state.selected = 'saving.failed';
    });

    // pending case for deleteTemplate with the state being deleting
    builder.addCase(deleteTemplate.pending, (state) => {
        state.selected = 'deleting';
    });

    // fulfilled case for deleteTemplate and remove from channel
    builder.addCase(deleteTemplate.fulfilled, (state, action) => {
        const template = action.meta.arg;
        delete state.templates[template.id];

        const channel = state.channels[template.channel];
        channel.templates = channel.templates.filter(
            (id) => id !== template.id,
        );

        state.selected = 'deleted';
    });

    builder.addCase(deleteTemplate.rejected, (state, action) => {
        const template = action.meta.arg;
        state.templates[template.id] = template;

        const channel = state.channels[template.channel];
        channel.templates = array.unique([...channel.templates, template.id]);

        state.selected = 'deleting.failed';
    });

    builder.addCase(complete, (state) => {
        state.selected = 'init';
    });
});

export const selectTemplates = (rootState: RootState) => rootState.templates;

export const selectStatus = (rootState: RootState) => {
    const { status } = selectTemplates(rootState);
    return status;
};

export const selectTemplate =
    (templateId: string | undefined) =>
    (rootState: RootState): Template | undefined => {
        const { templates } = selectTemplates(rootState);
        return templates[templateId ?? ''];
    };

export const selectChannelTemplates =
    (channel: string) => (rootState: RootState) => {
        const { channels, templates } = selectTemplates(rootState);
        return channels[channel]?.templates.map((id) => templates[id]);
    };

export const selectSelectedState = (rootState: RootState): SelectedStatus =>
    selectTemplates(rootState).selected;
