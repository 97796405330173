import { lang, obj } from '$utils';

function NextActions($log) {
    var svc = this;

    var common = {
        enquiry: 'Patient made an enquiry',
        rearrangedAppt: 'Patient has rearranged their appointment',
        rearrangedConsult: 'Patient has rearranged their consultation',
        thinkingConsult: 'Patient is thinking about a consultation',
        thinkingTx: 'Patient is thinking about treatment',
        booking: 'Patient booked in for a consultation',
        working: 'Patient wants to discuss it later',
        callback: 'Patient did not answer the phone',
        maybeFuture: "Patient isn't interested in treatment right now",
        lost: 'Patient will never be interested in treatment (nuke)',
        txPlanBooking: 'Patient has booked a treatment plan consultation',
        wrongNumber: 'Patient did not provide a correct number',
        startTx: 'Patient decided to go ahead with treatment',
        inTx: 'Patient decided to go ahead with treatment',
        notStartTx: "Patient hasn't started treatment yet",
        txBooking: 'Patient needs another appointment',
        txComplete: 'Patient has completed treatment',
        didNotAttend: 'Patient did not attend',
        cancelledForever:
            'Patient has cancelled and will never reschedule (nuke)',
        postponed: 'Patient has cancelled and will contact us to reschedule',
        rescheduled: 'Patient has rescheduled their appointment'
    };

    common.thinking = common.working;

    svc.choose = (keys) => obj.pick(common, keys);

    svc.all = (exclude = null) =>
        svc.choose(
            [
                'enquiry',
                'working',
                'callback',
                'wrongNumber',
                'booking',
                'didNotAttend',
                'rearrangedConsult',
                'thinkingTx',
                'rearrangedAppt',
                'txPlanBooking',
                'won',
                'startTx',
                'txComplete',
                'maybeFuture',
                'lost',
                'cancelForever',
                'postpone',
                'reschedule'
            ].filter((nextAction) => nextAction !== exclude)
        );

    svc.nextActionName = (nextAction) => {
        return common[nextAction];
    };

    var stageMap = {
        newPatient: svc.choose([
            'enquiry',
            'thinkingConsult',
            'booking',
            'thinkingTx'
        ]),

        newLead: svc.choose([
            'booking',
            'working',
            'callback',
            'wrongNumber',
            'maybeFuture',
            'lost'
        ]),

        working: svc.choose([
            'booking',
            'working',
            'callback',
            'wrongNumber',
            'maybeFuture',
            'lost'
        ]),

        inTx: {
            booking: common.txBooking,
            rearrangedAppt: common.rearrangedAppt,
            complete: common.txComplete,
            maybeFuture: common.maybeFuture,
            didNotAttend: common.didNotAttend
        },

        consultation: {
            txPlanBooking: common.txPlanBooking,
            rearrangedConsult: common.rearrangedConsult,
            thinking: common.thinking,
            didNotAttend: common.didNotAttend,
            won: common.won,
            complete: common.txComplete,
            maybeFuture: common.maybeFuture,
            lost: common.lost
        },

        thinking: {
            txPlanBooking: common.txPlanBooking,
            thinking: common.thinking,
            callback: common.callback,
            won: common.won,
            complete: common.txComplete,
            maybeFuture: common.maybeFuture,
            lost: common.lost
        },

        txPlanConsult: {
            won: common.won,
            rearrangedConsult: common.rearrangedConsult,
            thinking: common.thinking,
            didNotAttend: common.didNotAttend,
            complete: common.txComplete,
            maybeFuture: common.maybeFuture
        },

        won: {
            booking: common.startTx,
            won: common.notStartTx,
            complete: common.txComplete,
            didNotAttend: common.didNotAttend,
            maybeFuture: common.maybeFuture
        }
    };

    /**
     * Gets the right language given the current
     * stage and the chosen next action. For example
     * if a patient is a new lead and books in for a
     * consultation, the language to use is
     * 'consultation'. When, however, the patient is
     * in treatment, the language should be
     * 'next appointment'.
     */
    svc.getApptType = function (stage, nextAction) {
        var stageMap = {
            inTx: 'next treatment appointment'
        };

        stageMap.won = stageMap.inTx;

        var actionMap = {
            won: 'first treatment appointment',
            txPlanBooking: 'treatment plan consultation',
            booking: 'initial consultation',
            rearrangedConsult: 'rearranged consultation',
            rearrangedAppt: 'rearranged appointment'
        };

        if (stageMap[stage]) {
            return stageMap[stage];
        }

        return actionMap[nextAction] ?? 'appointment';
    };

    svc.forStage = function (action) {
        return stageMap[svc.getStage(action)];
    };

    svc.getStage = function (action) {
        var stage = action.stage;

        if (/^callback\d$/.test(stage)) {
            return 'newLead';
        }

        if (lang.isEmpty(stageMap[stage])) {
            $log.error('Could not find stage: ' + stage);
            return 'newLead';
        }

        return stage;
    };

    svc.forReschedule = () => {
        return svc.choose(['rescheduled', 'postponed', 'cancelledForever']);
    };
}

export default NextActions;
