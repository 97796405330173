import {
    selectQuery,
    selectReportOptions,
    updateQuery
} from '$state/concerns/patient-filter';
import { useAppDispatch, useAppSelector } from '$state/hooks';
import { ConnectedFilter } from '$ui/PatientFilter/ConnectedFilter';
import { Toolbar } from '$ui/PatientFilter/Toolbar';
import React from 'react';
import { Selector } from './Selector';

interface Props {
    run: () => void;
}

export const ConnectedFilterbar = ({ run }: Props) => {
    const dispatch = useAppDispatch();
    const reports = useAppSelector(selectReportOptions);
    const query = useAppSelector(selectQuery);
    const changeReport = (report: string) => {
        dispatch(
            updateQuery({
                report
            })
        );
    };

    return (
        <Toolbar>
            <ConnectedFilter actionLabel="Search" run={run}>
                <Selector
                    options={reports}
                    onSelect={changeReport}
                    selected={query.report}
                    placeholder={
                        query.report
                            ? reports.find((r) => r.value === query.report)
                                  ?.label
                            : 'Select a report'
                    }
                />
            </ConnectedFilter>
        </Toolbar>
    );
};
