import template from './success.html';

function SuccessController(NextActions, $timeout) {
    var ctrl = this;

    ctrl.$onInit = function () {
        if (ctrl.nextAction === 'rearrangedConsult') {
            ctrl.apptText = 'consultation';
            return;
        }

        if (ctrl.nextAction === 'rearrangedAppt') {
            ctrl.apptText = 'appointment';
            return;
        }

        ctrl.apptText = NextActions.getApptType(
            ctrl.action.stage,
            ctrl.nextAction
        );

        ctrl.retrying = false;
    };

    ctrl.firstName = function () {
        return ctrl.action.firstName ? ctrl.action.firstName : 'This patient';
    };

    ctrl.retry = () => {
        ctrl.retrying = true;
        ctrl.onRetry();

        // Rather than expecting every user of v2-success to
        // implement retry lifecycles, we can fake the loading
        // with a timeout so that to users, it's clear that it's doing
        // something, and they don't spam retry.
        $timeout(() => (ctrl.retrying = false), 2000);
    };
}

export default {
    template,
    controller: ['NextActions', '$timeout', SuccessController],
    bindings: {
        nextAction: '<',
        action: '<',
        onClose: '&',
        onBack: '&',
        isSticky: '<',
        failed: '<',
        onRetry: '&'
    }
};
