import template from './section-body.html';

class SectionBodyController {}

export default {
    template,
    controller: SectionBodyController,
    transclude: true,
    require: {
        sectionCtrl: '^v2Section'
    }
};
