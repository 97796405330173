import angular from 'angular';
import TxActions from './treatments.actions';
import txTypesReducer from './tx-types.reducer';
import featuresActions from './features.actions';
import featuresReducer from './features.reducer';

export default angular
    .module('app.v2.dashboard.reducers', [])
    .factory('TxActions', ['$http', 'TreatmentTypesService', TxActions])
    .factory('txTypesReducer', txTypesReducer)
    .factory('featuresReducer', featuresReducer)
    .factory('featuresActions', ['$http', featuresActions]).name;
