import angular from 'angular';
import booking from './booking';
import followUp from './follow-up';
import nextActions from './next-actions';

export default angular.module('app.v2.dashboard.steps', [
    booking,
    followUp,
    nextActions
]).name;
