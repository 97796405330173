import { Hue, Shade } from '$ui/Flo/types';
import { IconName } from '$ui/Flo/Icon';

export interface PhoneNumber {
    number: string;
    invalid_reason: string | null;
    invalidated_at: string | null;
}

export enum EventType {
    Comm = 'communication',
    Note = 'note',
    FormSubmission = 'form_submission',
    BusinessEvent = 'business_event',
    Attachment = 'attachment',
}

export enum CommType {
    Email = 'Email',
    MarketingEmail = 'Marketing Email',
    SMS = 'SMS',
    PhoneCall = 'Phone Call',
    WhatsApp = 'WhatsApp',
}

export interface Event {
    id: string;
    type: EventType;
    datetime: string;
    tooltip?: string | null;
    hue?: Hue;
    shade?: Shade;
}

export interface Comm extends Event {
    type: EventType.Comm;
    comm_type: CommType;
    inbound: boolean;
    text_content: string;
    read_at: string | null;
    identity?: string;
    status: string;
    retry: {
        active: boolean;
        count: number;
    };
    attachments?: Attachment[] | null;
}

export interface Email extends Comm {
    comm_type: CommType.Email | CommType.MarketingEmail;
    email_data: {
        from_email: string;
        to_email: string;
        subject: string;
    };
}

export interface SMS extends Comm {
    comm_type: CommType.SMS;
    sms_data: {
        from_number: PhoneNumber;
        to_number: PhoneNumber;
    };
}

export interface PhoneCall extends Comm {
    comm_type: CommType.PhoneCall;
    phone_call_data: {
        from_number: PhoneNumber;
        to_number: PhoneNumber;
    };
}

export interface Note extends Event {
    type: EventType.Note;
    title?: string | null;
    content: string;
    failed?: boolean;
}

export interface FormSubmission extends Event {
    type: EventType.FormSubmission;
    message: string;
    form: string;
    read_at: string | null;
}

export interface BusinessEvent {
    type: EventType.BusinessEvent;
    id: string;
    headline: string;
    description: string;
    datetime: string;
    note?: string;
    business_event_type: BusinessEventType;
}

export interface BusinessEventType {
    name: string;
    color: Hue;
    icon: IconName;
    tooltip?: string;
}

export interface WhatsApp extends Comm {
    comm_type: CommType.WhatsApp;
    message_data: {
        from_number: PhoneNumber;
        to_number: PhoneNumber;
    };
}

export interface AttachmentEvent {
    id: string;
    datetime: string;
    type: EventType.Attachment;
    inbound: boolean;
    comm_type: CommType;
    attachments: Attachment[];
}

export type CommEventUnion = Email | SMS | PhoneCall | WhatsApp;

export type CommEventUnionInbound = CommEventUnion & {
    status: Status | string;
};
export type Status = { name: string; hue: Hue };

export type EventUnion =
    | Note
    | CommEventUnion
    | FormSubmission
    | BusinessEvent
    | WhatsApp
    | AttachmentEvent;

export type Events = EventUnion[];

export interface openEmailArguments {
    fromEmail: string;
    toEmail: string;
    date: string;
    subject: string;
    status: string;
    commName: string;
}

export interface openEmailArgumentsV2 {
    fromEmail: string;
    toEmail: string;
    date: string;
    subject: string;
    status: string;
    commName: string;
}

export interface ActionCounts {
    [stage: string]: number;
}

/**
 * Attachment
 */
export interface Attachment {
    name: string;
    type: string;
    url: string;
}
