const template = `
<div class="container container-thin baseline-bottom-3">
  <div class="section is-open baseline-top">
    <div class="section-header">
      <i class="zmdi zmdi-hospital section-header-icon"></i>
      Tx Types
    </div>
    <div class="section-body">
      <div class="field-group-container">
        <div class="field-group" ng-repeat="txType in $ctrl.txTypes">
          <v2-field
            placeholder="e.g Ortho"
            value="txType.name"
            on-changed="$ctrl.onChange(txType, value)"></v2-field>
          <div class="field-group-action" ng-click="$ctrl.remove(txType)">
            <i class="zmdi zmdi-delete field-group-action-icon"></i>
          </div>
        </div>
      </div>

      <div class="message-warning" ng-if="$ctrl.txTypes.length === 0">
        No tx types defined. <a ng-click="$ctrl.add()">Add a new one.</a>
      </div>

      <div class="buttons baseline-top zero-margin-bottom">
        <v2-next-button ng-click="$ctrl.save()">
          Save
        </v2-next-button>
        <a class="button secondary-button" ng-click="$ctrl.add()">
          <i class="zmdi zmdi-plus-circle-o button-icon"></i> Add New Tx Type
        </a>
      </div>
    </div>
  </div>
</div>
`;

function controller($rootScope, TreatmentTypesService, Permissions) {
    var ctrl = this;

    ctrl.dirty = false;

    Permissions.guard('change_tx_types');

    TreatmentTypesService.list().then(function (txTypes) {
        ctrl.txTypes = txTypes;
    });

    ctrl.add = function () {
        ctrl.dirty = true;
        ctrl.txTypes.push({ id: null, name: '' });
    };

    ctrl.remove = function (txType) {
        ctrl.dirty = true;
        pull(ctrl.txTypes, txType);
    };

    ctrl.save = function () {
        ctrl.dirty = false;
        TreatmentTypesService.save(ctrl.txTypes).then(function () {
            $rootScope.$broadcast('message:show', {
                type: 'success',
                content: 'Success! We have saved the tx types.'
            });
        });
    };

    ctrl.onChange = function (txType, name) {
        txType.name = name;
    };
}

export const txTypes = {
    template,
    controller: [
        '$rootScope',
        'TreatmentTypesService',
        'Permissions',
        controller
    ]
};
