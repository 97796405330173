import * as React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { applyBg, applyPadding } from '$ui/Flo/util';

interface Props {
    children: React.ReactNode;
    open: boolean;
}

export const Submenu = (props: Props) => {
    const { children, open } = props;

    return (
        <CSSTransition
            classNames="submenu"
            timeout={400}
            in={open}
            unmountOnExit
        >
            <Container key="submenu">
                <Subcontainer>{children}</Subcontainer>
            </Container>
        </CSSTransition>
    );
};

const Subcontainer = styled.div`
    ${({ theme }) =>
        applyBg({
            theme,
            hue: 'primary',
            shade: '2',
            alpha: 0.45
        })};
    ${({ theme }) => applyPadding({ theme, padding: [2, 2, 2, 6] })};
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
`;

const Container = styled.div`
    height: auto;
    overflow: hidden;

    &.submenu-enter {
        max-height: 0;
    }

    &.submenu-enter.submenu-enter-active {
        max-height: 20vh;
        transition: all 300ms ease;
    }

    &.submenu-exit {
        max-height: 20vh;
    }

    &.submenu-exit.submenu-exit-active {
        max-height: 0;
        transition: all 300ms ease;
    }
`;

Submenu.defaultProps = {
    open: false
};
