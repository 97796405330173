import { dates } from '$utils';

function Actions($http) {
    var svc = this;

    svc.persist = function (id, record) {
        if (typeof id === 'object' && record === undefined) {
            return $http
                .post('/v2/actions', {
                    ...id,
                    date: dates.isoDate(id.date)
                })
                .then(function (res) {
                    return res.data;
                });
        }

        return $http.put('/v2/actions/' + id, {
            ...record,
            date: dates.isoDate(record.date)
        });
    };

    svc.exists = function (email) {
        return $http.get('/actions/exists/' + email).then(function (res) {
            return res.data;
        });
    };
}

export default Actions;
