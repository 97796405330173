import template from './icon.html';

function IconController() {
    var ctrl = this;

    ctrl.$onInit = function () {
        if (ctrl.size) {
            ctrl.width = ctrl.height = ctrl.size;
        }
    };
}

export default {
    template,
    controller: IconController,
    bindings: {
        icon: '@',
        size: '<',
        width: '<',
        height: '<'
    }
};
