/**
 * Channels
 *
 * This module encapsulates the state of Channels, which powers the
 * communication channels over which discourse between users and patients take
 * place, or between the team (notes).
 *
 * Right now, this supports email, SMS and notes. But eventually this will
 * encompass a number of channels of business events, like calls, form
 * submissions and instant messaging.
 *
 * This module bucks the trend and brings reducers, actions and selectors
 * together into a single, encapsulated module.
 *
 * Actions and selectors form the interface to this module, where the UI
 * dispatches actions which have some effect on state and selectors read from
 * state.
 *
 * This is a break from the CQRS-style we have currently but I'm not entirely
 * sure if there is an advantage to that on the frontend, especially since many
 * queries have a one-to-one correspondence to concerns.
 */

import { RootState } from '$state/store';
import { Channels, ChannelState, MemoizedChannelRules } from '$state/types';
import { createReducer, createSelector } from '@reduxjs/toolkit';
import { emailEnabled, smsEnabled } from '$state/queries/client';
import { whatsAppEnabled } from '$state/queries/integrations';

// Displayed tabs are data driven. This means in the future we
// should be able to configure which comm channels are available
// on a per client basis.
export const initialState: ChannelState = {
    [Channels.Note]: {
        channel: Channels.Note,
        writable: true,
        templatable: false,
        approval: false,
        icon: 'Edit',
        label: 'Note',
        fields: [
            {
                placeholder: 'Note',
                name: 'content',
                multiline: true,
                required: true
            }
        ]
    },
    [Channels.Email]: {
        channel: Channels.Email,
        writable: true,
        templatable: true,
        approval: false,
        icon: 'Mail',
        label: 'Email',
        fields: [
            {
                placeholder: 'Subject',
                name: 'subject',
                multiline: false,
                nextField: 'body',
                maxLength: 255
            },
            {
                placeholder: 'Message',
                name: 'body',
                multiline: true,
                required: true
            }
        ],
        canEndSnooze: true
    },
    [Channels.SMS]: {
        channel: Channels.SMS,
        writable: true,
        templatable: true,
        approval: false,
        icon: 'MessageSquare',
        label: 'SMS',
        fields: [
            {
                placeholder: 'Message',
                name: 'message',
                multiline: true,
                required: true
            }
        ],
        disabledMessage: 'Cannot send an SMS to an invalid number',
        canEndSnooze: true
    },
    [Channels.FormSubmissions]: {
        channel: Channels.FormSubmissions,
        writable: false,
        templatable: false,
        icon: 'CheckSquare',
        label: 'Form',
        fields: []
    },
    [Channels.WhatsApp]: {
        channel: Channels.WhatsApp,
        writable: true,
        templatable: true,
        approval: true,
        'icon': 'WhatsApp',
        'label': 'WhatsApp',
        fields: [
            {
                placeholder: 'Message',
                name: 'message',
                multiline: true,
                required: true
            }
        ],
        canEndSnooze: true,
        checkOngoing: true
    }
};

export default createReducer(initialState, () => {
    // ...
});

const channels = (state: RootState) => state.channels;

export const selectChannels = createSelector([channels], (channels) =>
    Object.values(channels)
);

const reverseChannelMap = Object.fromEntries(
    Object.entries(Channels).map(([k, v]) => [v, k])
);

export const selectEnabledChannels = createSelector(
    [selectChannels, (_: RootState, rules: MemoizedChannelRules) => rules],
    (channels, rules) => {
        return channels
            .filter(({ channel }) => {
                const key = reverseChannelMap[channel];

                if (typeof rules[key] === 'undefined') {
                    return true;
                }

                return rules[key];
            })
            .map((channel) => channel);
    }
);

export const selectWritableChannels = createSelector(
    [selectEnabledChannels],
    (enabledChannels) => enabledChannels.filter(({ writable }) => writable)
);

export const selectEnabledChannelKeys = createSelector(
    [selectEnabledChannels],
    (enabledChannels) => enabledChannels.map(({ channel }) => channel)
);

export const selectNavChannels = createSelector(
    [smsEnabled, emailEnabled, whatsAppEnabled],
    (smsEnabled, emailEnabled, whatsAppEnabled) => {
        const channels = [Channels.FormSubmissions];

        if (smsEnabled) {
            channels.push(Channels.SMS);
        }

        if (emailEnabled) {
            channels.push(Channels.Email);
        }

        if (whatsAppEnabled) {
            channels.push(Channels.WhatsApp);
        }

        return channels;
    }
);

export const selectTemplatableChannels = createSelector(
    [selectEnabledChannels],
    (enabledChannels) =>
        enabledChannels.filter(({ templatable }) => templatable)
);

export const selectChannel = (channel: Channels) => (rootState: RootState) =>
    rootState.channels[channel];

export const selectOngoingChannels = createSelector(
    [selectEnabledChannels],
    (enabledChannels) =>
        enabledChannels.filter(({ checkOngoing }) => checkOngoing)
            .map(({ channel }) => channel)
);
