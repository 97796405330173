export const Types = {
    REQUEST_TX_TYPES: 'REQUEST_TX_TYPES',
    RECEIVE_TX_TYPES: 'RECEIVE_TX_TYPES'
};

function constructor($http, TreatmentTypesService) {
    var requestTxTypes = () => ({
        type: Types.REQUEST_TX_TYPES
    });

    function receiveTxTypes(txTypes) {
        return {
            type: Types.RECEIVE_TX_TYPES,
            txTypes
        };
    }

    function fetchTxTypes() {
        return function (dispatch) {
            dispatch(requestTxTypes());

            return TreatmentTypesService.getTreatmentTypes().then((data) =>
                dispatch(receiveTxTypes(data))
            );
        };
    }

    return {
        ...Types,
        fetchTxTypes: fetchTxTypes
    };
}

export default constructor;
