export const Types = {
    REQUEST_FEATURES: 'REQUEST_FEATURES',
    RECEIVE_FEATURES: 'RECEIVE_FEATURES'
};

function constructor($http) {
    const requestFeatures = () => ({
        type: Types.REQUEST_FEATURES
    });

    const receiveFeatures = (features) => ({
        type: Types.RECEIVE_FEATURES,
        features
    });

    const fetchFeatures = () => {
        return (dispatch) => {
            dispatch(requestFeatures());

            return $http
                .get('/v2/features')
                .then((res) => {
                    dispatch(receiveFeatures(res.data));
                })
                .catch(() => {
                    dispatch(receiveFeatures([]));
                });
        };
    };

    return {
        ...Types,
        requestFeatures,
        receiveFeatures,
        fetchFeatures
    };
}

export default constructor;
