import { Types } from './features.actions';

const reducer = () => {
    return (state = [], action) => {
        switch (action.type) {
            case Types.REQUEST_FEATURES:
                return state;

            case Types.RECEIVE_FEATURES:
                return action.features;
        }

        return state;
    };
};

export default reducer;
