import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import { Attachment } from '$types';
import { Icon as FloIcon } from '$ui/Flo/Icon';
import { useHover } from '@/utils/hooks';
import { truncate } from '@/utils/str';
import { AttachmentLightbox } from '$ui/Flo/Lightbox';
import { DisplayImage } from '$ui/Timeline/Parts/Attachments/Image';
import { fetchAttachment } from '$api';
import { downloadFromBuffer } from '@/utils/files';

interface ImagesProps {
    images: Attachment[];
}

export const Images = ({ images }: ImagesProps) => {
    const [openLightbox, setOpenLightbox] = React.useState<boolean>(false);
    const [index, setIndex] = React.useState<number>(0);
    const isGallery = images.length > 1;
    const handleDownload = async (attachment: Attachment) => {
        const data = await fetchAttachment(attachment.url);
        downloadFromBuffer(data, attachment.name);
    };

    return (
        <Container isGallery={isGallery}>
            {openLightbox && (
                <AttachmentLightbox
                    onClose={() => setOpenLightbox(false)}
                    onDownload={handleDownload}
                    attachments={images}
                    index={index}
                />
            )}
            {images.map((image, index) => (
                <DisplayImageContainer isGallery={isGallery} key={image.name}>
                    <ImageOverlay
                        image={image}
                        inGallery={isGallery}
                        onDownload={handleDownload}
                    >
                        <DisplayImage
                            image={image}
                            toggleLightbox={() => {
                                setIndex(index);
                                setOpenLightbox(true);
                            }}
                        />
                    </ImageOverlay>
                </DisplayImageContainer>
            ))}
        </Container>
    );
};

const DisplayImageContainer = styled.div<{ isGallery: boolean }>`
    grid-column: ${({ isGallery }) => (isGallery ? 'span 1' : 'span 2')};
    border-radius: 4px;
    overflow: hidden;
`;

interface ImageOverlayProps {
    image: Attachment;
    inGallery: boolean;
    onDownload: (attachment: Attachment) => void;
    children?: React.ReactNode;
}

const ImageOverlay = ({
    image,
    inGallery,
    onDownload,
    children,
}: ImageOverlayProps) => {
    const hoverRef = React.useRef<HTMLDivElement>(null);
    const [hover, setHover] = React.useState<boolean>(false);
    useHover({
        ref: hoverRef,
        onEnter: () => setHover(true),
        onLeave: () => setHover(false),
    });
    const limitName = inGallery ? 20 : 40;

    return (
        <ImagePlaceholder ref={hoverRef}>
            {hover && (
                <Overlay>
                    <Name>{truncate(image.name, limitName)}</Name>
                    <IconHolder>
                        <FloIcon
                            icon="DownloadCloud"
                            hue="white"
                            opacity={1}
                            onClick={() => onDownload(image)}
                            clickable={true}
                        />
                    </IconHolder>
                </Overlay>
            )}
            {children}
        </ImagePlaceholder>
    );
};

const Container = styled.div<{ isGallery?: boolean }>`
    display: grid;
    gap: ${mix.unit({ size: 0.5 })};
    grid-template-columns: minmax(50%, 200px) minmax(50%, 200px);
    grid-auto-rows: 200px;
    width: 100%;
    height: 100%;
    flex-direction: column;
`;

const ImagePlaceholder = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 48px;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
    );
    padding: ${mix.unit({ size: 1 })};
    display: flex;
    flex-direction: row;
    justify-items: center;
    justify-content: space-between;
`;
const Name = styled.div`
    font-size: 12px;
    line-height: 16px;
    font-family: Roboto, serif;
    font-weight: 400;
    color: #ffffff;
    height: 32px;
`;
const IconHolder = styled.div`
    background: #00000052;
    width: 32px;
    height: 32px;
    border-radius: ${mix.unit({ size: 0.5 })};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const StatusContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: #f9fafb;
    border: 1px solid #eaecf0;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
`;
