import { Button } from '$ui/Flo/Button';
import { Icon } from '$ui/Flo/Icon';
import { Label } from '$ui/Flo/Label';
import { applyPadding, mix, scale } from '$ui/Flo/util';
import { isEmpty } from '@/utils/lang';
import * as React from 'react';
import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { AddLabelField } from './Labels/AddLabelField';
import { SuggestLabels } from './Labels/SuggestLabels';
import { Pad } from './Attributes';
import { Tooltip } from '$ui/Flo/Tooltip';
import { RecentLabels } from '$ui/Patient/Labels/RecentLabels';

export interface LabelsProps {
    labels: string[];
    accountLabels: string[];
    recentLabels: string[];
    onDelete: (label: string) => void;
    onAdd: (label: string) => void;
}

const MAX_LABELS = 15;

export const Labels: React.FC<LabelsProps> = (props) => {
    const { labels, onDelete, onAdd, accountLabels, recentLabels } = props;

    const [labelsStaging, setLabelsStaging] = React.useState<string[]>(labels);

    const inputRef = React.useRef<HTMLInputElement>(null);

    const containerRef = React.useRef<HTMLDivElement>(null);
    const [rows, setRows] = React.useState<HTMLElement[][]>([]);
    const [expanded, setExpanded] = React.useState(false);
    const [displayRecentLabels, setDisplayRecentLabels] = React.useState(false);

    const [addLabelValue, setAddLabelValue] = React.useState('');
    const [filteredAccountLabels, setFilteredAccountLabels] = React.useState<
        string[]
    >([]);
    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const [hasSpill, setHasSpill] = React.useState(false);
    const onSpillOver = React.useCallback((node) => {
        setHasSpill(!!node);
    }, []);

    const resetInputValue = () => setAddLabelValue('');
    const displayError = (message: string) => {
        setErrorMessage(message);
        setShowError(true);
    };
    const hideError = () => {
        setErrorMessage('');
        setShowError(false);
    };

    const getRows = () => {
        const container = containerRef.current;
        if (!container) return;

        const rows = [];
        let row: HTMLElement[] = [];
        for (let i = 0; i < container.children.length; i++) {
            const child = container.children[i] as HTMLElement;
            // if it is first child,
            // if offsetTop value is different from the previous child's
            // offsetTop value that means it is a new row
            if (
                i === 0 ||
                child.offsetTop !==
                    (container.children[i - 1] as HTMLElement).offsetTop
            ) {
                // if offset is different, push the row to rows
                if (row.length) {
                    rows.push(row);
                }
                // initializes a new row array with the current child
                row = [child];
            } else {
                // current child belongs to the same row as the previous child
                // then push it to the row array
                row.push(child);
            }
        }
        // left over elements
        if (row.length) {
            rows.push(row);
        }
        return rows;
    };

    const ROWS_TO_SHOW = 2;

    const toggleExpand = (state?: boolean) => {
        if (isEmpty(labelsStaging)) {
            return;
        }

        setExpanded(state || !expanded);
        resetInputValue();
    };

    const handleDelete = (label: string) => {
        if (!label) return;

        const rows = getRows() ?? [];
        if (rows.length <= ROWS_TO_SHOW) {
            setExpanded(false);
        }

        onDelete(label);
        setLabelsStaging(labelsStaging.filter((l) => l !== label));
    };

    const handleAdd = (label: string) => {
        if (!label) return;

        if (isEmpty(label)) {
            displayError('Label cannot be empty');
            return;
        }

        if (labelsStaging.length >= MAX_LABELS) {
            displayError(`Patient can have maximum of ${MAX_LABELS} labels`);
            return;
        }

        if (
            labelsStaging.filter((l) => l.toLowerCase() === label.toLowerCase())
                .length > 0
        ) {
            displayError('Patient already has this label');
            return;
        }

        const rows = getRows() ?? [];

        if (!expanded && rows.length >= ROWS_TO_SHOW) {
            setExpanded(true);
        }

        onAdd(label);
        setLabelsStaging([...labelsStaging, label]);
        setFilteredAccountLabels(
            filteredAccountLabels.filter((l) => l !== label),
        );

        inputRef.current?.focus();
        setDisplayRecentLabels(true);
    };

    const handleAddInputChange = (value: string) => {
        setAddLabelValue(value);
    };

    useEffect(() => {
        // dont want to recompute rows if it is already expanded
        if (expanded) {
            return;
        }
        setRows(getRows() ?? []);
    }, [labelsStaging, expanded]);

    useEffect(() => {
        setFilteredAccountLabels(
            accountLabels.filter(
                (label) =>
                    label
                        .toLowerCase()
                        .includes(addLabelValue.toLocaleLowerCase()) &&
                    !labelsStaging.includes(label),
            ),
        );
    }, [addLabelValue]);

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                hideError();
            }, 3000);
        }
    }, [showError]);

    const showAddLabelField = expanded || !hasSpill || isEmpty(labels);
    const isAddLabelFieldDisabled = labelsStaging.length + 1 >= MAX_LABELS;

    const openRecentLabels =
        isEmpty(addLabelValue) &&
        displayRecentLabels &&
        !isEmpty(recentLabels) &&
        !isAddLabelFieldDisabled;

    return (
        <div style={{ position: 'relative' }}>
            <PreRendered containerRef={containerRef} labels={labelsStaging} />
            {!expanded && (
                <Wrapper
                    show
                    pointer={rows.length > ROWS_TO_SHOW}
                    onClick={() => {
                        if (rows.length <= ROWS_TO_SHOW) {
                            return;
                        }
                        setExpanded(true);
                    }}
                >
                    <TagIcon />
                    {isEmpty(labelsStaging) && (
                        <NoLabel>This patient has no labels</NoLabel>
                    )}

                    {!isEmpty(labelsStaging) &&
                        rows.slice(0, ROWS_TO_SHOW).map((row, i) =>
                            row.map((label, j) => {
                                const isLast = j === row.length - 1;
                                if (
                                    i === ROWS_TO_SHOW - 1 &&
                                    isLast &&
                                    rows.length > ROWS_TO_SHOW
                                ) {
                                    const length = rows
                                        .slice(0, ROWS_TO_SHOW)
                                        .reduce(
                                            (acc, row) => acc + row.length,
                                            0,
                                        );

                                    const spill =
                                        labelsStaging.length - length + 2;
                                    return (
                                        <Label key={label.textContent} round>
                                            <span ref={onSpillOver}>
                                                +{spill}
                                            </span>
                                        </Label>
                                    );
                                }

                                return (
                                    label.textContent && (
                                        <Label
                                            key={label.textContent}
                                            round
                                            deletable
                                            onDelete={() =>
                                                handleDelete(
                                                    label.textContent ?? '',
                                                )
                                            }
                                            trailingIcon={'X'}
                                        >
                                            {label.textContent}
                                        </Label>
                                    )
                                );
                            }),
                        )}
                </Wrapper>
            )}
            {expanded && (
                <>
                    <Wrapper show>
                        <TagIcon />
                        {labelsStaging.map((label) => (
                            <Label
                                key={label}
                                round
                                deletable
                                onDelete={() => handleDelete(label)}
                                trailingIcon={'X'}
                            >
                                {label}
                            </Label>
                        ))}
                    </Wrapper>
                    <CollapseButton>
                        <Button
                            icon="ChevronUp"
                            onClick={() => toggleExpand()}
                            mode="outline"
                            hue="primary"
                            border="x-small"
                            rounded
                            align="center"
                            iconHue="grey"
                            iconShade="1"
                            size="small"
                            borderShade="9"
                            iconSpace="x-small"
                        >
                            Collapse
                        </Button>
                    </CollapseButton>
                </>
            )}

            {showAddLabelField && (
                <AddLabel>
                    <Pad scalar={!expanded ? [1, 0, 0, 0] : [0, 0]}>
                        <AddLabelField
                            inputRef={inputRef}
                            onEnter={(label) => handleAdd(label)}
                            onChange={(value) => handleAddInputChange(value)}
                            handleRecentLabels={(display) =>
                                setDisplayRecentLabels(display)
                            }
                            errors={
                                <Tooltip
                                    key={errorMessage}
                                    size="small"
                                    open={showError}
                                    align="center"
                                    hue="red"
                                >
                                    {errorMessage}
                                </Tooltip>
                            }
                            disabled={isAddLabelFieldDisabled}
                        />
                    </Pad>
                    {!isEmpty(addLabelValue) &&
                        !isEmpty(filteredAccountLabels) &&
                        !isAddLabelFieldDisabled && (
                            <SuggestField>
                                <SuggestLabels
                                    labels={filteredAccountLabels}
                                    onClick={(label) => handleAdd(label)}
                                />
                            </SuggestField>
                        )}
                    {openRecentLabels && (
                        <SuggestField>
                            <RecentLabels
                                labels={recentLabels}
                                onClick={(label) => handleAdd(label)}
                            />
                        </SuggestField>
                    )}
                </AddLabel>
            )}
        </div>
    );
};

const TagIcon = () => {
    return (
        <IconSpacer style={{ cursor: 'inherit' }}>
            <Icon icon="Tag" hue="black" shade="1" />
        </IconSpacer>
    );
};

interface PreRenderedProps {
    containerRef: React.RefObject<HTMLDivElement>;
    labels: string[];
}

const PreRendered = ({ containerRef, labels }: PreRenderedProps) => {
    return (
        <Wrapper ref={containerRef} show={false}>
            <TagIcon />
            {labels.map((label) => (
                <Label key={label} round deletable onDelete={() => null}>
                    {label}
                </Label>
            ))}
        </Wrapper>
    );
};

const IconSpacer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${({ theme }) => applyPadding({ theme, padding: [0.96875] })};
    ${mix.bg({ hue: 'grey', shade: '10' })}
    border-radius: 1.25rem;

    & > svg {
        width: ${({ theme }) => scale(theme, 1.5)}px;
        height: ${({ theme }) => scale(theme, 1.5)}px;
        opacity: 1 !important;
    }
`;

// no longer destroys the pre render wrapper because we need it to calculate
// the offsetTop z-index so that it doesn't overlap with the other elements top
// with a negative value so that it doesn't affect the layout of the other
// elements (absolute position here)
const Wrapper = styled.div<{ show: boolean; pointer?: boolean }>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    top: -400px;
    max-height: 300px;
    overflow: auto;

    ${({ show }) =>
        !show &&
        css`
            visibility: collapse;
            position: absolute;
            z-index: -1;
        `};
    cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
    ${mix.gap({ size: 0.5 })};
`;

const CollapseButton = styled.div`
    ${mix.padding({ padding: [1, 0] })}
`;

const AddLabel = styled.div`
    position: relative;
`;

const SuggestField = styled.div`
    position: absolute;
    width: 108%;
    transform: translateX(-4%) translateY(4px);
    z-index: 9;
`;

const NoLabel = styled.div`
    ${mix.padding({ padding: [0.5, 1] })}
    ${mix.type({ level: 'small' })};
    ${mix.color({ profile: 'secondary' })};
    display: flex;
    align-items: center;
    justify-content: center;
`;
