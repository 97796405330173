import * as React from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { mix } from '$ui/Flo/util';

interface Props {
    open: boolean;
    onClose: () => void;
    children: React.ReactNode;
    width?: number;
}

export const Slideout = (props: Props) => {
    const { open, onClose, children, width } = props;
    return (
        <>
            {open && <PreventScroll />}
            <Background open={open} onClick={onClose} />
            <Modal open={open}>
                <Canvas width={width}>{children}</Canvas>
            </Modal>
        </>
    );
};

type ModalProps = { open: boolean };

const Modal = styled.div<ModalProps>`
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    z-index: 3000;
    pointer-events: none;
    transform: translateX(100vw);
    transition: 0.3s transform ease-out;

    ${({ open }) =>
        open &&
        css`
            transform: translateX(0);
        `}
`;

const Canvas = styled.div<Pick<Props, 'width'>>`
    position: relative;
    z-index: 3100;
    width: 80%;

    ${({ width }) =>
        width &&
        css`
            ${mix.width({ size: width })}
        `};

    height: 100%;
    background: white;
    pointer-events: auto;
`;

const Background = styled.div<ModalProps>`
    background: rgba(0, 0, 0, 0);
    position: fixed;
    z-index: 2900;
    width: 100%;
    height: 100%;
    transition: 0.25s background-color;
    pointer-events: none;

    ${({ open }) =>
        open &&
        css`
            background: rgba(0, 0, 0, 0.25);
            pointer-events: auto;
        `};
`;

const PreventScroll = createGlobalStyle`
    body {
        overflow: hidden;
    }
`;
