import template from './confirmation.html';
import { isToday, parseISO } from 'date-fns';

function ConfirmationController() {
    var ctrl = this;

    ctrl.$onInit = function () {
        ctrl.txTypeArticle = /^[aeiou]/i.test(ctrl.txType) ? 'an' : 'a';

        ctrl.finalAttempt = ctrl.action.stage === 'callback3';

        ctrl.attemptsLeft = 'another three times';
        if (/^callback/.test(ctrl.action.stage)) {
            ctrl.attemptsLeft = {
                callback1: 'another two times',
                callback2: 'one more time',
                callback3: 'zero'
            }[ctrl.action.stage];
        }

        ctrl.nurturing = ['thinking', 'working'].includes(ctrl.action.stage);

        ctrl.confirm = ctrl.onConfirm;
    };

    ctrl.firstName = function (midSentence) {
        midSentence = midSentence || false;
        var defaultName = midSentence ? 'this patient' : 'This patient';
        return ctrl.action.firstName ? ctrl.action.firstName : defaultName;
    };

    ctrl.isToday = function () {
        return isToday(parseISO(ctrl.date));
    };

    ctrl.bookingType = function () {
        const txBookingStages = [
            'inTx',
            'consultation',
            'won',
            'txPlanConsult'
        ];

        if (
            txBookingStages.includes(ctrl.action.stage) &&
            ctrl.nextAction !== 'rearrangedConsult'
        ) {
            return 'treatment appointment';
        }

        return 'consultation';
    };
}

export default {
    template,
    controller: ConfirmationController,
    bindings: {
        action: '<',
        txType: '<',
        nextAction: '<',
        date: '<',
        note: '<',
        onConfirm: '&'
    }
};
