import template from './color-field.html';
import { array } from '$utils';

function ColorFieldController() {
    const ctrl = this;
    const maybeAddHash = (value) => (/^#/.test(value) ? value : '#' + value);

    ctrl.$onInit = () => {
        ctrl.mask = {
            mask: [/#?/, ...array.times(6, () => /[a-fA-F0-9]/)],
            guide: false
        };
    };

    ctrl.onValueChanged = (value, valid) => {
        if (/^#?[a-fA-F0-9]{3}(?:[a-fA-F0-9]{3})?$/.test(value)) {
            ctrl.value = maybeAddHash(value);
        }

        ctrl.onChanged({
            value: maybeAddHash(value),
            valid
        });
    };
}

export default {
    template,
    controller: ColorFieldController,
    bindings: {
        id: '@',
        validator: '<',
        validationError: '@',
        showError: '<',
        icon: '@',
        placeholder: '@',
        label: '@',
        value: '<',
        onChanged: '&',
        onBlurred: '&',
        type: '@'
    }
};
