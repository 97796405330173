import { createBrowserHistory } from 'history';
import { matchPath } from 'react-router';

export const bridgedHistory = createBrowserHistory();

export const LegacyRoutes = {
    'add-patient': '/patients/add',
    login: '/login',
    dashboard: '/',
    sync: '/stages/change',
    'tx-types': '/tx-types',
    clientTxs: '/clients/:clientId/txs',
    clientTxTypes: '/clients/:clientId/tx-types'
};

interface ClientState {
    clientId: number;
}

type State = ClientState | {};

const interpolateRoute = (route: string, state: State = {}) => {
    return route.replace(/:([a-zA-Z]+)/, (_, k) => {
        return state[k] ?? '';
    });
};

export const UiRouter = {
    go(routeName: string, state: State = {}) {
        console.log(`Routing ${routeName}`);

        const route = LegacyRoutes[routeName];
        if (!route) {
            console.warn(`Bridge Router: Could not find route ${routeName}`);
        }

        bridgedHistory.push(interpolateRoute(route, state));
    },

    is(route: string) {
        return LegacyRoutes[route] === bridgedHistory.location.pathname;
    }
};

export class UiRouterProvider {
    $get() {
        return UiRouter;
    }

    otherwise() {}

    state() {
        return this;
    }
}

export class UiParams {
    static get(param: string): string {
        const match = Object.values(LegacyRoutes)
            .map((path) =>
                matchPath(bridgedHistory.location.pathname, {
                    path,
                    exact: true,
                    strict: true
                })
            )
            .find((route) => route !== null);

        return match?.params[param];
    }
}

export class UiParamsProvider {
    $get() {
        return new Proxy(UiParams, {
            get(target, prop, _) {
                return target.get(prop.toString());
            }
        });
    }
}
