import template from './field.html';

function FieldController() {
    var ctrl = this;

    ctrl.$onInit = function () {
        ctrl.type = ctrl.type || 'text';

        if (!ctrl.value) {
            ctrl.value = '';
        }

        if (!ctrl.mask) {
            ctrl.mask = { mask: false };
        }

        ctrl.modified = false;
    };

    ctrl.hasIcon = function () {
        return Boolean(ctrl.icon);
    };

    ctrl.isValid = function () {
        var validator = ctrl.validator;

        if (!validator) {
            return true;
        }

        if (typeof validator === 'function') {
            return validator(String(ctrl.value));
        }

        if (validator.constructor !== RegExp) {
            return true;
        }

        return validator.test(ctrl.value);
    };

    ctrl.onInputBlurred = function () {
        ctrl.modified = true;
        ctrl.showError = !ctrl.isValid();
        ctrl.onBlurred({ valid: ctrl.isValid() });
    };

    ctrl.onInputChanged = function () {
        ctrl.showError = ctrl.modified && !ctrl.isValid();
        ctrl.onChanged({
            value: ctrl.value,
            valid: ctrl.isValid()
        });
    };
}

export default {
    template,
    controller: FieldController,
    bindings: {
        id: '@',
        validator: '<',
        validationError: '@',
        showError: '<',
        icon: '@',
        placeholder: '@',
        label: '@',
        value: '<',
        onChanged: '&',
        onBlurred: '&',
        type: '@',
        mask: '<',
        accepts: '@'
    }
};
