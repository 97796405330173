import { store } from '$state';
import { openPatient } from '$state/concerns/patient';

// consolidates code to toggle new edit patient experience

export const open = (patientId: string) => {
    store.dispatch(
        openPatient({
            patientId
        })
    );
};
