function Search($http) {
    this.search = function (query) {
        return $http
            .get(`/v3/patients/search?query=` + query)
            .then(function (response) {
                return response.data.map(function (patient) {
                    patient.name = [patient.firstName, patient.lastName].join(
                        ' '
                    );
                    return patient;
                });
            });
    };
}

export default Search;
