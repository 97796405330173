import React from 'react';
import { ConnectedFilter } from '$ui/PatientFilter/ConnectedFilter';
import { Toolbar } from '$ui/PatientFilter/Toolbar';
import { Heading } from '$ui/Flo/Heading';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';
import * as stages from '$state/concerns/stages';
import { useAppDispatch } from '$state';

interface FrameProps {
    run: () => void;
    children: React.ReactNode;
    title: string;
    description: string;
}

export const Frame = ({ run, children, description, title }: FrameProps) => {
    const dispatch = useAppDispatch();

    React.useEffect(run, []);

    React.useEffect(() => {
        dispatch(stages.load());
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            <Container>
                <Toolbar>
                    <ConnectedFilter
                        actionLabel="Run"
                        run={run}
                        excludeFilters={['stages']}
                    />
                </Toolbar>
                <Wrapper>
                    <div style={{ margin: '0', paddingBottom: '24px' }}>
                        <Heading level="h3" align="left" vSpace={2}>
                            {title}
                        </Heading>
                        <Description>{description}</Description>
                    </div>
                    {children}
                </Wrapper>
            </Container>
        </div>
    );
};

interface CellProps {
    right?: boolean;
    down?: boolean;
    children: React.ReactNode;
    span?: number;
}

export const Cell = ({
    right = false,
    down = false,
    children,
    span
}: CellProps) => {
    return (
        <div
            style={{
                position: 'relative',
                gridColumn: span ? `span ${span}` : 'auto'
            }}
        >
            {right && <RightIcon icon="ChevronsRight" size={3} />}
            {down && <DownIcon icon="ChevronsDown" size={3} />}
            {children}
        </div>
    );
};

export const Subtitle = ({ children }: { children: React.ReactNode }) => {
    return (
        <Heading profile="body" level="body1" bold align="left" vSpace={2}>
            {children}
        </Heading>
    );
};

const Container = styled.div`
    &.section-enter {
        opacity: 0;
        position: static;
    }

    &.section-enter-active {
        opacity: 1;
        transition: opacity 0.3s;
        position: absolute;
        left: 0;
        right: 0;
        max-height: calc(100vh - 59px);
    }

    &.section-exit {
        opacity: 1;
        position: static;
    }

    &.section-exit-active {
        opacity: 0;
        transition: opacity 0.3s;
        position: absolute;
        left: 0;
        right: 0;
        max-height: calc(100vh - 59px);
    }
`;

export const HorizontalLine = styled.div`
    ${mix.bg({ hue: 'grey', shade: '9' })}
    width: 100%;
    height: 1px;
    ${mix.margin({ margin: [3, 0] })}
`;

const Wrapper = styled.div`
    ${mix.padding({ padding: 2 })};
    width: 100%;
    margin: 0 auto;
`;

export const Description = styled.div`
    ${mix.color({ profile: 'body' })}
    ${mix.type({ level: 'body2' })}
    grid-column: 2 / 5;
    ${mix.bg({ hue: 'grey', shade: '9' })}
    ${mix.round({ rounded: true })}
    ${mix.padding({ padding: [2] })}
    border: 1px solid ${mix.palette({ hue: 'grey', shade: '8' })};
`;

export const Grid = styled.div`
    ${mix.margin({ margin: [0, 0, 3] })};
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    ${mix.gap({ size: 3 })}
`;

const RightIcon = styled(Icon)`
    position: absolute;
    z-index: 99;
    right: -12px;
    top: 50%;
    transform: translate(50%, -50%);
`;

const DownIcon = styled(Icon)`
    position: absolute;
    z-index: 99;
    bottom: -12px;
    left: 50%;
    transform: translate(-50%, 50%);
`;
