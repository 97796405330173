function Permissions($state, $http) {
    var svc = this,
        promise;

    svc.setRole = setRole;
    svc.can = can;
    svc.guard = guard;

    function activate() {
        if (promise) {
            return promise;
        }

        return get();
    }

    function get() {
        return (promise = $http.get('/permissions').then(function (res) {
            svc.permissions = res.data;
        }));
    }

    function setRole(role) {
        svc.role = role;
    }

    function can(permission) {
        return activate().then(function () {
            if (svc.role === 'admin') {
                return true;
            }

            return svc.permissions[svc.role]?.includes(permission);
        });
    }

    function guard(permission) {}
}

export default ['$state', '$http', Permissions];
