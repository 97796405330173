import validator from 'validator';

function AddClientController() {
    const ctrl = this;

    ctrl.client = {
        name: '',
        abbreviation: '',
        primaryColor: '',
        guides: []
    };

    ctrl.validators = {
        notEmpty: (value) => {
            return !validator.isEmpty(value, { ignore_whitespace: true });
        }
    };

    ctrl.onNameChanged = (value) => {
        ctrl.client.name = value;
    };

    ctrl.onAbbreviationChanged = (value) => {
        ctrl.client.abbreviation = value;
    };

    ctrl.onPrimaryColorChanged = (value) => {
        ctrl.client.primaryColor = value;
    };

    ctrl.onLogoAdded = (file) => {
        ctrl.client.logo = file;
    };

    ctrl.onGuidesAdded = (files) => {
        ctrl.client.guides = [...ctrl.client.guides, ...files];
    };

    ctrl.onGuideRemoved = (id) => {
        ctrl.client.guides = ctr.client.guides.filter(
            (guide) => guide.id !== id
        );
    };

    ctrl.onHeaderAdded = (file) => {
        ctrl.client.header = file;
    };

    ctrl.onFooterAdded = (file) => {
        ctrl.client.footer = file;
    };
}

export default AddClientController;
