import template from './checkboxes.html';
import { array } from '$utils';

function CheckboxesController() {
    var ctrl = this;

    ctrl.select = function (option, index) {
        if (ctrl.isActive(index)) {
            ctrl.selected = ctrl.selected.filter((i) => i !== index);
        } else {
            ctrl.selected = array.unique([...ctrl.selected, index]);
        }

        ctrl.onChange({ option: option, selected: ctrl.selected });
    };

    ctrl.isActive = function (index) {
        return ctrl.selected.includes(index);
    };
}

export default {
    template,
    controller: CheckboxesController,
    bindings: {
        selected: '<',
        onChange: '&',
        options: '<'
    }
};
