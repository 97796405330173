import * as React from 'react';

interface Props {
    hello: boolean;
}

export const Test = ({ hello }: Props) => {
    if (! hello) {
        return (
            <div>
                Rude, Angular!
            </div>
        );
    }

    return (
        <div>
            Hello Angular, I'm React!
        </div>
    );
};
