import { useMachine } from '@xstate/react';
import { fsm } from '$ui/AddPatient/fsm';
import React from 'react';
import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';
import { Searching } from '$ui/AddPatient/Searching';
import { ContactDetails } from './ContactDetails';
import { CountryCode } from '@/utils/phone';
import styled from 'styled-components';
import { DuplicateReport } from '$ui/AddPatient/DuplicateReport';

interface Props {
    onClose?: () => void;
}

export const AddPatientForm = (props: Props) => {
    const { onClose = () => null } = props;

    const [state, send] = useMachine(fsm);

    return (
        <Container>
            <Title>
                <div>Add a new patient</div>
                <Icon
                    icon="X"
                    size={3}
                    opacity={1}
                    color="gray-600"
                    clickable
                    onClick={onClose}
                />
            </Title>
            {state.matches('details') && (
                <Body>
                    <ContactDetails
                        country={CountryCode.GB}
                        onChange={(details) => send('search', { details })}
                        duplicatesFoundWith={state.context.duplicates}
                    />
                    {state.matches('details.initial') && (
                        <CenteredSection>
                            We need to check if a patient with this email
                            already exists.
                        </CenteredSection>
                    )}
                    {state.matches('details.searching') && (
                        <CenteredSection>
                            <Searching />
                        </CenteredSection>
                    )}
                    {state.matches('details.duplicates') && (
                        <Section>
                            <DuplicateReport {...state.context.duplicates} />
                        </Section>
                    )}
                    {state.matches('details.unique') && (
                        <div>
                            <h2>Unique Patient</h2>
                            <button onClick={() => send('continue')}>
                                Continue
                            </button>
                        </div>
                    )}
                </Body>
            )}

            {state.matches('nextAction') && (
                <div>
                    {state.matches('nextAction.initial') && (
                        <div>
                            <h2>Select Next Action</h2>
                            <button onClick={() => send('select')}>
                                Select Action
                            </button>
                        </div>
                    )}
                    {state.matches('nextAction.selected') && (
                        <div>
                            <h2>Complete Next Action Form</h2>
                            <button onClick={() => send('continue')}>
                                Continue
                            </button>
                        </div>
                    )}
                    <button onClick={() => send('back')}>Back</button>
                </div>
            )}

            {state.matches('confirmation') && (
                <div>
                    <h2>Confirmation</h2>
                    <button onClick={() => send('confirm')}>Confirm</button>
                    <button onClick={() => send('back')}>Back</button>
                </div>
            )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.h1`
    ${mix.type({ level: 'body1', bold: true })};
    ${mix.padding({ padding: 3 })};
    color: var(--gray-600);
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray-100);
    ${mix.height({ size: 7 })};
`;

const Body = styled.div`
    ${mix.padding({ padding: 3 })};
    ${mix.gap({ size: 1 })};
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
`;

const Section = styled.div`
    ${mix.padding({ padding: [2, 0, 0] })};
    flex: 1 0 auto;
    display: flex;
    border-top: 1px solid var(--gray-100);
`;

const CenteredSection = styled(Section)`
    ${mix.type({ level: 'body2' })};
    ${mix.padding({ padding: 3 })};
    color: var(--gray-400);
    text-align: center;
    align-items: center;
    justify-content: center;
`;
