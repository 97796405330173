import * as React from 'react';
import styled from 'styled-components';
import { Text } from '$ui/Flo/Text';
import { applyTextColor, applyType } from '$ui/Flo/util';

export interface Props {
    item: string;
    selected: boolean;
    counter: number;
    onClick: () => void;
}

export const SubmenuItem = (props: Props) => {
    const { item, selected, counter, onClick } = props;

    return (
        <Line selected={selected} onClick={onClick} data-qa={item}>
            <Text
                dark
                level="body2"
                bold={selected}
                profile={selected ? 'title' : 'secondary'}
            >
                {item}
            </Text>
            {counter > 0 && <span>{counter}</span>}
        </Line>
    );
};

interface LineProps {
    selected: boolean;
}

const Line = styled.span<LineProps>`
    ${({ theme, selected }) =>
        applyTextColor({
            theme,
            dark: true,
            profile: selected ? 'body' : 'secondary'
        })};
    ${({ theme }) => applyType({ theme, level: 'body2' })};

    font-weight: ${(props) => (props.selected ? 500 : 300)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: ${(props) => (props.selected ? 'initial' : 'pointer')};
    padding-right: 0.6rem;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }
`;

SubmenuItem.defaultProps = {
    selected: false,
    counter: 0
};
