function LayoutService() {
    this.listeners = [];

    this.set = function (layout) {
        this.listeners.forEach(function (listener) {
            if (typeof listener === 'function') {
                listener(layout);
            }
        });

        this.layout = layout;
    };

    this.reset = function () {
        this.set('default');
    };

    this.onChange = function (listener) {
        this.listeners.push(listener);
        // tell new listener of current state
        listener(this.layout);
    };

    this.reset();
}

export default LayoutService;
