import * as React from 'react';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';
import { PlaceholderLeaf } from '$state/types/contexts';
import { useHover } from '@/utils/hooks';

export interface Props {
    name: string;
    placeholderSelected: (placeholder: string) => void;
    values: Array<PlaceholderLeaf>;
    toggleBrowser: () => void;
}

export const Group = (props: Props) => {
    const {
        name,
        placeholderSelected,
        toggleBrowser,
        values
    } = props;

    const hoverRef = React.useRef<HTMLDivElement>(null);
    // const [hover, setHover] = useDebounce();
    const [hover, setHover] = React.useState<boolean>(false);

    useHover({
        ref: hoverRef,
        onEnter: () => setHover(true),
        onLeave: () => setHover(false)
    });

    return <Container ref={hoverRef} key={name}>
        {name}
        <Icon opacity={hover ? 1 : 0.75} icon="ChevronRight" size={2} clickable={true} />
        <SubGroupWrapper open={hover}>
            <SubGroupHolder open={hover}>
                {values.map(tag =>
                    <SubGroup
                        data-testid={`tag-${name}-${tag.name}`}
                        key={`${name}-${tag.name}`}
                        onClick={() => { placeholderSelected(tag.value);  toggleBrowser()}}
                    >
                        {tag.name}
                    </SubGroup>
                )}
            </SubGroupHolder>
        </SubGroupWrapper>
    </Container>;
};

const Container = styled.div`
    width: 100%;
    min-width: ${mix.unit({ size: 16.5 })};
    height: ${mix.unit({ size: 3.5 })};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${mix.padding({ padding: [0.5, 1, 0.5, 1] })};
    border-radius: ${mix.unit({ size: 0.5 })};
    cursor: pointer;
    position: relative;

    &:hover {
        background-color: var(--gray-100);
        color: var(--gray-900);
    }
`;

const SubGroupWrapper = styled.div<{ open: boolean }>`
    width: 100%;
    transform: scale(0);
    transition: transform 0.25s;
    transform-origin: top left;
    position: absolute;
    z-index: 299;
    top: -5px;
    left:  ${mix.unit({ size: 16.5 })};
    padding-left: ${mix.unit({ size: 1.5 })};

    ${({ open }) =>
        open && css`
            transform: scale(1);
            transition: transform 0.25s;
        `
    }
`;

const SubGroupHolder = styled.div<{ open: boolean }>`
    ${mix.padding({ padding: [0.5] })};
    min-width: ${mix.unit({ size: 16.5 })};
    background: white;
    border: 1px solid var(--gray-100);
    border-radius: ${mix.unit({ size: 0.5 })};
    box-shadow: 0 2px 4px 0 #0000000A;
    height: auto;
    gap: ${mix.unit({ size: 0.5 })};
    display: none;

    ${({ open }) =>
        open && css`
            display: block;
        `
    }
`;

const SubGroup = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${mix.padding({ padding: [0.5, 1, 0.5, 1] })};
    border-radius: ${mix.unit({ size: 0.5 })};
    cursor: pointer;
    position: relative;
    color: var(--gray-600);
    &:hover {
        background-color: var(--gray-100);
        color: var(--gray-900);
    }
`;

