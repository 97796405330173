import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { RootState } from '$state';
import * as api from '$api';

export type Stage = {
    key: string;
    name: string;
};

type InitState = {
    status: 'init';
};

type LoadingState = {
    status: 'loading';
};

type LoadedState = {
    status: 'loaded';
    data: Stage[];
};

type ErroredState = {
    status: 'errored';
};

type State = InitState | LoadingState | LoadedState | ErroredState;

const initialState: State = {
    status: 'init'
};

export const load = createAsyncThunk('stages/load', api.loadStages);

export default createReducer<State>(initialState, (builder) => {
    builder.addCase(load.pending, (state) => {
        state.status = 'loading';
    });

    builder.addCase(load.fulfilled, (_, action) => {
        return {
            status: 'loaded',
            data: action.payload.map(({ name, display_name }) => ({
                key: name,
                name: display_name
            }))
        };
    });

    builder.addCase(load.rejected, (state) => {
        state.status = 'errored';
    });
});

export const isLoaded = (
    state: RootState
): state is RootState & { stages: LoadedState } =>
    state.stages.status === 'loaded';

export const selectStages = (state: RootState): Stage[] => {
    return isLoaded(state) ? state.stages.data : [];
};
