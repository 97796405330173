import { store } from '@/state';
import { authenticated } from '@/state/events';
import { lang } from '$utils';

function AuthService(
    $rootScope,
    $q,
    $http,
    $localStorage,
    base64,
    Permissions,
    $ngRedux,
    featuresActions
) {
    var svc = this,
        pvt = {};

    pvt.client = void 0;
    pvt.claims = void 0;

    pvt.parseTokenClaims = function () {
        var token = $localStorage.token;
        var user = {};

        if (token !== void 0) {
            var encoded = token.split('.')[1];
            user = JSON.parse(base64.urldecode(encoded));
        }

        return user;
    };

    svc.signin = function (details) {
        return $http
            .post('/authenticate', details)
            .then(function (res) {
                const { token, features, role } = res.data;

                $localStorage.token = token;
                // TODO: Add an init endpoint which returns long-lived frontend state
                $localStorage.features = features;
                $localStorage.role = role;
                svc.getToken();
                $rootScope.$broadcast('signedIn');

                svc.authenticate();
            })
            .then(() => {
                try {
                    Tawk_API.setAttributes(
                        {
                            name: details.email,
                            email: details.email
                        },
                        (err) =>
                            console.log('Failed to add name to Tawk: ', err)
                    );
                } catch (e) {}
            });
    };

    svc.authenticate = function () {
        const { claims, token } = svc.getToken();

        // Preserve old functionality in absense of locally-stored features
        // Eventually should add an endpoint that verifies the token
        // on app boot and return features in that too.
        if (!$localStorage.features) {
            $ngRedux.dispatch(featuresActions.requestFeatures());
        } else {
            $ngRedux.dispatch(
                featuresActions.receiveFeatures($localStorage.features)
            );
        }

        const role = $localStorage.role ?? 'user';

        store.dispatch(
            authenticated({
                client: claims.client,
                features: $localStorage.features ?? [],
                token,
                role
            })
        );
    };

    svc.logout = function (after) {
        delete pvt.claims;
        delete $localStorage.token;
        delete $localStorage.role;
        pvt.client = void 0;

        if (lang.isFunction(after)) {
            after();
        }
    };

    svc.getToken = function () {
        if (lang.isEmpty(pvt.claims)) {
            pvt.claims = pvt.parseTokenClaims();
        }

        Permissions.setRole(pvt.claims.role);

        return {
            token: $localStorage.token,
            claims: pvt.claims
        };
    };

    svc.isAuthed = function () {
        return $localStorage.token !== void 0;
    };

    svc.isAdmin = function () {
        svc.getToken();

        if (pvt.claims === undefined) {
            return false;
        }

        return pvt.claims.role === 'admin';
    };

    svc.getCurrentClient = function () {
        if (lang.isEmpty(pvt.client)) {
            return (pvt.client = $http
                .get('/clients/current')
                .then(function (res) {
                    return res.data;
                })
                .catch(function () {
                    pvt.client = void 0;
                }));
        }

        return pvt.client;
    };
}

export default [
    '$rootScope',
    '$q',
    '$http',
    '$localStorage',
    'base64',
    'Permissions',
    '$ngRedux',
    'featuresActions',
    AuthService
];
