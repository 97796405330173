import { EmailState } from '$state/types/inbox';
import { Heading } from '$ui/Flo/Heading';
import { Icon } from '$ui/Flo/Icon';
import { applyMargin } from '$ui/Flo/util';
import { ModalBody, ModalContainer, ModalHeader } from '$ui/Flo/Modal/LightModal';
import React from 'react';
import styled from 'styled-components';
import { truncate } from '@/utils/str';

export interface Props {
    state: EmailState;
    subject: string;
    body?: string;
    onClose: () => void;
}

export const EmailModal = (props: Props) => {
    const { subject, onClose } = props;
    const limitSubject = 300;

    return (
        <ModalContainer>
            <ModalHeader title={subject}>
                Subject: {truncate(subject, limitSubject)}
                <CloseIcon onClick={onClose} icon='X' color="gray-500" size={2.5} opacity={1} clickable />
            </ModalHeader>
            <EmailBody {...props} />
        </ModalContainer>
    );
};

const EmailBody = ({ state, body }: Props) => {
    if (state === 'loading') {
        return (
            <ModalBody>
                <NonLoadedStateWrapper>
                    <Icon icon='Settings' spin size={6} />
                    <Heading profile='secondary' level='h6'>
                        Loading email...
                    </Heading>
                </NonLoadedStateWrapper>
            </ModalBody>
        );
    }

    if (state === 'error') {
        return (
            <ModalBody>
                <NonLoadedStateWrapper>
                    <Icon icon='AlertCircle' hue='red' opacity={1} size={6} />
                    <Heading profile='body' level='h6'>
                        Oops! Something went wrong :(
                    </Heading>
                </NonLoadedStateWrapper>
            </ModalBody>
        );
    }

    return (
        <ModalBody>
            {body && (
                <EmailContent
                    dangerouslySetInnerHTML={{ __html: body }}
                ></EmailContent>
            )}
        </ModalBody>
    );
};

const CloseIcon = styled(Icon)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NonLoadedStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > * + * {
        ${({ theme }) => applyMargin({ theme, margin: [2, 0, 0, 0] })};
    }
`;

const EmailContent = styled.div`
    ul {
        font-size: 14px;
    }

    table {
        font-size: 14px;
    }
`;
