import React, { useContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { ThemeContext } from 'styled-components';
import { Hue, Shade, ThemeType } from '$ui/Flo/types';
import { palette } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';

interface ToastArgs {
    id: string;
    message: JSX.Element | string;
}

export const info = ({ id, message }: ToastArgs) => {
    toast(message, { id });
};

export const success = ({ id, message }: ToastArgs) => {
    toast.success(message, { id });
};

export const error = ({ id, message }: ToastArgs) => {
    toast.error(message, { id });
};

export const Toasts = () => {
    const theme = useContext(ThemeContext) as ThemeType;

    const type = theme.type.body1;

    const color = (hue: Hue, shade: Shade = '7') =>
        palette({ theme, hue, shade });

    const opacity = 0.75;

    return (
        <Toaster
            position="top-center"
            toastOptions={{
                icon: (
                    <Icon
                        opacity={opacity}
                        icon="RotateCw"
                        hue="white"
                        spin
                        size={3}
                    />
                ),
                style: {
                    background: color('primary'),
                    fontFamily: type.family,
                    fontSize: type.size,
                    color: 'white',
                    borderRadius: '50px',
                    padding: '8px 16px'
                },

                duration: 3000,

                error: {
                    icon: (
                        <Icon opacity={opacity} icon="X" hue="white" size={3} />
                    ),
                    style: {
                        background: color('red')
                    }
                },

                success: {
                    icon: (
                        <Icon
                            opacity={opacity}
                            icon="Check"
                            hue="white"
                            size={3}
                        />
                    ),
                    style: {
                        background: color('green')
                    }
                }
            }}
        />
    );
};
