import * as React from 'react';
import { Theme } from '$ui/Theme';
import { api } from '$api';
import { useAppSelector } from '$state/hooks';
import { client } from '$state/queries/client';

export const Canvas = () => {
    return (
        <div>
            <Theme>
                <Form />
            </Theme>
        </div>
    );
};

const Form = () => {
    const currentClient = useAppSelector(client);

    const formIdRef = React.useRef<HTMLInputElement>(null);
    const nameRef = React.useRef<HTMLInputElement>(null);
    const emailRef = React.useRef<HTMLInputElement>(null);
    const phoneRef = React.useRef<HTMLInputElement>(null);
    const messageRef = React.useRef<HTMLTextAreaElement>(null);
    const consentRef = React.useRef<HTMLInputElement>(null);
    const typeRef = React.useRef<HTMLSelectElement>(null);

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const clientId = currentClient?.id;

        const formId = formIdRef.current?.value;
        if (!formId) {
            console.error('Form ID is a required field');
            return;
        }

        const name = nameRef.current?.value;
        if (!name) {
            console.error('Name is a required field');
            return;
        }

        const email = emailRef.current?.value;
        if (!email) {
            console.error('Email is a required field');
            return;
        }

        const phone = phoneRef.current?.value;
        const message = messageRef.current?.value;
        const consent = Boolean(consentRef.current?.value);
        const type = typeRef.current?.value ?? 'General';

        api.post(`/forms/${clientId}/${formId}`, {
            name,
            email,
            phone,
            message,
            opt_in: consent,
            type
        }).then(() => console.log('Form submitted!'));
    };

    return (
        <form style={{ padding: 32 }} onSubmit={onSubmit}>
            <h2>Test Form Submission Form</h2>
            <Sep>
                <input
                    ref={formIdRef}
                    data-cy="form-field-id"
                    required
                    type="text"
                    name="formId"
                    placeholder="Form ID"
                />
            </Sep>
            <Sep>
                <input
                    ref={nameRef}
                    data-cy="name-field"
                    required
                    type="text"
                    name="name"
                    placeholder="Name"
                />
            </Sep>
            <Sep>
                <input
                    ref={emailRef}
                    data-cy="email-field"
                    required
                    type="email"
                    name="email"
                    placeholder="Email"
                />
            </Sep>
            <Sep>
                <input
                    ref={phoneRef}
                    data-cy="phone-field"
                    type="text"
                    name="phone"
                    placeholder="Phone"
                />
            </Sep>
            <Sep>
                <textarea
                    ref={messageRef}
                    data-cy="message-text"
                    name="message"
                    placeholder="Your enquiry"
                ></textarea>
            </Sep>
            <Sep>
                <label>
                    <input type="hidden" name="gdpr" value="0" />
                    <input
                        ref={consentRef}
                        data-cy="gdpr-checkbox"
                        type="checkbox"
                        name="gdpr"
                        value="1"
                    />
                    Do u give consent?
                </label>
            </Sep>
            <Sep>
                <select ref={typeRef} data-cy="type-select" name="type">
                    <option>Ortho</option>
                    <option>Implant</option>
                    <option>Cosmetic</option>
                    <option>General</option>
                    <option>Dentures</option>
                    <option>Invisalign</option>
                </select>
            </Sep>
            <Sep>
                <button data-cy="submit-btn">Fire the lazor</button>
            </Sep>
        </form>
    );
};

interface SepProps {
    children: React.ReactNode;
}

export const Sep = (props: SepProps) => {
    return <div style={{ marginBottom: 16 }}>{props.children}</div>;
};
