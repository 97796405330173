const link = (scope, el, attrs) => {
    if (!scope.v2Accepts) {
        return;
    }

    const regex = new RegExp(scope.v2Accepts);
    el.on('keypress', e => {
        if (e.key.length !== 1) {
            return;
        }

        if (!regex.test(e.key)) {
            e.preventDefault();
            return;
        }
    });

    // el.on('keyup', e => {
    //     el.val(
    //         el
    //             .val()
    //             .split('')
    //             .filter(char => regex.test(char))
    //             .join('')
    //     );
    // });
};

export default function() {
    return {
        restrict: 'A',
        scope: {
            v2Accepts: '@'
        },
        link
    };
}
