import React, { useEffect } from 'react';
import { useForm, Controller, FieldError } from 'react-hook-form';
import { useTextField } from 'react-aria';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';
import { phone } from '$utils';
import { CountryCode } from '@/utils/phone';

interface ContactDetailsProps {
    country: CountryCode;
    onChange: (data: FormData) => void;
    duplicatesFoundWith: {
        email: boolean;
        phone: boolean;
    };
}

interface FormData {
    email: string;
    phone: string;
    fullName: string;
}

export const ContactDetails: React.FC<ContactDetailsProps> = ({
    country,
    onChange,
    duplicatesFoundWith,
}) => {
    const emailRef = React.useRef<HTMLInputElement>(null);
    const phoneRef = React.useRef<HTMLInputElement>(null);
    const nameRef = React.useRef<HTMLInputElement>(null);

    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm<FormData>({
        mode: 'onChange',
    });

    useEffect(() => {
        const subscription = watch(() =>
            handleSubmit((data) => {
                onChange(data);
            })(),
        );
        return () => subscription.unsubscribe();
    }, [watch, handleSubmit]);

    return (
        <Form>
            <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                    required: 'Email is required',
                    pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: 'Invalid email address',
                    },
                }}
                render={({ field }) => {
                    const { inputProps, labelProps } = useTextField(
                        {
                            ...field,
                            label: "What is the patient's email address?",
                        },
                        emailRef,
                    );
                    return (
                        <InputWrapper>
                            <Label {...labelProps}>
                                What is the patient's email address?
                                <AlertWrapper
                                    type="email"
                                    enabled={duplicatesFoundWith.email}
                                >
                                    <Input
                                        {...inputProps}
                                        hasError={!!errors.email}
                                    />
                                </AlertWrapper>
                            </Label>
                            <ErrorMessage error={errors.email} />
                        </InputWrapper>
                    );
                }}
            />
            <Controller
                name="phone"
                control={control}
                defaultValue=""
                rules={{
                    required: 'Phone number is required',
                    validate: {
                        validPhoneNumber: (value) => {
                            return (
                                phone.is(value, country) ||
                                'Must be a valid phone number'
                            );
                        },
                    },
                }}
                render={({ field }) => {
                    const { inputProps, labelProps } = useTextField(
                        {
                            ...field,
                            label: "What is the patient's phone number?",
                        },
                        phoneRef,
                    );
                    return (
                        <InputWrapper>
                            <Label {...labelProps}>
                                What is the patient's phone number?
                                <AlertWrapper
                                    type="phone"
                                    enabled={duplicatesFoundWith.phone}
                                >
                                    <Input
                                        {...inputProps}
                                        hasError={!!errors.phone}
                                    />
                                </AlertWrapper>
                            </Label>
                            <ErrorMessage error={errors.phone} />
                        </InputWrapper>
                    );
                }}
            />
            <Controller
                name="fullName"
                control={control}
                defaultValue=""
                rules={{
                    required: 'Full name is required',
                    pattern: {
                        value: /(.|\s)*\S(.|\s)*/,
                        message: 'Full name cannot be blank',
                    },
                }}
                render={({ field }) => {
                    const { inputProps, labelProps } = useTextField(
                        {
                            ...field,
                            label: "What is the patient's full name?",
                        },
                        nameRef,
                    );
                    return (
                        <InputWrapper>
                            <Label {...labelProps}>
                                What is the patient's full name?
                                <Input
                                    {...inputProps}
                                    hasError={!!errors.fullName}
                                />
                            </Label>
                            <ErrorMessage error={errors.fullName} />
                        </InputWrapper>
                    );
                }}
            />
        </Form>
    );
};

type AlertWrapperProps = {
    enabled: boolean;
    children: React.ReactNode;
    type: 'email' | 'phone';
};

export const AlertWrapper = ({
    enabled,
    type,
    children,
}: AlertWrapperProps) => {
    if (!enabled) {
        return <>{children}</>;
    }

    return (
        <div
            css={`
                position: relative;
                line-height: 1;
            `}
        >
            <div
                title={`Duplicates found with the given ${type}`}
                css={`
                    position: absolute;
                    right: ${mix.unit({ size: 1.5 })};
                    top: 50%;
                    transform: translateY(-50%);
                `}
            >
                <Icon icon="AlertTriangle" opacity={1} color="error-500" />
            </div>
            <div>{children}</div>
        </div>
    );
};

type ErrorMessageProps = {
    error?: FieldError;
};

const ErrorMessage = ({ error }: ErrorMessageProps) => {
    return (
        <div
            css={`
                visibility: hidden;
                ${mix.type({ level: 'small' })};
                color: var(--error-600);
                display: flex;
                align-items: center;
                ${mix.gap({ size: 0.5 })}
                ${!!error &&
                css`
                    visibility: visible;
                `}
            `}
        >
            <Icon icon="AlertCircle" color="error-600" opacity={1} />
            <div>{error?.message ?? 'For visibility calculations'}</div>
        </div>
    );
};

const Form = styled.form`
    ${mix.gap({ size: 1 })};
    display: flex;
    flex-direction: column;
`;

const InputWrapper = styled.div`
    ${mix.gap({ size: 1 })}
    display: flex;
    flex-direction: column;
`;

const Label = styled.label`
    ${mix.type({ level: 'body2', bold: true })}
    font-size: 14px;
    color: var(--gray-600);
`;

const Input = styled.input<{ hasError: boolean }>`
    ${mix.padding({ padding: 1.5 })};
    border: 1px solid
        ${(props) => (props.hasError ? 'var(--error-300)' : 'var(--gray-300)')};
    border-radius: 4px;
    width: 100%;
`;
