import angular from 'angular';
import backToTop from './back-to-top.directive';
import DialogController from './dialog.controller';
import DialogService from './dialog.service';
import select2 from './select2.directive';

export default angular
    .module('app.legacy.global', [])
    .controller('DialogController', [
        '$scope',
        '$mdDialog',
        'actions',
        DialogController
    ])
    .directive('backToTop', ['$timeout', backToTop])
    .directive('select2', ['$timeout', select2])
    .service('DialogService', ['$mdDialog', DialogService]).name;
