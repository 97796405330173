const template = `
    <div class='container container-thin'>
        <div class='section is-open baseline-top'>
            <div class='section-header'>
            <i class='zmdi zmdi-hospital section-header-icon'></i>
            Treatment Types for {{ $ctrl.client.name }}
            </div>
            <div class='section-body'>
            <v2-checkboxes
                options='$ctrl.txTypes'
                selected='$ctrl.selected'
                on-change='$ctrl.onSelected(option, selected)'></v2-checkboxes>
            <div class='buttons'>
                <v2-next-button ng-click='$ctrl.save()'>
                Save
                </v2-next-button>
            </div>
            </div>
        </div>
    </div>
`;

function controller(
    $rootScope,
    $stateParams,
    TreatmentTypesService,
    ClientService,
    Permissions
) {
    var ctrl = this;

    Permissions.guard('manage_tx_types');

    ctrl.selected = [];

    TreatmentTypesService.list().then(function (txTypes) {
        ctrl.txTypes = Object.fromEntries(
            txTypes.map(({ id, name }) => [id, name])
        );
    });

    ClientService.get($stateParams.clientId).then(function (client) {
        ctrl.client = client;
    });

    TreatmentTypesService.listForClient($stateParams.clientId).then(function (
        selected
    ) {
        ctrl.selected = selected.map(function (txType) {
            return String(txType.id);
        });
    });

    ctrl.save = function () {
        TreatmentTypesService.saveForClient(
            $stateParams.clientId,
            ctrl.selected
        ).then(function () {
            $rootScope.$broadcast('message:show', {
                type: 'success',
                content:
                    'Success! Treatment types saved for ' + ctrl.client.name
            });
        });
    };

    ctrl.onSelected = function (option, selected) {
        ctrl.selected = selected;
    };
}

export const clientTxTypes = {
    template,
    controller: [
        '$rootScope',
        '$stateParams',
        'TreatmentTypesService',
        'ClientService',
        'Permissions',
        controller
    ]
};
