import React from 'react';
import Logo from '$resources/logo-o.svg';
import styled, { keyframes } from 'styled-components';
import { mix } from '$ui/Flo/util';

export const Searching = () => {
    return (
        <Container>
            <LogoContainer>
                <Logo />
                <Pulse>
                    <Logo />
                </Pulse>
            </LogoContainer>
            <TextContainer>
                <Title>Searching</Title>
                <Summary>
                    We are just checking if this patient already exists.
                </Summary>
            </TextContainer>
        </Container>
    );
};

const Container = styled.div`
    ${mix.gap({ size: 2 })};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const LogoContainer = styled.div`
    ${mix.sq({ size: 5 })};
    position: relative;

    svg {
        fill: var(--gray-300);
    }
`;

const pulse = keyframes`
    0% {
        transform: scale(1);
        opacity: 0;
    }

    50% {
        opacity: 0.25;
    }

    100% {
        transform: scale(1.5);
        opacity: 0;
    }
`;

const Pulse = styled.div`
    animation: ${pulse} 1.5s ease-in-out infinite;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
`;

const TextContainer = styled.div`
    ${mix.gap({ size: 1 })};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Title = styled.div`
    ${mix.type({ level: 'body1' })};
    color: var(--gray-500);
`;

const Summary = styled.div`
    ${mix.type({ level: 'body2' })};
    color: var(--gray-400);
`;
