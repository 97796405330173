function shapeshift() {
    return {
        restrict: 'A',
        controller: [
            'LayoutService',
            function (LayoutService) {
                this.svc = LayoutService;
            }
        ],
        scope: {},
        controllerAs: '$ctrl',
        link: function (scope, element, attrs, ctrl) {
            ctrl.svc.onChange(function (layout) {
                if (layout === 'auth') {
                    element.addClass('body-wide body-auth');
                } else {
                    element.removeClass('body-wide body-auth');
                }
            });
        }
    };
}

export default shapeshift;
