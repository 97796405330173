import template from './options-with-other.html';
import { obj } from '$utils';

function OptionsWithOtherController() {
    var ctrl = this;

    ctrl.$onInit = function () {
        if (ctrl.options.other === undefined) {
            ctrl.options.other = 'Other';
        }

        if (ctrl.selectedText === undefined) {
            return;
        }

        if (!isOption(ctrl.selectedText)) {
            ctrl.selected = 'other';
        } else {
            ctrl.selected = obj.invert(ctrl.options)[ctrl.selectedText];
            ctrl.selectedText = '';
        }
    };

    ctrl.setOption = function (option, index) {
        ctrl.selected = index;

        ctrl.onChange({
            option: ctrl.isOther() ? '' : option,
            index: index
        });
    };

    ctrl.updateOther = function () {
        ctrl.onChange({
            option: ctrl.selectedText,
            index: ctrl.selected
        });
    };

    ctrl.isOther = function () {
        return ctrl.selected === 'other';
    };

    function isOption(text) {
        return Object.values(ctrl.options).includes(text);
    }
}

export default {
    template,
    controller: OptionsWithOtherController,
    bindings: {
        selected: '<',
        options: '<',
        otherLabel: '@',
        selectedText: '<',
        onChange: '&'
    }
};
