import { combineReducers } from 'redux';

function ReduxReducerConfig($ngRedux, txTypes, features) {
    $ngRedux.replaceReducer(
        combineReducers({
            txTypes,
            features
        })
    );
}

export default [
    '$ngRedux',
    'txTypesReducer',
    'featuresReducer',
    ReduxReducerConfig
];
