import { lang } from '$utils';

const link = (scope, el, attrs) => {
    el.on('drop', (e) => {
        e.preventDefault();
        const { files } = e.dataTransfer;
        if (files && lang.isFunction(scope.v2FileArea)) {
            scope.v2FileArea({ files });
        }
    });

    el.on('dragover', (e) => {
        e.preventDefault();
        if (lang.isFunction(scope.v2FileAreaOnDragOver)) {
            scope.v2FileAreaOnDragOver();
        }
    });

    el.on('dragleave', (e) => {
        e.preventDefault();
        if (lang.isFunction(scope.v2FileAreaOnDragLeave)) {
            scope.v2FileAreaOnDragLeave();
        }
    });

    el.on('dragenter', (e) => e.preventDefault());
};

export default function () {
    return {
        restrict: 'A',
        scope: {
            v2FileArea: '&',
            v2FileAreaOnDragOver: '&',
            v2FileAreaOnDragLeave: '&'
        },
        link
    };
}
