import template from './section-header.html';

class SectionHeaderController {}

export default {
    template,
    controller: SectionHeaderController,
    transclude: true,
    require: {
        sectionCtrl: '^v2Section'
    }
};
