import angular from 'angular';
import authService from './auth.service';
import canDirective from './can.directive';
import permissionsService from './permissions.service';

export default angular
    .module('app.legacy.auth', [])
    .service('AuthService', authService)
    .directive('can', canDirective)
    .service('Permissions', permissionsService).name;
