import States from './states.constant';

function NextActionHeader() {
    var svc = this;

    svc.get = function (state) {
        switch (state) {
            default:
            case States.BOOKING:
                return {
                    text: 'Book this patient in for a consultation',
                    icon: 'calendar',
                };

            case States.SELECTION:
                return {
                    text: 'What happened with this patient?',
                    icon: 'question-circle-o',
                };

            case States.ENQUIRY:
                return {
                    text: 'Add a new lead',
                    icon: 'plus-square-o',
                };

            case States.THINKING_CONSULT:
                return {
                    text: 'Chase up a consultation booking',
                    icon: 'comment-o',
                };

            case States.THINKING_TX:
                return {
                    text: 'Chase up treatment decision',
                    icon: 'comment-o',
                };

            case States.CALLBACK:
                return {
                    text: 'Attempt another call',
                    icon: 'phone-square',
                };
        }
    };
}

export default NextActionHeader;
