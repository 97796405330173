import * as React from 'react';
import styled from 'styled-components';
import { Placeholder } from '$state/types/contexts';
import { FloatingTextField, Label } from '$ui/Flo/Field/FloatingTextField';
import { FormGrid } from '$ui/Settings/Forms/FormGrid';
import { ChannelConfig, ChannelField, Channels } from '$state/types';
import { mix } from '$ui/Flo/util';
import { SaveButton } from '$ui/Flo/AsyncButton';
import { useAppDispatch, useAppSelector } from '$state';
import * as templates from '$state/concerns/templates';
import { selectPlaceholders, selectStatus } from '$state/concerns/placeholders';
import { selectChannel } from '$state/concerns/channels';
import { SelectedStatus, Template } from '$state/types/templates';
import { AsyncStatus } from '$types';
import { useTemplateParams } from '$ui/TemplatesV2/hooks';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router';
import { spliceAtCursor, useFocusedField } from '@/utils/fields';
import { Placeholders } from '$ui/Placeholders';
import { ApproveModal } from '$ui/TemplatesV2/Parts';

const SAVED_TO_INIT_DELAY_MS = 2000;

export const ConnectedTemplateEditor = () => {
    const history = useHistory();
    const { templateId, channelName } = useTemplateParams();
    const dispatch = useAppDispatch();
    const selectedStatus = useAppSelector(templates.selectSelectedState);
    const templateStatus = useAppSelector(templates.selectStatus);
    const template = useAppSelector(templates.selectTemplate(templateId));
    const channel = useAppSelector(
        selectChannel(template?.channel ?? channelName ?? Channels.Email),
    );
    const placeholders = useAppSelector(selectPlaceholders);
    const placeholderStatus = useAppSelector(selectStatus);

    const emptyTemplate: Template = {
        id: uuid(),
        name: '',
        subject: '',
        body: '',
        channel: channel.channel,
        approval: false,
        status: null,
        reason: null
    };

    const [draft, setDraft] = React.useState<Template>(
        template ?? emptyTemplate,
    );

    const [showApprove, setShowApprove] = React.useState(false);

    React.useEffect(() => {
        setDraft(template ?? emptyTemplate);
    }, [templateId, channelName, templateStatus]);

    React.useEffect(() => {
        if (selectedStatus === 'deleted') {
            history.push(`/settings/templates/${channelName}`);
            dispatch(templates.complete());
            return;
        }

        if (selectedStatus !== 'saved') {
            return;
        }

        const timeoutId = setTimeout(() => {
            history.push(`/settings/templates/${channelName}/edit/${draft.id}`);
            dispatch(templates.complete());
        }, SAVED_TO_INIT_DELAY_MS);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [selectedStatus]);

    const saveNewTemplate = async (template: Template) => {
        await dispatch(templates.saveTemplate(template))
        onClose()
    }

    const onSave = () => {
        if (channel.approval) {
            setShowApprove(true);
        } else {
            saveNewTemplate(draft)
        }
    };

    const onApprove = (isApproval: boolean) => {
        const newDraftWithApproval = {
            ...draft,
            approval: isApproval
        }
        saveNewTemplate(newDraftWithApproval)
    };

    const onClose = () => {
        setShowApprove(false);
    };

    return (
            <TemplateEditor
                status={selectedStatus}
                template={draft}
                channel={channel}
                placeholderStatus={placeholderStatus}
                placeholders={placeholders}
                onChange={(template: Template) => setDraft(template)}
                onSave={onSave}
                onApprove={onApprove}
                showApprove={showApprove}
                onDelete={() => {
                    dispatch(templates.deleteTemplate(draft));
                }}
                onClose={onClose}
            />
    );
};

interface TemplateEditorProps {
    status: SelectedStatus;
    template: Template;
    channel: ChannelConfig;
    placeholderStatus: AsyncStatus;
    placeholders: Placeholder[];
    onChange: (template: Template) => void;
    onSave: () => void;
    onDelete: () => void;
    onClose: () => void;
    onApprove: (isApproval: boolean) => void;
    showApprove: boolean;
}

export const TemplateEditor = (props: TemplateEditorProps) => {
    const {
        status,
        template,
        channel,
        placeholders,
        onSave,
        onApprove,
        onClose,
        showApprove
    } = props;

    const fields: ChannelField[] = channel.fields;

    const refs = {
        subject: React.useRef<HTMLInputElement>(null),
        body: React.useRef<HTMLInputElement>(null),
    };
    const [placeholderSelected, setPlaceholderSelected] = React.useState<null | string>(null);

    const [focused, focusedRef, setFocus] = useFocusedField(refs);

    const disabled = status !== 'init';

    const onChange = (value: Partial<Template>) => {
        props.onChange({
            ...template,
            ...value,
        });
    };

    React.useEffect(() => {
        if (placeholderSelected) {
            insertTag(placeholderSelected);
        }
    }, [placeholderSelected]);

    const inputFields = fields.map((field: ChannelField) => {
        const name = field.name === 'subject' ? 'subject' : 'body';

        if(name === 'body') {
            return (
                <div key={field.placeholder}>
                    <Label label={field.placeholder} bold={false}/>
                    <BodyContainer>
                        <PlaceholdersContainer>
                            <Left>
                                <Placeholders
                                    position={"bottom"}
                                    placeholders={placeholders}
                                    placeholderSelected={p => { setPlaceholderSelected(p) }}
                                />
                            </Left>
                        </PlaceholdersContainer>
                        <BodyTextField
                            {...field}
                            disabled={disabled}
                            key={field.placeholder}
                            value={template[name] ?? ''}
                            onChange={(value) => {
                                onChange({ [name]: value });
                            }}
                            ref={refs[name]}
                            onFocus={setFocus(name)}
                            styles={{
                                border: 'none',
                                borderTop: '1px solid var(--gray-200)',
                            }}
                            innerStyles={{
                                resize: 'none'
                            }}
                            disableError
                            rows={10}
                        />
                    </BodyContainer>
                </div>
            );
        }

        return (
            <FloatingTextField
                {...field}
                disabled={disabled}
                key={field.placeholder}
                label={field.placeholder}
                value={template[name] ?? ''}
                onChange={(value) => {
                    onChange({ [name]: value });
                }}
                ref={refs[name]}
                onFocus={setFocus(name)}
                labelBold={false}
            />
        );
    });

    const insertTag = (tag: string) => {
        const updatedFocusedName = focused ?? 'body';

        if (!updatedFocusedName) {
            return;
        }

        const updatedFocusRef = focusedRef ?? refs[updatedFocusedName];

        const spliced = spliceAtCursor(tag, updatedFocusRef);

        if (!spliced) {
            return;
        }

        onChange({
            [updatedFocusedName]: spliced,
        });

        focusedRef?.current?.focus();
    };

    React.useLayoutEffect(() => {
        const { current } = refs.body;

        if (!current) {
            return;
        }

        if (current.type === 'textarea' && current.value) {
            current.style.height = 'auto';
            current.style.height = current.scrollHeight + `px`;
        }
    }, [template.body]);

    return (
        <>
            <FormGrid>
                <FloatingTextField
                    key="templateName"
                    disabled={disabled}
                    label="Template Name"
                    value={template.name ?? ''}
                    onChange={(v) => onChange({ name: v })}
                />

                {inputFields}
                <ApproveModal isOpen={showApprove} closeOnOutsideClick onClose={onClose} onApprove={onApprove} />
            </FormGrid>
            <ActionBar>
                <SaveButton
                    label="Save Template"
                    color={'primary-500'}
                    disabled={disabled || !template.name || !template.body}
                    doing={status === 'saving'}
                    done={status === 'saved'}
                    size="med-large"
                    onClick={onSave}
                    disableIcon={true}
                />
            </ActionBar>
        </>
    );
};

const ActionBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--gray-100);
    ${mix.padding({ padding: 4 })};
`;

const BodyContainer = styled.div`
    margin-top: 0;
    background-color: #fff;
    border: 1px solid var(--gray-300);
`;

const PlaceholdersContainer = styled.div`
    width: 100%;
    ${mix.padding({ padding: [0.5, 0.5, 0, 0.5] })};
    display: flex;
    justify-content: space-between;
`;

const Left = styled.div`
    display: flex;
    ${mix.gap({ size: 0.5 })};
`;

const BodyTextField = styled(FloatingTextField)`
    border-top: 1px solid var(--gray-200);
    resize: none;
`;
