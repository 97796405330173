import React from 'react';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { dates } from '$utils';
import { lowerFirst } from '@/utils/str';
import { IconName } from '$ui/Flo/Icon';

interface Props {
    icon?: IconName;
    type: string;
    content: string;
    name: string;
    time: string;
    onView?: () => void;
    read?: boolean;
}

const delta = (time: string) => dates.delta(time, new Date(), true);

export function HeaderNotification(props: Props) {
    const { type, content, name, time, onView, read = false } = props;
    const [duration, setDuration] = React.useState<string>(delta(time));
    const [visible, setVisible] = React.useState<boolean>(false);

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            setDuration(delta(time));
        }, 10_000);

        return () => clearInterval(intervalId);
    }, [time]);

    const typeSelector = (type: string) => {
        switch (type) {
            case 'snooze':
                return 'Snooze has ended' + (content ? ': ' : ' ');
            default:
                return '';
        }
    };

    return (
        <Container
            read={read}
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
        >
            <Content>
                <NameTime>
                    <Name>{name}</Name>
                    <Time visible={visible}>{duration}</Time>
                    {visible && (
                        <Buttons>
                            {onView && (
                                <Button onClick={onView}>Open Patient</Button>
                            )}
                        </Buttons>
                    )}
                </NameTime>
                <Title>
                    <Type>{typeSelector(type)}</Type>
                    {lowerFirst(content)}
                </Title>
            </Content>
        </Container>
    );
}

const Container = styled.div<{
    read: boolean;
}>`
    ${mix.type({ level: 'body2' })};
    ${mix.bg({ hue: 'primary', shade: '1' })};
    color: var(--gray-300);
    border-radius: 3px;
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
    position: relative;
    text-rendering: geometricPrecision;
    border: 3px solid ${mix.palette({ hue: 'primary', shade: '1' })};
    ${mix.margin({ margin: [0, 2, 1] })};
    ${mix.padding({ padding: 2 })};

    ${({ read }) =>
        !read &&
        css`
            border-left-color: ${mix.palette({
                hue: 'primary',
                shade: '6'
            })};
        `}
    &:last-child {
        margin-bottom: 0;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    ${mix.gap({ size: 1 })};
`;

const NameTime = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
`;

const Name = styled.div`
    ${mix.type({ level: 'body2', bold: true })};
`;

const Title = styled.div`
    ${mix.type({ level: 'body2', bold: false, lineHeight: '1.5' })};
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

const Type = styled.span`
    color: var(--gray-400);
`;

const Time = styled.div<{ visible: boolean }>`
    ${mix.type({ level: 'small' })};
    color: var(--gray-400);

    ${({ visible }) =>
        visible &&
        css`
            display: none;
        `}
`;

const Button = styled.div`
    ${mix.padding({ padding: [0.5, 1] })};
    ${mix.type({ level: 'small' })};
    ${mix.round({ rounded: true })};
    ${mix.bg({ hue: 'primary', shade: '2', alpha: 0.5 })};
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
        background: rgba(255, 255, 255, 20%);
    }
`;

const Buttons = styled.div`
    position: absolute;
    right: 0;
    padding: 0;
    ${mix.bg({ hue: 'primary', shade: '1' })};
`;
