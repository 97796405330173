import template from './options-field.html';

export default {
    template,
    bindings: {
        label: '@',
        options: '<',
        value: '<',
        onChanged: '&',
        showError: '<'
    }
};
