import * as React from 'react';
import { store as defaultStore } from '@/state';
import { Provider } from 'react-redux';

export const withRedux =
    <P extends {}>(Target: (props: P) => JSX.Element) =>
    ({
        store = defaultStore,
        ...props
    }: P & {
        store?: typeof defaultStore;
    }) => {
        /** @ts-ignore */
        return (
            <Provider store={store}>
                {/* @ts-ignore */}
                <Target {...props} />
            </Provider>
        );
    };
