class SyncService {
    constructor($q, $http) {
        this.$q = $q;
        this.$http = $http;
    }

    listStages() {
        return this.$http.get('/change-stage/stages').then((res) => {
            return res.data;
        });
    }

    findPatient(id) {
        return this.$http.get('/change-stage/patients/' + id).then((res) => {
            return res.data;
        });
    }

    savePatient(id, stage) {
        return this.$http
            .put('/change-stage/patients/' + id, {
                stage
            })
            .then((res) => {
                return res.data.result;
            });
    }
}

export default SyncService;
