import { obj } from '$utils';
import template from './next-action-flo.html';

const states = {
    booking: {
        text: 'Book this patient in for a consultation',
        icon: 'calendar'
    },
    selection: {
        text: 'What happened with this patient?',
        icon: 'question-circle-o'
    },
    enquiry: {
        text: 'Add a new lead',
        icon: 'plus-square-o'
    },
    thinkingConsult: {
        text: 'Chase up a consultation booking',
        icon: 'comment-o'
    },
    thinkingTx: {
        text: 'Chase up treatment decision',
        icon: 'comment-o'
    },
    inTx: {
        text: 'Book in their first treatment appointment',
        icon: 'calendar'
    },
    confirmation: {
        text: '',
        icon: ''
    }
};

function NextActionFloController() {
    var ctrl = this;

    ctrl.$onInit = function () {
        ctrl.states = states;
        ctrl.nextAction = 'booking';
        ctrl.changeState('booking');
    };

    ctrl.changeState = function (state) {
        ctrl.state = state;
        ctrl.header =
            states[state === 'confirmation' ? ctrl.nextAction : state];
    };

    ctrl.chooseNextAction = function (nextAction) {
        ctrl.nextAction = nextAction;
        ctrl.changeState(nextAction);
    };

    ctrl.setDate = function (date) {
        ctrl.date = date;
    };

    ctrl.setNote = function (note) {
        ctrl.note = note;
    };

    ctrl.setProvider = function (provider) {
        ctrl.provider = provider;
    };

    ctrl.onConfirm = function () {
        ctrl.onComplete(
            obj.pick(ctrl, ['nextAction', 'date', 'note', 'provider'])
        );
    };
}

export default {
    template,
    controller: ['NextActionHeader', NextActionFloController],
    bindings: {
        enabled: '<',
        action: '<',
        onComplete: '&',
        isSaving: '<',
        pipeline: '<'
    }
};
