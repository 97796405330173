import {
    createAction,
    createAsyncThunk,
    createReducer
} from '@reduxjs/toolkit';
import {
    ActionCountPayload,
    actionCounts,
    ActionsPayload,
    fetchActions as fetchActionsApi
} from '$api';
import { ActionsQuery, ActionState, ActionStatus } from '$state/types';
import { Draft } from 'immer';
import { actionCompleted, actionUpdated, actionWaking, unauthenticated } from '../events';
import {
    LegacyV2ActionSchema,
    ReferralsFilter,
    SnoozedStatus,
    SortOrder
} from '@/types';
import { extendSnooze, startSnooze, endSnooze } from '$state/concerns/patient';

export interface FetchActionsArgs {
    stages?: string[];
    forView: string;
    query?: ActionsQuery;
}

/**
 * fetchActions fetches and soft merges actions in
 */
export const fetchActions = createAsyncThunk<ActionsPayload, FetchActionsArgs>(
    'actions/due',
    async ({ stages, query }: FetchActionsArgs) => {
        return fetchActionsApi(stages, query);
    }
);

/**
 * refreshActions fetches and hard merges actions in, by deleting
 * actions in the requested stages, then inserting new actions
 */
export const refreshActions = createAsyncThunk<
    ActionsPayload,
    FetchActionsArgs
>('actions/due/refresh', async ({ stages, query }: FetchActionsArgs) => {
    return fetchActionsApi(stages, query);
});

/**
 * fetchCounts gets the counts of actions in each stage
 */
export const fetchCounts = createAsyncThunk<ActionCountPayload, string[]>(
    'actions/counts',
    async (stages: string[]) => {
        return actionCounts(stages);
    }
);

export const actionsSorted = createAction<SortOrder>('actions/sorted');

export const actionToggled = createAction<{
    state: boolean;
    patient_id: string;
}>('actions/action-toggled');

export const loadMoreActions = createAction<{
    view: string;
    category: string;
    load: number;
}>('actions/category/load-more');

export const filterReferrals = createAction<ReferralsFilter>(
    'actions/filters/referrals'
);
export const filterSnooze = createAction<SnoozedStatus>(
    'actions/filters/snooze'
);

export const newActionRemoveSingle = createAction<string>(
    'actions/new/remove/single'
);

export const newActionRemoveAll = createAction(
    'actions/new/remove/all'
);

export const INITIAL_ACTIONS = 10;

export const initialState: ActionState = {
    newActions: {},
    actions: {},
    views: {
        'New Leads': {
            state: ActionStatus.INITIAL_LOAD,
            section: 'Leads',
            path: '/',
            categories: [
                {
                    name: 'New Leads',
                    stages: ['newLead'],
                    displayTotal: INITIAL_ACTIONS,
                    actions: []
                }
            ]
        },
        'Call Attempts': {
            state: ActionStatus.INITIAL_LOAD,
            section: 'Leads',
            path: '/actions/call-attempts',
            categories: [
                {
                    name: '2nd Attempts',
                    stages: ['callback1'],
                    displayTotal: INITIAL_ACTIONS,
                    actions: []
                },
                {
                    name: '3rd Attempts',
                    stages: ['callback2'],
                    displayTotal: INITIAL_ACTIONS,
                    actions: []
                },
                {
                    name: 'Final Attempts',
                    stages: ['callback3'],
                    displayTotal: INITIAL_ACTIONS,
                    actions: []
                }
            ]
        },
        'In Discussion': {
            state: ActionStatus.INITIAL_LOAD,
            section: 'Leads',
            path: '/actions/working',
            categories: [
                {
                    name: 'In Discussion',
                    stages: ['working'],
                    displayTotal: INITIAL_ACTIONS,
                    actions: []
                }
            ]
        },
        'Considering Tx': {
            state: ActionStatus.INITIAL_LOAD,
            section: 'Consultations',
            path: '/actions/consults/thinking',
            categories: [
                {
                    name: 'Considering Tx',
                    stages: ['thinking'],
                    displayTotal: INITIAL_ACTIONS,
                    actions: []
                }
            ]
        },
        Outcomes: {
            state: ActionStatus.INITIAL_LOAD,
            section: 'Consultations',
            path: '/actions/consults/outcomes',
            categories: [
                {
                    name: 'Consultation Outcomes',
                    stages: ['consultation'],
                    displayTotal: INITIAL_ACTIONS,
                    actions: []
                },
                {
                    name: 'Tx Plan Consultation Outcomes',
                    stages: ['txPlanConsult'],
                    displayTotal: INITIAL_ACTIONS,
                    actions: []
                },
                {
                    name: 'Tx Appointments',
                    stages: ['inTx'],
                    displayTotal: INITIAL_ACTIONS,
                    actions: []
                }
            ]
        }
    },
    counts: {},
    sort: 'desc',
    filters: {
        referrals: 'both',
        snooze: 'hideSnoozed'
    }
};

export default createReducer(initialState, (builder) => {
    builder.addCase(fetchActions.pending, (state, action) => {
        const { arg } = action.meta;
        const viewName = arg.forView;
        const view = state.views[viewName];
        view.query = {
            ...view.query,
            ...arg.query
        };
        if (view.state === ActionStatus.ERROR_INITIAL_LOADING) {
            view.state = ActionStatus.INITIAL_LOAD;
        }
        if (view.state !== ActionStatus.INITIAL_LOAD) {
            view.state = ActionStatus.REFRESHING;
        }
    });

    builder.addCase(fetchActions.fulfilled, (state, action) => {
        mergeActionsIn(state, action.payload, action.meta.arg);
        const { arg } = action.meta;
        const viewName = arg.forView;
        const view = state.views[viewName];
        view.state = ActionStatus.OK;
    });

    builder.addCase(fetchActions.rejected, (state, action) => {
        const viewName = action.meta.arg.forView;
        const view = state.views[viewName];

        if (!view) {
            return;
        }

        if (view.state === ActionStatus.INITIAL_LOAD) {
            view.state = ActionStatus.ERROR_INITIAL_LOADING;
            return;
        }

        view.state = ActionStatus.ERROR_REFRESHING;
    });

    builder.addCase(filterReferrals, (state, action) => {
        state.filters.referrals = action.payload;
    });

    builder.addCase(filterSnooze, (state, action) => {
        state.filters.snooze = action.payload;
    });

    builder.addCase(actionUpdated, (state, action) => {
        const { patientId, stage, nextActionAt } = action.payload;
        const record = state.actions[patientId];
        if (record && record.state != 'hidden') {
            state.actions[patientId].stale = true;
            state.actions[patientId].isWaking = false; // if we are updating it can't wake
        } else if (!record && new Date() > new Date(nextActionAt)) {
            // New patient/action
            state.actions[patientId] = {
                action: { stage, patient_id: patientId, initialized: false },
                stale: true,
                state: 'closed',
                isNew: true,
                isWaking: false
            };
            state.newActions[patientId] = { isDisplayed: false };

            // iterate over each view and add the patient to the correct
            // category
            const viewNames = Object.keys(state.views);
            for (const viewName of viewNames) {
                const view = state.views[viewName];
                const category = view.categories.find((cat) =>
                    cat.stages.includes(stage)
                );
                if (category) {
                    category.actions = Array.from(
                        new Set([...category.actions, patientId])
                    );
                }
            }
        }
    });

    builder.addCase(actionWaking, (state, action) => {
        const { patientId, stage } = action.payload;
        const record = state.actions[patientId];

        if (record && record.state != 'hidden') {
            state.actions[patientId].stale = false;
            state.actions[patientId].isWaking = true;
        } else if (!record) {
            // New patient/action
            state.actions[patientId] = {
                action: { stage, patient_id: patientId, initialized: false },
                stale: false,
                state: 'closed',
                isNew: false,
                isWaking: true
            };

            // iterate over each view and add the patient to the correct
            // category
            const viewNames = Object.keys(state.views);
            for (const viewName of viewNames) {
                const view = state.views[viewName];
                const category = view.categories.find((cat) =>
                    cat.stages.includes(stage)
                );
                if (category) {
                    category.actions = Array.from(
                        new Set([...category.actions, patientId])
                    );
                }
            }
        }
    });

    builder.addCase(unauthenticated, () => initialState);

    builder.addCase(fetchCounts.fulfilled, (state, action) => {
        for (const stage in action.payload) {
            state.counts[stage] = action.payload[stage];
        }
    });

    builder.addCase(actionCompleted, (state, action) => {
        const { patientId } = action.payload;
        const record = state.actions[patientId];
        if (record) {
            record.state = 'complete';
        }
    });

    builder.addCase(startSnooze.pending, (state, action) => {
        const { patientId } = action.meta.arg;
        const record = state.actions[patientId];
        if (record) {
            record.state = 'snoozed';
        }
    });

    builder.addCase(extendSnooze.pending, (state, action) => {
        const { patientId } = action.meta.arg;
        const record = state.actions[patientId];
        if (record) {
            record.state = 'snoozeExtended';

        }
    });

    builder.addCase(endSnooze.pending, (state, action) => {
        const patientId = action.meta.arg;
        const record = state.actions[patientId];
        if (record) {
            record.isWaking = false;
            if ('snooze' in record.action) {
                record.action.snooze = null;
            }
        }
    });

    builder.addCase(actionsSorted, (state, action) => {
        state.sort = action.payload;
    });

    builder.addCase(actionToggled, (state, action) => {
        const record = state.actions[action.payload.patient_id];
        if (!record) {
            return;
        }

        switch (record.state) {
            case 'open':
            case 'closed':
                record.state = action.payload.state ? 'open' : 'closed';
                break;
            case 'complete':
            case 'snoozed':
            case 'snoozeExtended':
                record.state = 'hidden';
                break;
        }
    });

    builder.addCase(loadMoreActions, (state, action) => {
        const { view, category, load } = action.payload;
        const cat = state.views[view].categories.find(
            (cat) => cat.name == category
        );
        cat!.displayTotal += load;
    });

    builder.addCase(newActionRemoveSingle, (state, action) => {
        delete state.newActions[action.payload];
        const patient = state.actions[action.payload];
        state.actions[action.payload] = {
            ...patient,
            isNew: false
        };
    });

    builder.addCase(newActionRemoveAll, (state) => {
        for (const patientId in state.newActions) {
            if (state.newActions[patientId].isDisplayed) {
                delete state.newActions[patientId];
            }
        }
    });
});

export const mergeActionsIn = (
    state: Draft<ActionState>,
    actions: ActionsPayload, // The actions to merge in
    arg: FetchActionsArgs // The args that were requested,
) => {
    // Get the categories for the view
    const categories = state.views[arg.forView].categories;
    // clear current actions from categories - replacing with new filtered
    // actions
    categories.forEach((cat) => {
        // Remove the old actions from the category
        cat.actions = [];
    });

    // Put the new actions in - adds patient_id[] category.actions
    actions.forEach((action: LegacyV2ActionSchema) => {
        const patientId = action.patient_id;
        const oldAction = state.actions[patientId];
        const newAction = state.newActions[patientId];
        action.initialized = true;
        state.actions[patientId] = {
            action,
            state: 'closed',
            stale: false,
            isNew: !!newAction,
            isWaking: false
        };
        const category = categories.find((cat) =>
            cat.stages.includes(action.stage)
        );

        if (!category) return;

        if (oldAction) {
            const oldCategory = categories.find((cat) =>
                cat.stages.includes(oldAction.action.stage)
            );
            if (oldCategory && category.name !== oldCategory.name) {
                oldCategory.actions = oldCategory.actions.filter(
                    (id) => id !== patientId
                );
            }
        }
        category.actions.push(patientId);

        if (newAction) {
            newAction.isDisplayed = true;
        }
    });
};
