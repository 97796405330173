/**
 * Serves as a mount point, lifting React UI components so they can be used in
 * the legacy AngularJS app.
 */

import {react2angular} from 'react2angular';
import {Header, Sidebar} from '$ui/Layout';
import {Canvas} from './Canvas';
import Bridge from './bridge.service';
import * as views from './views';
import {UiParamsProvider, UiRouterProvider} from './ui-router.fake';

// temporary home but offers the pattern of integration between the legacy
// framework and the pure UI components (until state management)

// defaults for canvas
function canvasController() {
    this.date = new Date();

    this.note = {
        title: 'A note',
        content: 'Hello',
        type: 'note',
        date: '2021-02-15T12:30:00.000Z'
    };
}

export default // prettier-ignore
// so name stays on the last line
// prettier-ignore
// so name stays on the last line
// prettier-ignore
// so name stays on the last line
// prettier-ignore
// so name stays on the last line
// prettier-ignore
// so name stays on the last line
angular
    .module('app.bridge', [])
    .service('Bridge', ['$http', Bridge])
    .controller('CanvasController', canvasController)
    .component('uiCanvas', react2angular(Canvas))
    .component(
        'uiHeader',
        react2angular(Header, ['onLogoClicked', 'onAddPatientClicked'])
    )
    .component('uiSidebar', react2angular(Sidebar, ['navigate', 'signOut']))
    .component('viewUnauthorized', views.unauthorized)
    .component('viewTxTypes', views.txTypes)
    .component('viewClientTxTypes', views.clientTxTypes)
    .component('viewDashboard', views.dashboard)
    .component('viewAddPatient', views.addPatient)
    .component('viewChangeStage', views.changeStage)
    .provider('$state', UiRouterProvider)
    .provider('$stateParams', UiParamsProvider)
    .provider('$urlRouter', UiRouterProvider)
    // prettier-ignore
    // so name stays on the last line
    .name;
