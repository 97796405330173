function ClientController() {
    var ctrl = this;

    ctrl.client = {
        name: 'Leadflo Dental Practice',
        abbreviation: 'LF',
        primaryColor: '#298bfa',
        logo: {
            path: '/img/hsds-logo.png',
            name: 'hsds-logo.png',
            type: 'image/png'
        },
        guides: [
            {
                id: 1,
                path: '/pdf/implants.pdf',
                name: 'implants.pdf',
                type: 'application/pdf'
            },
            {
                id: 2,
                path: '/pdf/invisible-braces.pdf',
                name: 'invisible-braces.pdf',
                type: 'application/pdf'
            },
            {
                id: 3,
                path: '/pdf/denture.pdf',
                name: 'denture.pdf',
                type: 'application/pdf'
            },
            {
                id: 4,
                path: '/pdf/ortho.pdf',
                name: 'ortho.pdf',
                type: 'application/pdf'
            },
            {
                id: 5,
                path: '/pdf/cosmetic.pdf',
                name: 'cosmetic.pdf',
                type: 'application/pdf'
            }
        ],
        header: {
            path: '/img/email-header.png',
            name: 'email-header.png',
            type: 'image/png'
        },
        footer: {
            path: '/img/email-footer.png',
            name: 'email-footer.png',
            type: 'image/png'
        }
    };

    ctrl.onNameChanged = (value) => {
        ctrl.client.name = value;
    };

    ctrl.onAbbreviationChanged = (value) => {
        ctrl.client.abbreviation = value;
    };

    ctrl.onPrimaryColorChanged = (value) => {
        ctrl.client.primaryColor = value;
    };

    ctrl.onLogoAdded = (file) => {
        ctrl.client.logo = file;
    };

    ctrl.onGuidesAdded = (files) => {
        ctrl.client.guides.push(...files);
    };

    ctrl.onGuideRemoved = (id) => {
        ctr.client.guides = ctrl.client.guides.filter(
            (guide) => guide.id !== id
        );
    };

    ctrl.onHeaderAdded = (file) => {
        ctrl.client.header = file;
    };

    ctrl.onFooterAdded = (file) => {
        ctrl.client.footer = file;
    };
}

export default ClientController;
