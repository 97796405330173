import { array } from '$utils';

function TreatmentTypesService($http, AuthService) {
    var svc = this;

    svc.getTreatmentTypes = function () {
        return svc.listForSelf().then(function (txTypes) {
            return array.pluck(txTypes, 'name');
        });
    };

    svc.setForAction = function (action) {};

    svc.list = function () {
        return $http.get('/v2/tx-types').then(function (res) {
            return res.data.data;
        });
    };

    svc.listForClient = function (clientId) {
        return $http
            .get('/v2/clients/' + clientId + '/tx-types')
            .then(function (res) {
                return res.data.data;
            });
    };

    svc.listForSelf = () => {
        return $http.get('/v2/tx-types/current').then((res) => res.data.data);
    };

    svc.save = function (txTypes) {
        return $http.post('/v2/tx-types', { types: txTypes });
    };

    svc.saveForClient = function (clientId, txTypeIds) {
        return $http.post('/v2/clients/' + clientId + '/tx-types', {
            types: txTypeIds
        });
    };
}

export default TreatmentTypesService;
