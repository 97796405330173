function can(Permissions) {
    return {
        restrict: 'A',
        link: link
    };

    function link(scope, el, attrs) {
        guard(attrs.can, el);
        scope.$on('signedIn', () => guard(attrs.can, el));
    }

    function guard(can, el) {
        el.addClass('hidden');

        Permissions.can(can).then(function (canDo) {
            if (!canDo) {
                el.addClass('hidden');
            } else {
                el.removeClass('hidden');
            }
        });
    }
}

export default ['Permissions', can];
