function AppController($rootScope, AuthService, $state, $ngRedux, TxActions) {
    const ctrl = this;

    ctrl.$onInit = () => {
        ctrl.v3 = true;

        if (ctrl.shouldLogout) {
            AuthService.logout(() => $state.go('login'));
        }

        if (AuthService.isAuthed()) {
            AuthService.authenticate();
            $ngRedux.dispatch(TxActions.fetchTxTypes());
        }
    };
}

export default [
    '$rootScope',
    'AuthService',
    '$state',
    '$ngRedux',
    'TxActions',
    AppController
];
