import { Icon } from '$ui/Flo/Icon';
import { Button } from '$ui/Flo/Button';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Hue } from '$ui/Flo/types';
import { useEffect } from 'react';

export const DeleteModal = ({
    isOpen,
    onClose,
    onDelete,
    closeOnOutsideClick,
}: {
    isOpen: boolean;
    onClose: () => void;
    onDelete: () => void;
    closeOnOutsideClick?: boolean;
}) => {
    const modalRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        const listener = (event: MouseEvent) => {
            if (!closeOnOutsideClick) {
                return;
            }

            if (!modalRef.current) {
                return;
            }

            if (modalRef.current.contains(event.target as Node)) {
                return;
            }

            onClose();
        };

        document.addEventListener('click', listener);

        return () => {
            document.removeEventListener('click', listener);
        };
    }, [closeOnOutsideClick, onClose, isOpen]);

    if (!isOpen) {
        return null;
    }

    return (
        <ModalContainer>
            <ModalContent ref={modalRef}>
                <ModalClose onClick={onClose}>
                    <Icon icon="X" onClick={onClose} clickable size={3} />
                </ModalClose>
                <div>
                    <Content>
                        <IconContainer hue={'red'}>
                            <Icon
                                icon={'Trash2'}
                                size={3}
                                hue={'red'}
                                opacity={1}
                            />
                        </IconContainer>
                        <TitleModal>Delete Template</TitleModal>
                        <MessageModal>
                            Are you sure you want to delete this template?
                        </MessageModal>
                    </Content>
                    <ActionBar>
                        <ButtonGroup>
                            <Button rounded mode={'outline'} onClick={onClose}>
                                <CustomButton>Cancel</CustomButton>
                            </Button>
                            <Button rounded hue={'red'} onClick={onDelete}>
                                <CustomButton color={'white'}>
                                    Delete
                                </CustomButton>
                            </Button>
                        </ButtonGroup>
                    </ActionBar>
                </div>
            </ModalContent>
        </ModalContainer>
    );
};

const ModalContainer = styled.div`
    position: fixed;
    background: rgba(0, 0, 0, 0.16);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    ${mix.padding({ padding: 4 })};
    ${mix.type({ level: 'body2' })};
    z-index: 10000;
`;

const ModalContent = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: white;
    position: relative;
    max-width: 700px;
    max-height: 700px;
    ${mix.gap({ size: 3 })};
    ${mix.shadow()};
    border-radius: 6px;
`;

const ModalClose = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    ${mix.padding({ padding: 1 })};
`;

const ActionBar = styled.div`
    border-top: 1px solid var(--gray-200);
`;

const TitleModal = styled.div`
    font-size: 20px;
    font-weight: 600;
    color: var(--gray-600);
`;

const MessageModal = styled.div`
    font-size: 16px;
    color: var(--gray-600);
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: ${mix.unit({ size: 1.5 })};
    justify-content: space-between;
    ${mix.padding({ padding: [1.5, 3] })};
`;

const Content = styled.div`
    ${mix.padding({ padding: [1.5] })};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${mix.unit({ size: 2 })};
    ${mix.padding({ padding: [3] })};
`;

const CustomButton = styled.div<{ color?: string }>`
    width: 140px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => (props.color ? props.color : 'var(--gray-500)')};
`;

const IconContainer = styled.div<{ hue: Hue }>`
    ${mix.sq({ size: 6 })};
    border-radius: 50%;
    background: var(--gray-100);
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ hue }) => {
        switch (hue) {
            case 'primary':
                return css`
                    background: var(--primary-050);
                `;
            case 'red':
                return css`
                    background: var(--error-050);
                `;
            case 'green':
                return css`
                    background: var(--success-050);
                `;
            case 'yellow':
                return css`
                    background: var(--warning-050);
                `;
            default:
                return css`
                    background: var(--gray-100);
                `;
        }
    }};
`;
