import angular from 'angular';
import dashboard from './dashboard';
import addPatient from './add-patient';
import client from './client';
import manager from './manager';
import features from './features';

export default angular.module('app.v2', [
    dashboard,
    addPatient,
    client,
    manager,
    features
]).name;
