import { Icon } from '$ui/Flo/Icon';
import { Button } from '$ui/Flo/Button';
import * as React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { useEffect } from 'react';

export const ApproveModal = ({
    isOpen,
    onClose,
    onApprove,
    closeOnOutsideClick,
}: {
    isOpen: boolean;
    onClose: () => void;
    onApprove: (isApproval: boolean) => void;
    closeOnOutsideClick?: boolean;
}) => {
    const modalRef = React.useRef<HTMLDivElement>(null);
    const [isApproval, setIsApproval] = React.useState(true);

    const onApproveHandle = () => {
        onApprove(isApproval);
    };

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        const listener = (event: MouseEvent) => {
            if (!closeOnOutsideClick) {
                return;
            }

            if (!modalRef.current) {
                return;
            }

            if (modalRef.current.contains(event.target as Node)) {
                return;
            }

            onClose();
        };

        document.addEventListener('click', listener);

        return () => {
            document.removeEventListener('click', listener);
        };
    }, [closeOnOutsideClick, onClose, isOpen]);

    if (!isOpen) {
        return null;
    }

    return (
        <ModalContainer>
            <ModalContent ref={modalRef}>
                <ModalClose onClick={onClose}>
                    <Icon icon="X" onClick={onClose} clickable size={3} />
                </ModalClose>
                <div>
                    <Content>
                        <TitleModal>Send for approval</TitleModal>
                        <MessageModal>
                            Do you want to send this template for approval, so
                            that it can be used to start or restart
                            conversations?
                        </MessageModal>
                        <SwitcherWrapper>
                            <SwitcherButton
                                active={isApproval}
                                onClick={() => setIsApproval(true)}
                            >
                                Yes
                            </SwitcherButton>
                            <SwitcherButton
                                active={!isApproval}
                                onClick={() => setIsApproval(false)}
                            >
                                No
                            </SwitcherButton>
                        </SwitcherWrapper>
                        {isApproval && (
                            <>
                                <CheckContainer>
                                    <CheckIcon
                                        icon={'CheckCircle'}
                                        color={'gray-600'}
                                        opacity={1}
                                    />
                                    <div>
                                        You will not be able to use this
                                        template to start a conversation until
                                        it is approved
                                    </div>
                                </CheckContainer>
                                <CheckContainer>
                                    <CheckIcon
                                        icon={'CheckCircle'}
                                        color={'gray-600'}
                                        opacity={1}
                                    />
                                    <div>
                                        You can always use this template within
                                        an active conversation at any time
                                    </div>
                                </CheckContainer>
                            </>
                        )}
                        {!isApproval && (
                            <CheckContainer>
                                <CheckIcon
                                    icon={'CheckCircle'}
                                    color={'gray-600'}
                                    opacity={1}
                                />
                                <div>
                                    You can only use this template within active
                                    conversations
                                </div>
                            </CheckContainer>
                        )}
                    </Content>
                    <ActionBar>
                        <ButtonGroup>
                            <Button rounded mode={'outline'} onClick={onClose}>
                                <CustomButton>Continue Editing</CustomButton>
                            </Button>
                            <Button
                                rounded
                                hue={'primary'}
                                onClick={onApproveHandle}
                            >
                                <CustomButton color={'white'}>
                                    {isApproval
                                        ? 'Send for approval'
                                        : 'Save template'}
                                </CustomButton>
                            </Button>
                        </ButtonGroup>
                    </ActionBar>
                </div>
            </ModalContent>
        </ModalContainer>
    );
};

const ModalContainer = styled.div`
    position: fixed;
    background: rgba(0, 0, 0, 0.16);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    ${mix.padding({ padding: 4 })};
    ${mix.type({ level: 'body2' })};
    z-index: 10000;
`;

const ModalContent = styled.div`
    ${mix.width({ size: 51 })};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: white;
    position: relative;
    max-width: 700px;
    max-height: 700px;
    ${mix.gap({ size: 3 })};
    ${mix.shadow()};
    border-radius: 6px;
`;

const ModalClose = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    ${mix.padding({ padding: 1 })};
`;

const ActionBar = styled.div`
    border-top: 1px solid var(--gray-200);
`;

const TitleModal = styled.div`
    font-size: 20px;
    font-weight: 600;
    color: var(--gray-600);
`;

const MessageModal = styled.div`
    font-size: 16px;
    color: var(--gray-600);
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: ${mix.unit({ size: 1.5 })};
    justify-content: space-between;
    ${mix.padding({ padding: [1.5, 3] })};
`;

const Content = styled.div`
    ${mix.padding({ padding: [1.5] })};
    display: flex;
    flex-direction: column;
    gap: ${mix.unit({ size: 2 })};
    ${mix.padding({ padding: [3] })};
`;

const CustomButton = styled.div<{ color?: string }>`
    width: 140px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => (props.color ? props.color : 'var(--gray-500)')};
`;

const CheckContainer = styled.div`
    display: flex;
    gap: ${mix.unit({ size: 1 })};
`;

const CheckIcon = styled(Icon)`
    margin-top: ${mix.unit({ size: 0.5 })};
`;

const SwitcherWrapper = styled.div`
    background: #eaecf0;
    padding: 2px;
    border-radius: 6px;
    display: flex;
    gap: 2px;
`;

const SwitcherButton = styled.button<{ active?: boolean }>`
    background: none;
    color: #98a2b3;
    ${mix.padding({ padding: 1 })};
    border: none;
    border-radius: 4px;
    transition: background-color 0.5s ease;
    cursor: pointer;
    flex: 1;
    ${(props) =>
        props.active &&
        `
         background: #FFFFFF;
         color: #475467;
    `}
`;
