import { store } from '$state';
import { from } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import {
    featureSet,
    featuresLoaded,
    featureState
} from '$state/queries/features';
import { lang } from '$utils';

const FLAGS = {};

const featureSet$ = from(store)
    .pipe(map(featureState))
    .pipe(filter(featuresLoaded))
    .pipe(map(featureSet))
    .pipe(distinctUntilChanged(lang.isEqual));

const constructor = () => {
    const enabled = (featureName, trueCallback, falseCallback = () => {}) => {
        featureSet$
            .pipe(map((set) => set.includes(featureName)))
            .pipe(distinctUntilChanged())
            .subscribe((enabled) => {
                if (enabled) {
                    trueCallback();
                } else {
                    falseCallback();
                }
            });
    };

    return { enabled, ...FLAGS };
};

export default constructor;
