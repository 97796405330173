import * as React from 'react';
import { Timeline } from '$ui/Timeline/index';
import { TimelineV2 } from '$ui/Timeline/TimelineV2';
import {
    Events as EventsV2,
    Event as EventV2,
    CommType as CommTypeV2,
    Comm as CommV2,
    EventType as EventTypeV2,
} from '$types/timeline-v2';
import styled from 'styled-components';
import {
    Comm,
    CommType,
    Event,
    Events,
    openEmailArguments,
    openEmailArgumentsV2,
} from '$types';
import { RefreshButton } from '$ui/Timeline/RefreshButton';
import { Theme } from '$ui/Theme';
import {
    fetchTimeline,
    refreshTimeline,
    retryComm as retryCommAction,
    selectChannel,
    toLegacyEvent,
} from '$state/concerns/timeline';
import { selectTimeline } from '$state/queries/timeline';
import {
    useAppDispatch,
    useAppSelector,
    useContextLoader,
    useTemplateLoader,
} from '$state';
import { Channels } from '$state/types';
import { featureEnabled } from '$state/queries/features';
import { conversationRead } from '$state/concerns/inbox';
import { assetUrl, selectRepName } from '$state/concerns/client';
import { client } from '$state/queries/client';
import * as patients from '$state/queries/patient';
import { TimelineReplyBar } from '$ui/ReplyBar/TimelineReplyBar';
import { withState } from '$state/utils';
import { EventPayload } from '$api/patients';

interface Props {
    patientId: string;
    openEmailModal: (args: openEmailArguments | openEmailArgumentsV2) => void;
    selectChannelAfterLoad?: Channels; // If provided, will select the channel once the timeline has loaded
    showRefreshButton?: boolean;
}

export const ConnectedTimeline = (props: Props) => {
    const {
        patientId,
        openEmailModal,
        selectChannelAfterLoad,
        showRefreshButton = true,
    } = props;

    const dispatch = useAppDispatch();
    const timeline = useAppSelector(withState(selectTimeline, patientId));
    const repPhotoUrl = useAppSelector(assetUrl('rep'));
    const repName = useAppSelector(selectRepName);
    const { name: clientName } = useAppSelector(client) ?? {};
    const patient = useAppSelector(patients.get(patientId));
    const attachmentsEnabled = useAppSelector(
        withState(featureEnabled, 'attachments'),
    );
    const timelineV2Enabled = useAppSelector(
        withState(featureEnabled, 'timeline-v2'),
    );

    // For some reason using the ID as the dependency here
    // causes it to enter an infinite loop of dispatching,
    // crashing the browser.
    // Fortunately we had an empty dep list before,
    // so using the patient ID should satisfy us for now
    React.useEffect(() => {
        const fetch = async () => {
            await dispatch(fetchTimeline(patientId));
            !!selectChannelAfterLoad &&
                dispatch(
                    selectChannel({
                        patientId: patientId,
                        channel: selectChannelAfterLoad,
                    }),
                );
        };
        fetch();
    }, [patientId]);

    React.useEffect(() => {
        dispatch(
            conversationRead({
                patientId,
                notify: true,
            }),
        );
    }, [patientId]);

    useContextLoader(patientId);
    useTemplateLoader();

    const events = timeline.events;
    const status = timeline.metadata.status;
    const retryComm = (event: Event) => {
        if (event.type !== 'communication') {
            return;
        }
        dispatch(
            retryCommAction({ id: event.id, patientId, comm: event as Comm }),
        );
    };

    // Converts the V2 event to the old event type for the retry function
    const retryCommV2 = (event: EventV2) => {
        if (event.type !== EventTypeV2.Comm) {
            return;
        }
        const comm = event as CommV2;
        const commType = comm.comm_type;
        if (
            commType == CommTypeV2.Email ||
            commType == CommTypeV2.MarketingEmail
        ) {
            const email = toLegacyEvent({
                ...event,
                email_type:
                    commType === 'email'
                        ? CommType.Email
                        : CommType.MarketingEmail,
                type: 'Email',
            } as EventPayload);
            retryComm(email);
        }
        if (commType === CommTypeV2.SMS) {
            const sms = toLegacyEvent({
                ...event,
                type: 'SMS',
            } as EventPayload);
            retryComm(sms);
        }

        if (commType === CommTypeV2.PhoneCall) {
            const call = toLegacyEvent({
                ...event,
                type: 'Phone Call',
            } as EventPayload);
            retryComm(call);
        }

        if (commType === CommTypeV2.WhatsAppMessage) {
            const whatsapp = toLegacyEvent({
                ...event,
                type: 'WhatsApp',
            } as EventPayload);
            retryComm(whatsapp);
        }

        if (commType === CommTypeV2.FormSubmission) {
            return;
        }
    };

    return (
        <Theme>
            <Container>
                {timelineV2Enabled ? (
                    <TimelineV2
                        events={events as EventsV2}
                        openEmailModal={openEmailModal}
                        status={status}
                        retry={retryCommV2}
                        practiceName={clientName ?? 'N A'}
                        representative={{
                            name: repName,
                            photo: repPhotoUrl,
                        }}
                        patientName={
                            patients.maybeFullName(patient) ?? 'Not Available'
                        }
                        attachmentsEnabled={attachmentsEnabled ?? false}
                    />
                ) : (
                    <Timeline
                        events={events as Events}
                        openEmailModal={openEmailModal}
                        status={status}
                        retry={retryComm}
                        practiceName={clientName ?? 'N A'}
                        representative={{
                            name: repName,
                            photo: repPhotoUrl,
                        }}
                        patientName={
                            patients.maybeFullName(patient) ?? 'Not Available'
                        }
                        attachmentsEnabled={attachmentsEnabled ?? false}
                    />
                )}
                <Header>
                    {showRefreshButton && (
                        <RefreshButton
                            status={status}
                            refresh={() => dispatch(refreshTimeline(patientId))}
                        />
                    )}
                </Header>
                <Footer>
                    <TimelineReplyBar patientId={patientId} />
                </Footer>
            </Container>
        </Theme>
    );
};

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const Header = styled.div`
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem 3rem 2rem 0;
`;

const Footer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    max-height: 75%;
`;
