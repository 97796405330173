import { store } from '@/state';
import { init } from '@/state/concerns/auth';
import auth from '$auth';

function authConfig($httpProvider)
{
    $httpProvider.interceptors.push(['$q', authInterceptor]);

    function authInterceptor($q)
    {
        var interceptor = {};

        interceptor.request = function (config) {
            if (!auth.authenticated()) {
                return config;
            }

            config.headers = config.headers || {};
            config.headers.Authorization = 'Bearer ' + auth.token();
            return config;
        };

        interceptor.responseError = function (response) {
            if (response.status === 401 || response.status === 400) {
                store.dispatch(init());
            }

            return $q.reject(response);
        };

        return interceptor;
    }
}

export default authConfig;
