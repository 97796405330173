import angular from 'angular';
import ClientController from './client.controller';
import AddClientController from './add-client.controller';
import { react2angular } from 'react2angular';
import { Test } from './Test';

export default angular
    .module('app.v2.client', [])
    .component('test', react2angular(Test, ['hello']))
    .controller('ClientController', ClientController)
    .controller('AddClientController', AddClientController).name;
