import angular from 'angular';
import { lang } from '$utils';

function DialogService($mdDialog) {
    var stack = [],
        pvt = {};

    pvt.pop = function () {
        if (!lang.isEmpty(stack)) {
            return stack.pop();
        }

        return void 0;
    };

    this.show = function (opts) {
        opts = {
            ...{
                parent: angular.element(document.body),
                bindToController: true,
                controllerAs: '$ctrl',
                fullscreen: true,
                onShowing: function (scope) {
                    // simulate $onInit
                    scope?.$ctrl?.$onInit?.();
                }
            },
            opts
        };

        stack.push(opts);

        return $mdDialog.show(opts);
    };

    this.close = function () {
        if (stack.length > 1) {
            pvt.pop();
            return this.show(pvt.pop());
        }

        pvt.pop();
        return $mdDialog.cancel();
    };
}

export default DialogService;
