import template from './search-bar.html';
import * as patients from '$bridge/edit-patient';

var STATE_START = 'start';
var STATE_SEARCHING = 'searching';
var STATE_RESULTS = 'results';
var STATE_NO_RESULTS = 'no_results';

function SearchBarController(Search) {
    var ctrl = this;

    ctrl.changeState = function (state) {
        ctrl.state = state;
    };

    ctrl.clear = function () {
        ctrl.results = [];
        ctrl.query = '';
        ctrl.changeState(STATE_START);
    };

    ctrl.$onInit = function () {
        ctrl.clear();
    };

    ctrl.onChange = function (query) {
        if (query.length > 2) {
            ctrl.changeState(STATE_SEARCHING);

            Search.search(query).then(function (results) {
                if (!ctrl.isSearching()) {
                    return;
                }

                ctrl.results = results;

                if (results.length > 0) {
                    ctrl.changeState(STATE_RESULTS);
                } else {
                    ctrl.changeState(STATE_NO_RESULTS);
                }
            });
        } else {
            ctrl.changeState(STATE_START);
        }
    };

    ctrl.isStart = function () {
        return ctrl.state === STATE_START;
    };

    ctrl.isSearching = function () {
        return ctrl.state === STATE_SEARCHING;
    };

    ctrl.hasResults = function () {
        return ctrl.state === STATE_RESULTS;
    };

    ctrl.isResultsEmpty = function () {
        return ctrl.state === STATE_NO_RESULTS;
    };

    ctrl.hasTooManyResults = function () {
        return ctrl.hasResults() && ctrl.results.length > 25;
    };

    ctrl.patientClicked = (id, patientId) => {
        if (ctrl.dontOpenEditor) {
            ctrl.onPatientClicked({ id, patientId });
        } else {
            patients.open(patientId, id);
        }

        ctrl.clear();
    };
}

export default {
    template,
    controller: ['Search', SearchBarController],
    bindings: {
        onPatientClicked: '&',
        onAddPatientClicked: '&',
        dontOpenEditor: '<'
    }
};
