import * as React from 'react';
import {
    CommEventUnion,
    CommType,
    Event,
    Events,
    EventUnion,
    openEmailArguments,
} from '$types';
import { EmailEvent } from './EmailEvent';
import { NoteEvent } from './NoteEvent';
import { DateLine } from './DateLine';
import { Failed, Loading } from '$ui/Shared';
import { TimelineStatus } from '$state/concerns/timeline';
import { SMSEvent } from './SMSEvent';
import { FormSubmissionEvent } from './FormSubmissionEvent';
import { PhoneCallEvent } from './PhoneCallEvent';
import { Theme } from '$ui/Theme';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { BusinessEvent } from './BusinessEvent';
import { WhatsAppMessageEvent } from '$ui/Timeline/WhatsAppMessageEvent';
import { AttachmentEvent } from '$ui/Timeline/AttachmentEvent';

type openEmailModal = (args: openEmailArguments) => void;

interface ActorProps {
    patientName: string;
    representative: {
        photo: string | null;
        name: string | null;
    };
    practiceName: string;
}

interface Props extends ActorProps {
    events: Events;
    openEmailModal: openEmailModal;
    status: TimelineStatus;
    retry?: (comm: Event) => void;
    attachmentsEnabled?: boolean;
}

export const Timeline = (props: Props) => {
    const {
        events,
        openEmailModal,
        status,
        retry,
        attachmentsEnabled = false,
        ...actor
    } = props;

    if (status === 'loading') {
        return <Loading text="Loading timeline..." />;
    }

    if (status === 'error_loading') {
        return <Failed />;
    }

    const timeline = [];
    const days = groupEvents(events);
    for (const group of days) {
        timeline.push(
            <DateLine date={group.date} key={group.date.toString()} />,
        );
        timeline.push(
            ...group.events.map((event) =>
                buildEvent(
                    event,
                    openEmailModal,
                    actor,
                    retry,
                    attachmentsEnabled,
                ),
            ),
        );
    }

    return (
        <Theme>
            <Container data-cy="timeline">
                <Pane role="list">{timeline}</Pane>
            </Container>
        </Theme>
    );
};

interface eventGroup {
    date: Date;
    events: Events;
}

const groupEvents = (events: Events): eventGroup[] => {
    const map = {};
    for (const event of events) {
        if (!event) continue;
        const date = new Date(Date.parse(event.datetime));
        const key = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
        const existingEvents = (map[key] || {}).events || [];
        existingEvents.push(event);
        map[key] = {
            date: date,
            events: existingEvents,
        };
    }
    return Object.values(map);
};

const buildEvent = (
    event: EventUnion,
    open: openEmailModal,
    actor: ActorProps,
    retry?: (comm: Event) => void,
    attachmentsEnabled?: boolean,
) => {
    switch (event.type) {
        case 'communication':
            return buildComm(event, open, actor, retry, attachmentsEnabled);
        case 'note':
            return <NoteEvent {...actor} note={event} key={event.id} />;
        case 'form_submission':
            return (
                <FormSubmissionEvent
                    submission={event}
                    key={event.id}
                    {...actor}
                />
            );
        case 'business_event':
            return (
                <BusinessEvent
                    {...actor}
                    businessEvent={event}
                    key={event.id}
                />
            );

        case 'attachment':
            return <AttachmentEvent {...actor} attachment={event} />;

        default:
            return null;
    }
};

const buildComm = (
    comm: CommEventUnion,
    open: openEmailModal,
    actor: ActorProps,
    retry?: (comm: Event) => void,
    attachmentsEnabled?: boolean,
) => {
    switch (comm.comm_type) {
        case CommType.Email:
        case CommType.MarketingEmail:
            return (
                <EmailEvent
                    {...actor}
                    email={comm}
                    openModal={open}
                    key={comm.id}
                    retry={retry}
                    attachmentsEnabled={attachmentsEnabled}
                />
            );
        case CommType.SMS:
            return (
                <SMSEvent
                    {...actor}
                    sms={comm}
                    key={comm.id}
                    retry={retry}
                    attachmentsEnabled={attachmentsEnabled}
                />
            );
        case CommType.PhoneCall:
            return <PhoneCallEvent {...actor} call={comm} key={comm.id} />;
        case CommType.WhatsApp:
            return (
                <WhatsAppMessageEvent
                    {...actor}
                    message={comm}
                    key={comm.id}
                    retry={retry}
                    attachmentsEnabled={attachmentsEnabled}
                />
            );
        default:
            return null;
    }
};

const Container = styled.div`
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 16px 2rem;
`;

const Pane = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${mix.unit({ size: 4 })};
    padding-top: ${mix.unit({ size: 6 })};
`;
